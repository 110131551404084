import { useBreakpoint } from '@landr/maestro';
import { useNotificationsContext } from 'contexts/NotificationsContext/NotificationsContext';
import { NotificationBase } from '../types';

export const useNotification = (notification: NotificationBase) => {
    const { removeNotification } = useNotificationsContext();
    const isMobile = useBreakpoint('md');

    const { id, variant, timeout, getMessage } = notification;

    const message = getMessage(isMobile);

    const handleRemove = () => {
        removeNotification(id);
    };

    return { variant, message, timeout, handleRemove };
};
