import { PopoverPlacement, IconProps } from '@landr/maestro';
export interface FixedTarget {
    top?: number | string;
    right?: number | string;
    bottom?: number | string;
    left?: number | string;
}

export interface TextTarget {
    parent: string;
    text: string;
}

export type TargetType = string | TextTarget | FixedTarget;
export type ResponsiveTargetType = {
    default: TargetType;
    xs?: TargetType;
    sm?: TargetType;
    ms?: TargetType;
    md?: TargetType;
    ml?: TargetType;
    lg?: TargetType;
    xl?: TargetType;
    xxl?: TargetType;
    jb?: TargetType;
};

export type Step = {
    target: ResponsiveTargetType;
    focus?: string;
    placement?: PopoverPlacement;
    icon?: React.FC<IconProps>;
    title: string;
    content: string;
    exitCta?: string;
    nextCta?: string;
    pathname?: string;
    variant?: 'base' | 'accent';
    listenToTarget?: boolean;
    scrollIntoView?: boolean;
};

export type OnboardingContextStateType = {
    source: string | undefined;
    step: Step | undefined;
    walkThrough: Step[] | [];
    current: number;
    total: number;
};

export type OnboardingAction =
    | {
          type: 'addWalkThrough';
          payload: { steps: Step[] };
      }
    | {
          type: 'enterWalkThrough';
          payload: { source: string };
      }
    | {
          type: 'nextStep';
          payload: { nextStep: number; callback: () => void };
      }
    | {
          type: 'exitWalkThrough';
      };

export enum WalkThrough {
    PluginWelcomeTour = 'PluginWelcomeTour',
    Creator = 'Creator',
}

export type WalkThroughs = {
    [WalkThrough.PluginWelcomeTour]: Step[];
    [WalkThrough.Creator]: Step[];
};

export type OnboardingContextType = {
    state: OnboardingContextStateType;
    requestOnboarding: (walkThroughName: WalkThrough[]) => void;
    WalkThrough: typeof WalkThrough;
    next: (nextStep: number, callback: () => void) => void;
    exit: () => void;
};
