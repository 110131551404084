import debounce from 'lodash/debounce';

export interface Observer<T> {
    callback: (payload: T) => void;
}

export type Procedure = (...args: unknown[]) => void;

export class EventObserver<T> implements Observer<T> {
    public callback: (payload: T) => void;

    constructor(callback: (payload: T) => void) {
        this.callback = (payload: T): void => {
            return callback(payload);
        };
    }
}

export class EventSubscription<T> {
    private eventName = '';

    constructor(eventName: string) {
        this.eventName = eventName;
    }

    public observers = new Set<Observer<T>>();

    public subscribe(observer: Observer<T>): void {
        this.observers.add(observer);
    }

    public unsubscribe(observer: Observer<T>): void {
        this.observers.delete(observer);
    }

    public immediate = this.broadcast;
    public debounced = debounce(this.broadcast, 500);

    private broadcast(data: T): void {
        this.observers.forEach((observer) => {
            observer.callback(data);
        });
    }
}
