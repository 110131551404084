// TODO: this should be generated through AirTable data source but we don't have one yet.
export enum ProblemDetailsType {
    Unknown = 'unknown',
    NotFound = '404',
    NotEnoughCredits = 'NotEnoughCredits',
    DownloadBinZipFailed = 'DownloadBinZipFailed',
    NetworkError = 'NetworkError',
    Conflict = '409',
    ApiError = 'ApiError',
    PoorStandingOrSuspendedAccount = 'PoorStandingOrSuspendedAccount',
    PlanPaused = 'PlanPaused',
}
