import React from 'react';
import { PlayerProvider } from '@landr/react-player';
import { log } from 'utils/log';

export const ReactPlayerProvider = ({
    children,
}: {
    children: React.ReactNode;
}): React.ReactElement => {
    return <PlayerProvider log={log}>{children}</PlayerProvider>;
};

export default ReactPlayerProvider;
