export enum ModalsEnum {
    freeUserFreeDownloadUpsell = 'freeUserFreeDownloadUpsell',
    freeUserPaidDownloadUpsell = 'freeUserPaidDownloadUpsell',
    paidUserPaidDownloadUpsell = 'paidUserPaidDownloadUpsell',
    creditsWarning = 'creditsWarning',
    removeSamplesFromCollection = 'removeSamplesFromCollection',
    createCollectionModal = 'createCollectionModal',
    editCollectionModal = 'editCollectionModal',
    deleteCollectionModal = 'deleteCollectionModal',
    poorStandingOrSuspended = 'poorStandingOrSuspended',
    keyboardCheatSheet = 'keyboardCheatSheet',
    sweetenerUpsellModal = 'sweetenerUpsellModal',
    planPaused = 'planPaused',
    activateTrialSubscription = 'activateTrialSubscription',
}
