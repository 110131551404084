import { t } from '@lingui/macro';
import { Step } from '../OnboardingContext.type';

export const getCreator: () => Step[] = () => [
    {
        variant: 'accent',
        target: {
            default:
                '[data-testid="creator"] [data-component="BpmAndKeyControl"]',
        },
        scrollIntoView: false,
        placement: 'top',
        title: t`Switch it up`,
        content: t`Play with your sound to find the perfect fit. Change the key and BPM instantly.`,
        exitCta: t`Skip`,
    },
    {
        variant: 'accent',
        focus: '[data-testid="creator-samples-table-body"] > div:first-child',
        target: {
            default: `[aria-expanded="true"] + div [data-testid="creator-samples-table-body"] > div:first-child button[aria-label="${t`Advanced settings`}"]`,
        },
        scrollIntoView: false,
        placement: 'auto',
        title: t`Advanced Settings`,
        content: t`You can adjust individual loops here. Adjust the original key or BPM to make it your own.`,
        exitCta: t`Skip`,
    },
    {
        variant: 'accent',
        target: {
            default:
                '[data-component="CreatorHeader"] [data-component="DownloadButton"]',
            md: '[data-component="CreatorHeader"] [data-component="CreatorHeaderMobileMenu"]',
        },
        scrollIntoView: false,
        placement: 'auto',
        title: t`Take it offline`,
        content: t`Download your beat and pick up right where you left off in your DAW once you’re done sketching.`,
    },
];
