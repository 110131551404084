import { defaultLocale } from 'constants/locale';
import { NavigateFunction } from 'react-router-dom';
import { Languages, UnsupportedLanguages } from 'types/enums';

export const navigateIfNeeded = ({
    add,
    remove,
    pathname = '',
    navigate,
}: {
    add?: Languages;
    remove?: Languages | UnsupportedLanguages;
    pathname: string | undefined;
    navigate: NavigateFunction;
}) => {
    if (!add && !remove) {
        return;
    }

    const pathnameWithoutLocale = remove
        ? pathname.replace(`/${remove}`, '')
        : pathname;

    const newPathname =
        add && add !== defaultLocale
            ? `/${add}${pathnameWithoutLocale}`
            : `${pathnameWithoutLocale}`;

    if (newPathname !== pathname) {
        navigate(newPathname, { replace: true });
    }
};
