import { CreatorSession } from 'libs/CreatorSession/CreatorSession';

export const getUseLegacyProcessing = (isLegacyDevice: boolean): boolean => {
    return isLegacyDevice || typeof SharedArrayBuffer === 'undefined';
};

export const setupModules = async (
    creatorSession: CreatorSession,
    useLegacyProcessing: boolean,
): Promise<void> => {
    if (!useLegacyProcessing) {
        await creatorSession.audioContext.audioWorklet.addModule(
            '/workers/sharedBufferNode.js',
        );
    }
};

export const setupSession = async (
    setCreatorSession: (session: CreatorSession) => void,
    isLegacyDevice: boolean,
): Promise<void> => {
    const { CreatorSession } = await import(
        'libs/CreatorSession/CreatorSession'
    );
    const useLegacyProcessing = getUseLegacyProcessing(isLegacyDevice);
    const creatorSession = new CreatorSession(useLegacyProcessing);

    await setupModules(creatorSession, useLegacyProcessing);

    setCreatorSession(creatorSession);
};

export const getCreatorSessionSetup =
    (
        setCreatorSession: (session: CreatorSession) => void,
        isLegacyDevice: boolean,
        isDawPlugin: boolean,
    ) =>
    () => {
        // Don't initialize Creator in the Plugin
        if (isDawPlugin) {
            return false;
        }

        setupSession(setCreatorSession, isLegacyDevice);

        return true;
    };
