import { useQuery, ApolloError } from '@apollo/client';
import {
    Sample,
    SampleQuery,
    SampleQueryVariables,
} from 'types/generated/graphql';
import { Sample as SampleGql } from './sample.gql';

export const useSampleById = (
    id?: string,
): {
    sample: Sample | undefined;
    error: ApolloError | undefined;
    loading: boolean;
} => {
    const { data, error, loading } = useQuery<
        SampleQuery,
        SampleQueryVariables
    >(SampleGql, {
        skip: !id,
        variables: {
            sampleGuid: id as string,
        },
    });

    const sample = data?.sample;

    return { sample, error, loading };
};
