import { ApolloLink } from '@apollo/client';
import { CONFIG } from 'utils/config';
import { dispatchUpdateRequiredEvent } from 'helpers/customEvent';
import { VersionChange } from 'types/enums';

export const schemaVersionLink = new ApolloLink((operation, forward) => {
    return forward(operation).map((data) => {
        const schemaVersion = operation
            .getContext()
            .response.headers.get('x-schema-version');

        // If the schema is undefined in the BFF schemaVersion will be the
        // string 'undefined', not the `undefined` primitive
        if (!schemaVersion || schemaVersion === 'undefined') {
            return data;
        }

        const [major, minor] = schemaVersion.split('.');

        if (major !== CONFIG.VITE_SCHEMA_VERSION.split('.')[0]) {
            dispatchUpdateRequiredEvent(VersionChange.Major);
            return data;
        }

        if (minor !== CONFIG.VITE_SCHEMA_VERSION.split('.')[1]) {
            dispatchUpdateRequiredEvent(VersionChange.Minor);
            return data;
        }

        return data;
    });
});
