import { EventIds } from 'constants/eventIds';
import { AxiosResponse } from 'axios';
import { log } from 'utils/log';
import { ProblemDetailsType } from '../enums';
import { ProblemDetails } from './problemDetails';

const UnknownProblemDetails: ProblemDetails = {
    type: 'https://api.landr.com/errors/unknown',
    title: 'Unknown error',
    status: 500,
};

/**
 * By default axios returns error message `Network Error`.
 */
const isNetworkError = (errorMessage?: string) =>
    errorMessage === 'Network Error';

export function getProblemDetailsType(
    type?: string,
    errorMessage?: string,
): ProblemDetailsType {
    if (isNetworkError(errorMessage)) {
        return ProblemDetailsType.NetworkError;
    }

    if (!type) {
        return ProblemDetailsType.Unknown;
    }
    const lastSlash = type.lastIndexOf('/');

    if (lastSlash === -1) {
        log.error(
            `Problem details type doesn't respect convention, it should be an url`,
            EventIds.ProblemDetailsTypeError,
            undefined,
            { type },
        );
        return type as ProblemDetailsType;
    }
    return type.substring(lastSlash + 1) as ProblemDetailsType;
}

export class APIError extends Error implements ProblemDetails {
    status: number;
    title: string;
    type: ProblemDetailsType;
    detail?: string;
    instance?: string;
    rawError: any;

    constructor(
        response?: AxiosResponse<ProblemDetails>,
        errorMessage?: string,
    ) {
        let data = UnknownProblemDetails;

        if (response) {
            const { status: responseStatus, ...responseData } =
                response.data || {};

            data = {
                ...UnknownProblemDetails,
                status: responseStatus || response.status,
                ...responseData,
            };
        }
        super(data.title);

        this.status = data.status;
        this.title = data.title;
        this.type = getProblemDetailsType(data.type, errorMessage);
        this.detail = data.detail;
        this.instance = data.instance;
        this.rawError = response ? response.data : null;
    }
}
