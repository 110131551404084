import {
    EXECUTION_CONTEXT_URL_PARAM,
    EXECUTION_CONTEXT_SESSION_KEY,
} from 'constants/executionEnvironment';
import { ApplicationEnum } from '@landr/core.models';
import { AnalyticsPlatformName } from '@landr/core';
import { ExecutionEnvironment } from 'types/enums';
import { CONFIG } from 'utils/config';

export function getIsDesktopApp(): boolean {
    return Boolean(window?.electronPreload);
}

export function getExecutionEnvironmentParam(): string | null {
    const queryString = window.location.search;

    return new URLSearchParams(queryString).get(EXECUTION_CONTEXT_URL_PARAM);
}

export function getExecutionEnvironmentSessionItem(): string | null {
    return window.sessionStorage.getItem(EXECUTION_CONTEXT_SESSION_KEY);
}

export function getIsDAWPlugin(): boolean {
    const executionEnvironmentParam = getExecutionEnvironmentParam();
    const executionEnvironmentSessionItem =
        getExecutionEnvironmentSessionItem();

    return (
        executionEnvironmentParam === ExecutionEnvironment.DAWPlugin ||
        executionEnvironmentSessionItem === ExecutionEnvironment.DAWPlugin
    );
}

export function getApplicationName(): ApplicationEnum {
    return getIsDAWPlugin()
        ? (CONFIG.VITE_LOG_PLUGIN_APPLICATION_NAME as ApplicationEnum)
        : (CONFIG.VITE_LOG_APPLICATION_NAME as ApplicationEnum);
}

// Note: v2 analytics use AnalyticsApplication and AnalyticsPlatformName, making this helpers obsolete
// remove with https://mixgenius.atlassian.net/browse/SP-6081
export function getAnalyticsApplicationName(): string {
    return getIsDAWPlugin()
        ? ApplicationEnum.SamplesPlugin
        : ApplicationEnum.SamplesMarketplace;
}

export function getAnalyticsPlatformName(): AnalyticsPlatformName {
    if (getIsDAWPlugin()) {
        return AnalyticsPlatformName.Plugin;
    }

    if (getIsDesktopApp()) {
        return AnalyticsPlatformName.Desktop;
    }

    return AnalyticsPlatformName.Web;
}

// Pricing links have to be opened in a new window when we're in the plugin
export function getPricingLinkTarget(): '_blank' | '_self' {
    return getIsDAWPlugin() ? '_blank' : '_self';
}
