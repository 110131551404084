export enum RoutePaths {
    Artists = 'artists',
    Browse = 'browse',
    UserCollections = 'my-collections',
    Downloads = 'downloads',
    Favorites = 'favorites',
    Genres = 'genres',
    Instruments = 'instruments',
    Labels = 'labels',
    Packs = 'packs',
    Pricing = 'pricing',
    Profile = 'profile', // deprecated
    Recommendations = 'recommendations',
    Collections = 'collections',
    Descriptors = 'descriptors', // deprecated
    Charts = 'charts', // deprecated
    Creator = 'creator', // deprecated
    CreatorBeatMaker = 'creator-beatmaker',
    Exclusives = 'exclusives',
    Plugin = 'plugin',
    QuickPicks = 'quickpicks',
    Sfxs = 'sfxs',
    Category = 'category',
}
