import { EXECUTION_CONTEXT_SESSION_KEY } from 'constants/executionEnvironment';
import React, { useContext } from 'react';
import { ExecutionEnvironment } from 'types/enums';
import { getIsDAWPlugin, getExecutionEnvironmentSessionItem } from 'helpers';
import { useMountEffect } from 'hooks/useMountEffect';

export type ExecutionEnvironmentContextType = {
    executionEnvironment: ExecutionEnvironment;
    isDAWPlugin: boolean;
    isBrowser: boolean;
};

export interface ExecutionEnvironmentContextProviderProps {
    children: React.ReactNode;
}

// DEFAULT CONTEXT

export const defaultExecutionEnvironmentContext: ExecutionEnvironmentContextType =
    {
        executionEnvironment: ExecutionEnvironment.Browser,
        isDAWPlugin: false,
        isBrowser: true,
    };

// CONTEXT

export const ExecutionEnvironmentContext =
    React.createContext<ExecutionEnvironmentContextType>(
        defaultExecutionEnvironmentContext,
    );

export const useExecutionEnvironmentContext =
    (): ExecutionEnvironmentContextType =>
        useContext(ExecutionEnvironmentContext);

// PROVIDER

export const ExecutionEnvironmentProvider = ({
    children,
}: ExecutionEnvironmentContextProviderProps): JSX.Element => {
    const isPluginEnabled = getIsDAWPlugin();
    const executionEnvironmentSessionItem =
        getExecutionEnvironmentSessionItem();
    const isDAWPlugin = isPluginEnabled;
    const isBrowser = !isPluginEnabled;

    const executionEnvironment = isPluginEnabled
        ? ExecutionEnvironment.DAWPlugin
        : ExecutionEnvironment.Browser;

    useMountEffect(() => {
        if (
            isPluginEnabled &&
            executionEnvironmentSessionItem !== ExecutionEnvironment.DAWPlugin
        ) {
            window.sessionStorage.setItem(
                EXECUTION_CONTEXT_SESSION_KEY,
                ExecutionEnvironment.DAWPlugin,
            );
        }
    });

    return (
        <ExecutionEnvironmentContext.Provider
            value={{
                executionEnvironment,
                isDAWPlugin,
                isBrowser,
            }}
        >
            {children}
        </ExecutionEnvironmentContext.Provider>
    );
};

export default ExecutionEnvironmentProvider;
