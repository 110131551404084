import { UTMCookieSettings } from 'constants/UTMCookieSettings';
import {
    AppState,
    AuthAnalyticsProperties,
    AuthService,
    BrowserPlatformProvider,
    FusionAuthSPAAuthProvider,
    User,
    FusionAuthPluginAuthProvider,
} from '@landr/auth';
import { Environment, getEnvSuffix } from '@landr/core';
import { getIsDAWPlugin, getTargetUrl } from 'helpers';
import { DefaultLanguage, Languages } from '@landr/core.models';
import { CONFIG, analytics, i18n, log } from 'utils';

export class Auth {
    private authService: AuthService | null = null;
    private offlineUrl: string | null = null;
    private locale: Languages = DefaultLanguage;

    public async initialize({
        offlineUrl,
    }: {
        environment: Environment;
        offlineUrl: string;
    }) {
        this.offlineUrl = offlineUrl ?? null;

        const environment = CONFIG.VITE_APP_ENV;

        const platformProvider = new BrowserPlatformProvider({
            environment,
            analytics,
            cookieSettings: UTMCookieSettings,
        });

        const isDAWPlugin = getIsDAWPlugin();
        const authProvider = isDAWPlugin
            ? new FusionAuthPluginAuthProvider({
                  domain: CONFIG.VITE_FUSION_AUTH_DOMAIN,
                  clientId: CONFIG.VITE_FUSION_AUTH_PLUGIN_CLIENT_ID,
                  platformProvider,
                  log: log.getInstance('FusionAuthPluginAuthProvider'),
                  revokeRefreshTokenEndpoint: `${CONFIG.VITE_MS_USER}/fusionauth/revoke`,
              })
            : new FusionAuthSPAAuthProvider({
                  domain: CONFIG.VITE_FUSION_AUTH_DOMAIN,
                  clientId: CONFIG.VITE_FUSION_AUTH_CLIENT_ID,
                  platformProvider,
                  environment,
                  log: log.getInstance('FusionAuthSPAAuthProvider'),
              });

        this.authService = new AuthService({
            authProvider,
            platformProvider,
            environment,
            homepageForAnalyticsFallback: 'Samples',
            log: log.getInstance('AuthService'),
        });

        await this.authService.checkOrWaitForOngoingAuthentication();

        await this.initLocale();
    }

    public login(options?: { forceLoginPrompt?: boolean }) {
        this.authService?.login({
            appState: {
                targetUrl: getTargetUrl(),
            },
            locale: i18n.locale as Languages,
            forceLoginPrompt: options?.forceLoginPrompt,
        });
    }

    public signup() {
        this.authService?.login({
            isSignup: true,
            appState: {
                targetUrl: getTargetUrl(),
            },
            locale: i18n.locale as Languages,
        });
    }

    public logout() {
        return this.authService?.logout();
    }

    public continueOrRedirectToProfileSurvey() {
        const isDAWPlugin = getIsDAWPlugin();

        if (auth.isSignup() && !isDAWPlugin) {
            const profileSurveyPathname = `/profile-survey?isSignup=true&redirectUrl=${window.location.href}`;

            // Don't use getWebappLink here because it will redirect to local environment
            window.location.assign(
                `https://app${getEnvSuffix(
                    CONFIG.VITE_APP_ENV,
                )}.landr.com${profileSurveyPathname}`,
            );
        }
    }

    public async isAuthenticated(): Promise<boolean> {
        if (!this.authService) {
            throw new Error('Auth service not initialized');
        }

        return this.authService?.checkOrWaitForOngoingAuthentication();
    }

    public isSignup(): boolean {
        if (!this.authService) {
            throw new Error('Auth service not initialized');
        }

        return !!this.authService?.getIsSignup();
    }

    public getAccessToken(): Promise<string | null> {
        if (this.authService) {
            return this.authService.getAccessToken();
        }

        return Promise.resolve(null);
    }

    public getAppState(): AppState | null {
        if (!this.authService) {
            throw new Error('Auth service not initialized');
        }

        return this.authService.getAppState();
    }

    public async getAnalyticsProperties(): Promise<AuthAnalyticsProperties | null> {
        if (!this.authService) {
            throw new Error('Auth service not initialized');
        }
        return this.authService.getAnalyticsProperties();
    }

    public getLocale(): Languages {
        return this.locale;
    }

    public async getUser(): Promise<User | null> {
        if (!this.authService) {
            throw new Error('Auth service not initialized');
        }
        return this.authService.getUser().catch(() => {
            // Silently failed (log is done by the lib), and return no user
            if (this.offlineUrl) {
                window.location.assign(this.offlineUrl);
            }
            return null;
        });
    }

    private getTwoLetterLanguageCode(
        languageCode: string,
        fallbackLanguage: Languages = Languages.English,
    ): Languages {
        const twoLetterLanguageAbbreviation: string = languageCode
            ? languageCode.substring(0, 2)
            : fallbackLanguage;

        return Object.values(Languages).includes(
            twoLetterLanguageAbbreviation as Languages,
        )
            ? (twoLetterLanguageAbbreviation as Languages)
            : fallbackLanguage;
    }

    private getAnonymousLocale(): Languages {
        const browserLanguage =
            (typeof navigator !== 'undefined' && navigator.language) || 'en-US';
        const urlParams = new URLSearchParams(window.location.search);
        const urlLang = urlParams.get('locale');

        return this.getTwoLetterLanguageCode(urlLang ?? browserLanguage);
    }

    private async initLocale() {
        const user = await this.getUser();

        if (user) {
            this.locale =
                (user.preferredCulture?.substr(0, 2) as Languages) ??
                DefaultLanguage;
        } else {
            this.locale = this.getAnonymousLocale();
        }
    }
}

export const auth = new Auth();
