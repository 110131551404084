import { useContext } from 'react';
import {
    ExecutionEnvironmentContext,
    ExecutionEnvironmentContextType,
} from 'contexts/ExecutionEnvironmentContext/ExecutionEnvironmentContext';

const useExecutionEnvironment = (): ExecutionEnvironmentContextType => {
    const executionEnvironment = useContext(ExecutionEnvironmentContext);

    return executionEnvironment;
};

export default useExecutionEnvironment;
