import React, { useContext, useRef } from 'react';
import { useUserCredits } from 'apollo/user';
import { neverop } from 'utils/noop';
import isNumber from 'lodash/isNumber';

interface UserCreditsProviderProps {
    children: React.ReactNode;
}

export type UserCreditsContextType = {
    getUserCredits: () => void;
    userCredits: number | undefined;
};

export const defaultUserCreditsContext = {
    getUserCredits: neverop('UserCreditsContext', 'getUserCredits'),
    userCredits: undefined,
};

export const UserCreditsContext = React.createContext<UserCreditsContextType>(
    defaultUserCreditsContext,
);

export const UserCreditsProvider = ({
    children,
}: UserCreditsProviderProps): JSX.Element => {
    const { doQuery: getUserCredits, userCredits: fetchedUserCredits } =
        useUserCredits();

    const userCredits = useRef(fetchedUserCredits?.remaining);

    // If we get `undefined` for userCredits after receiving a valid value we
    // keep showing the old value
    if (isNumber(fetchedUserCredits?.remaining)) {
        userCredits.current = fetchedUserCredits?.remaining;
    }

    return (
        <UserCreditsContext.Provider
            value={{
                getUserCredits,
                userCredits: userCredits.current,
            }}
        >
            {children}
        </UserCreditsContext.Provider>
    );
};

export const useUserCreditsContext = (): UserCreditsContextType =>
    useContext(UserCreditsContext);

export default UserCreditsProvider;
