import { useEffect } from 'react';
import { OpenModal } from 'contexts/ModalContext/ModalContext.type';
import { useMilestonesController } from 'controllers/business/Milestones/Milestones.controller';
import { DownloadableItem } from 'types/downloadableItem';
import { PurchaseActionTypes, PurchaseSteps } from '../Purchase.types';
import { handleCreditUseWarning } from './handleCreditUseWarning';
import { handleNotEnoughCredits } from './handleNotEnoughCredits';
import { handlePoorStandingOrSuspended } from './handlePoorStandingOrSuspended';
import { handlePlanPaused } from './handlePlanPaused';
import { PurchaseAction, PurchaseState } from './purchaseReducer';
import { HandleCostPreview } from './useCostPreview';
import { DownloadHandler } from './useHandleDownload';
import { HandlePurchase } from './useHandlePurchase';
import { HandlePurchaseError } from './useHandlePurchaseError';
import { handleActivateTrialSubscription } from './handleActivateTrialSubscription';

const minSampleCost = 1;

export interface IPurchaseFlow {
    dispatch: React.Dispatch<PurchaseAction>;
    handleCostPreview: HandleCostPreview;
    handleDownload: DownloadHandler;
    handleError: HandlePurchaseError;
    handlePurchase: HandlePurchase;
    hasSubscription: boolean;
    openModal: OpenModal;
    purchaseState: PurchaseState;
}

export const usePurchaseFlow = ({
    dispatch,
    handleCostPreview,
    handleDownload,
    handleError,
    handlePurchase,
    hasSubscription,
    openModal,
    purchaseState,
}: IPurchaseFlow) => {
    const {
        activeStep,
        downloadUrl,
        error,
        item,
        purchaseCostInCredits,
        purchaseQueue,
        skipCreditUseApproval,
        interactionSource,
        onSuccess,
    } = purchaseState;
    const { get } = useMilestonesController();

    useEffect(() => {
        switch (activeStep) {
            case PurchaseSteps.Empty:
                if (purchaseQueue.length) {
                    const [nextItem, ...remaining] = purchaseQueue;

                    dispatch({
                        type: PurchaseActionTypes.Start,
                        ...nextItem,
                        purchaseQueue: remaining,
                    });
                }
                break;
            case PurchaseSteps.DidError:
                handleError(error);
                dispatch({ type: PurchaseActionTypes.Exit });
                break;
            case PurchaseSteps.AwaitingCostPreview:
                handleCostPreview({
                    item: item as DownloadableItem,
                });
                break;
            case PurchaseSteps.CostPreviewReady:
                if (
                    purchaseCostInCredits > minSampleCost &&
                    !skipCreditUseApproval
                ) {
                    handleCreditUseWarning({
                        item: item as DownloadableItem,
                        openModal,
                    });
                    break;
                }

                handlePurchase({
                    item: item as DownloadableItem,
                    interactionSource,
                    onSuccess: onSuccess,
                });

                break;
            case PurchaseSteps.CreditUseApproved:
                handlePurchase({
                    item: item as DownloadableItem,
                    onSuccess: onSuccess,
                });
                break;
            case PurchaseSteps.NotEnoughCredits:
                handleNotEnoughCredits({
                    hasSubscription,
                    openModal,
                });
                break;
            case PurchaseSteps.PoorStandingOrSuspended:
                handlePoorStandingOrSuspended({
                    openModal,
                });
                break;

            case PurchaseSteps.PlanPaused:
                handlePlanPaused({
                    openModal,
                });
                break;
            case PurchaseSteps.TrialSubscription:
                handleActivateTrialSubscription({
                    openModal,
                });
                break;
            case PurchaseSteps.ReadyToDownload:
                handleDownload({
                    item: item as DownloadableItem,
                    url: downloadUrl as string,
                });

                // Once we start downloading we are finished the purchase flow
                dispatch({
                    type: PurchaseActionTypes.Exit,
                });
                break;
            default:
                return;
        }
        // Only re-evaluate on activeStep change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeStep, get]);
};
