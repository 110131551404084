import { useCallback } from 'react';
import {
    AddedToDownloadsError,
    PurchaseApiError,
} from 'contexts/NotificationsContext/notifications';
import { NotificationBase } from 'contexts/NotificationsContext/types';

export const useErrorNotification = ({
    skipDownload,
    createNotification,
}: {
    skipDownload: boolean;
    createNotification: <T extends NotificationBase>(notification: T) => void;
}) => {
    const errorNotification = useCallback(() => {
        if (skipDownload) {
            createNotification(new PurchaseApiError());
        } else {
            createNotification(new AddedToDownloadsError());
        }
    }, [createNotification, skipDownload]);

    return errorNotification;
};
