import { useState, useCallback } from 'react';
import { CreatorEvents } from 'types/enums';
import { useObserver } from 'hooks/useObserver';
import { useCreatorSession } from 'contexts/CreatorSessionContext/CreatorSessionContext';
import { useActivePlayerContext } from 'contexts/ActivePlayerContext/ActivePlayerContext';
import { CreatorTrackMapType } from 'libs/CreatorSession/CreatorSession.types';
import {
    CreatorEventPayloadType,
    CreatorSampleMetaType,
    CreatorAction,
} from '../Creator.types';

type UsePlaybackStateHandlersType = {
    isUiPaused: boolean;
    isSessionProcessing: boolean;
    setIsSessionProcessing: (isProcessing: boolean) => void;
    togglePause: () => void;
};

export const usePlaybackStateHandlers = (
    dispatch: React.Dispatch<CreatorAction>,
): UsePlaybackStateHandlersType => {
    const creatorSession = useCreatorSession();

    const { stopActivePlayer } = useActivePlayerContext();

    const [isUiPaused, setIsUiPaused] = useState(true);
    const [isSessionProcessing, setIsSessionProcessing] = useState(false);

    const pauseObserver = useCallback((p: CreatorEventPayloadType) => {
        setIsUiPaused(p as boolean);
    }, []);

    const processingObserver = useCallback((p: CreatorEventPayloadType) => {
        setIsSessionProcessing(p as boolean);
    }, []);

    const togglePause = useCallback(() => {
        stopActivePlayer();
        creatorSession.togglePause();
    }, [stopActivePlayer, creatorSession]);

    const dispatchTracksUpdated = useCallback(
        (_tracks: CreatorEventPayloadType): void => {
            const tracks = _tracks as CreatorTrackMapType;

            dispatch({
                type: 'updateSamples',
                payload: {
                    samples: [...tracks.entries()].reduce(
                        (updateMap, [id, track]) =>
                            updateMap.set(id, {
                                // Gain is explicitly not included because the gain
                                // value is handled differently in the Creator Context
                                // and the Creator Session
                                bpm: track.bpm,
                                bpmMultiplier: track.bpmMultiplier,
                                pitchTranspose: track.pitchTranspose,
                                semitoneShift: track.semitoneShift,
                                timeFactor: track.timeFactor,
                                timeFactorBeforeMultiplier:
                                    track.timeFactorBeforeMultiplier,
                                recommendedValues: track.recommendedValues,
                                scaledDuration: track.scaledDuration,
                            }),
                        new Map<string, Partial<CreatorSampleMetaType>>(),
                    ),
                },
            });
        },
        [dispatch],
    );

    useObserver<CreatorEvents, CreatorEventPayloadType>(
        CreatorEvents.PAUSE_UPDATED,
        pauseObserver,
        creatorSession.subscribe,
        creatorSession.unsubscribe,
        !creatorSession.isInitialized,
    );

    useObserver<CreatorEvents, CreatorEventPayloadType>(
        CreatorEvents.PROCESSING_UPDATED,
        processingObserver,
        creatorSession.subscribe,
        creatorSession.unsubscribe,
        !creatorSession.isInitialized,
    );

    useObserver<CreatorEvents, CreatorEventPayloadType>(
        CreatorEvents.TRACKS_UPDATED,
        dispatchTracksUpdated,
        creatorSession.subscribe,
        creatorSession.unsubscribe,
        !creatorSession.isInitialized,
    );

    return {
        setIsSessionProcessing,
        isUiPaused,
        isSessionProcessing,
        togglePause,
    };
};
