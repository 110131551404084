import { EventIds } from 'constants/eventIds';
import { log } from 'utils/log';
import { CreatorStoredState, CreatorContextStateType } from '../Creator.types';
import { prepareStateForStoring } from './misc.helpers';

// Note: Only add what the user can directly control
export function convertStateToHash(
    state: CreatorContextStateType,
): string | null {
    let hash;
    const preparedState = prepareStateForStoring(state);

    try {
        hash = btoa(JSON.stringify(preparedState));
    } catch (error) {
        log.error(
            `Failed to convert state into hash`,
            EventIds.FailedToCreateHash,
            error,
        );
        return null;
    }

    return hash;
}

export function convertHashToStoredState(
    hash: string,
): Partial<CreatorStoredState> | null {
    let unsanitizedParsedState;

    // Warn if the hash is bigger than 2000 characters - no consequence for now,
    // because we do not limit the size of the hash when we generate it
    if (hash.length >= 2000) {
        log.warn(
            `Hash is ${hash.length} characters long`,
            EventIds.HashTooLong,
        );
    }

    try {
        unsanitizedParsedState = JSON.parse(atob(hash));
    } catch (error) {
        log.error(
            `Failed to parse the hash ${hash}`,
            EventIds.FailedToParseHash,
            error,
        );
        return null;
    }

    return unsanitizedParsedState;
}

export function storeSessionLocally(state: CreatorContextStateType): void {
    const stateHash = convertStateToHash(state);

    if (stateHash) {
        localStorage.setItem('creator', stateHash);
    }
}
