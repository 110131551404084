import { useMemo } from 'react';

const useIsReady = ({
    isGuestUser,
    persistedMilestones,
}: {
    isGuestUser: boolean;
    persistedMilestones?: string[];
}) => {
    const isReady = useMemo(() => {
        return isGuestUser || !!persistedMilestones;
    }, [isGuestUser, persistedMilestones]);

    return isReady;
};

export default useIsReady;
