import { useToLocalizedPath } from 'hooks/useToLocalizedPath/useToLocalizedPath';
import { useMatch, PathMatch } from 'react-router-dom';
import { RoutePaths } from 'types/enums';

const useCreatorRouteMatch = (): PathMatch<'session'> | null => {
    const toLocalizedPath = useToLocalizedPath();

    const session =
        useMatch(toLocalizedPath(`/${RoutePaths.Creator}/:session`)) ||
        useMatch(toLocalizedPath(`/${RoutePaths.CreatorBeatMaker}/:session`));

    return session;
};

export default useCreatorRouteMatch;
