import { useMemo } from 'react';
import { Languages } from 'types/enums';

export type PrefixByLocales = { [key in Languages]: string };

export const usePrefixByLocales: () => PrefixByLocales = () => {
    const prefixesWithLocale = useMemo(() => {
        return [
            ...Object.values(Languages).map((locale) =>
                locale === Languages.EN
                    ? [Languages.EN, '']
                    : [locale, `/${locale}`],
            ),
        ];
    }, []);

    return Object.fromEntries(prefixesWithLocale);
};
