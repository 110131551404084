import { gql } from '@apollo/client';
import {
    META_PAGE_FILTER_FRAGMENT,
    META_PAGE_TOTAL_FRAGMENT,
} from 'apollo/metaPage/metaPage.fragment';
import { SAMPLE_FRAGMENT } from './sample.fragment';

export const Sample = gql`
    query Sample($sampleGuid: ID!) {
        sample(sampleGuid: $sampleGuid) {
            ...SampleFragment
        }
    }
    ${SAMPLE_FRAGMENT}
`;

export const SampleIsDownloaded = gql`
    query SampleIsDownloaded($guid: ID!) {
        sample(sampleGuid: $guid) {
            guid
            isDownloaded
        }
    }
`;

export const PaginatedSamples = gql`
    query PaginatedSamples(
        $take: Int = 1
        $page: Int = 0
        $query: String
        $offset: Int
    ) {
        paginatedSamples(
            take: $take
            page: $page
            query: $query
            offset: $offset
        ) {
            data {
                ...SampleFragment
            }
            meta {
                ...MetaPageTotalFragment
                ...MetaPageFilterFragment
            }
            next {
                skip
            }
        }
    }
    ${SAMPLE_FRAGMENT}
    ${META_PAGE_TOTAL_FRAGMENT}
    ${META_PAGE_FILTER_FRAGMENT}
`;

export const PaginatedDownloadedSamples = gql`
    query PaginatedDownloadedSamples(
        $take: Int = 1
        $page: Int = 0
        $query: String
        $offset: Int
    ) {
        paginatedDownloadedSamples(
            take: $take
            page: $page
            query: $query
            offset: $offset
        ) {
            data {
                ...SampleFragment
            }
            meta {
                ...MetaPageTotalFragment
                ...MetaPageFilterFragment
            }
            next {
                skip
            }
        }
    }
    ${META_PAGE_TOTAL_FRAGMENT}
    ${META_PAGE_FILTER_FRAGMENT}
    ${SAMPLE_FRAGMENT}
`;

export const PaginatedFavoritedSamples = gql`
    query PaginatedFavoritedSamples(
        $take: Int = 1
        $page: Int = 0
        $query: String
        $offset: Int
    ) {
        paginatedFavoritedSamples(
            take: $take
            page: $page
            query: $query
            offset: $offset
        ) {
            data {
                ...SampleFragment
            }
            meta {
                ...MetaPageTotalFragment
                ...MetaPageFilterFragment
            }
            next {
                skip
            }
        }
    }
    ${META_PAGE_TOTAL_FRAGMENT}
    ${META_PAGE_FILTER_FRAGMENT}
    ${SAMPLE_FRAGMENT}
`;

export const PaginatedRecommendedSamples = gql`
    query PaginatedRecommendedSamples(
        $sampleGuid: ID!
        $take: Int = 1
        $page: Int = 0
        $query: String
        $offset: Int
    ) {
        paginatedRecommendedSamples(
            sampleGuid: $sampleGuid
            take: $take
            page: $page
            query: $query
            offset: $offset
        ) {
            data {
                ...SampleFragment
            }
            meta {
                ...MetaPageTotalFragment
                ...MetaPageFilterFragment
            }
            next {
                skip
            }
        }
    }
    ${META_PAGE_TOTAL_FRAGMENT}
    ${META_PAGE_FILTER_FRAGMENT}
    ${SAMPLE_FRAGMENT}
`;

export const UpdateSampleFavoritedState = gql`
    mutation UpdateSampleFavoritedState($sample: SampleFavoritedInput!) {
        updateSampleFavoritedState(sample: $sample) {
            guid
            isFavorited
        }
    }
`;

export const SampleStream = gql`
    query SampleStream($sampleGuid: ID!) {
        sampleStream(sampleGuid: $sampleGuid) {
            url
        }
    }
`;

// Un-used query needed for useLazyQuery, we should refacto query with useQuery skip and fetch instead https://mixgenius.atlassian.net/browse/SP-10379
export const CUSTOM_STREAM_QUERY = gql`
    query SampleStream($sampleGuid: ID!) {
        sampleStream(sampleGuid: $sampleGuid) {
            url
        }
    }
`;
