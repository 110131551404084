import { useState, useCallback } from 'react';
import { useToggle } from '@landr/maestro';

type UseOpenStateHandlersType = {
    autoOpenAndPlay: () => void;
    isCreatorOpen: boolean;
    toggleCreator: () => void;
    closeCreator: () => void;
    openCreator: () => void;
};

export const useOpenStateHandlers = (
    toggleSessionPause: () => void,
    setIsSessionProcessing: (isProcessing: boolean) => void,
): UseOpenStateHandlersType => {
    const {
        isActive: isCreatorOpen,
        toggle: toggleCreator,
        activate: openCreatorHandler,
        deactivate: closeCreator,
    } = useToggle();

    const [hasAutoOpened, setHasAutoOpened] = useState(false);

    // Sometime we have to programmatically open Creator and trigger processing UI
    const openCreator = useCallback(() => {
        setIsSessionProcessing(true);
        openCreatorHandler();
    }, [openCreatorHandler, setIsSessionProcessing]);

    const autoOpenAndPlay = useCallback(() => {
        if (!hasAutoOpened) {
            openCreator();
            setHasAutoOpened(true);
            toggleSessionPause();
        }
    }, [openCreator, hasAutoOpened, toggleSessionPause]);

    return {
        autoOpenAndPlay,
        isCreatorOpen,
        toggleCreator,
        openCreator,
        closeCreator,
    };
};
