import React, { useContext, useEffect, useState } from 'react';
import { User } from 'types/generated/graphql';
import { useUserProfile } from 'apollo/user';
import { log } from 'utils/log';
import { getUserCombinedName } from 'helpers/getUserCombinedName';

interface UserProfileProviderProps {
    children: React.ReactNode;
}

export type UserProfileContextType = {
    userProfile?: User;
    combinedName?: string;
};

export const defaultUserProfileContext = {
    userProfile: undefined,
    combinedName: undefined,
};

export const UserProfileContext = React.createContext<UserProfileContextType>(
    defaultUserProfileContext,
);

export const useUserProfileProvider = () => {
    const { userProfile } = useUserProfile();
    const [combinedName, setCombinedName] = useState<string | undefined>(
        undefined,
    );

    useEffect(() => {
        if (userProfile) {
            const userCombinedName = getUserCombinedName(userProfile);

            setCombinedName(userCombinedName);

            log.userProfile = userProfile;
        }
    }, [userProfile]);

    return { userProfile, combinedName };
};

export const UserProfileProvider = ({
    children,
}: UserProfileProviderProps): JSX.Element => {
    const { userProfile, combinedName } = useUserProfileProvider();

    return (
        <UserProfileContext.Provider
            value={{
                userProfile,
                combinedName,
            }}
        >
            {children}
        </UserProfileContext.Provider>
    );
};

export const useUserProfileContext = (): UserProfileContextType =>
    useContext(UserProfileContext);

export default UserProfileProvider;
