import axios, { AxiosInstance } from 'axios';
import { CONFIG } from './utils/config';

export type ApiMethods = 'post' | 'put' | 'patch' | 'get' | 'head' | 'delete';

const api: AxiosInstance = axios.create({
    baseURL: `${CONFIG.VITE_API_URL}/`,
});

export default api;
