import { defaultLocale } from 'constants/locale';
import { Languages } from 'types/enums';
import { useEffect, useState } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import { useAuthentication } from 'components/AuthenticationProvider/AuthenticationProvider';
import { getIsSupportedLocale } from './helpers/getIsSupportedLocale';
import { getIsUnsupportedLocale } from './helpers/getIsUnsupportedLocale';
import { activateLanguage } from './helpers/activateLanguage';
import { navigateIfNeeded } from './helpers/navigateIfNeeded';

export const useI18nLocaleDetection = () => {
    const match = useMatch('/:locale/*');
    const navigate = useNavigate();

    const { user, isLoading } = useAuthentication();

    const [locale, setLocale] = useState<Languages>();

    useEffect(() => {
        if (locale) {
            activateLanguage(locale);
        }
    }, [locale]);

    useEffect(() => {
        if (isLoading || locale) {
            return;
        }

        const userPreferredCulture = user?.preferredCulture;

        const browserLanguage = navigator?.language.substring(0, 2);
        const supportedBrowserLanguage = getIsSupportedLocale(browserLanguage)
            ? browserLanguage
            : undefined;

        const supportedUrlLanguage = getIsSupportedLocale(match?.params.locale)
            ? match?.params.locale
            : undefined;

        const unsupportedUrlLanguage = getIsUnsupportedLocale(
            match?.params.locale,
        )
            ? match?.params.locale
            : undefined;

        const urlLanguage = supportedUrlLanguage || unsupportedUrlLanguage;

        // user preferred language has highest priority.
        // We remove the locale from the path if present.
        if (userPreferredCulture) {
            const userLanguage = getIsSupportedLocale(userPreferredCulture)
                ? userPreferredCulture
                : defaultLocale;

            setLocale(userLanguage);
            navigateIfNeeded({
                remove: urlLanguage,
                navigate,
                pathname: match?.pathname,
            });
        }
        // we fallback to locale in the url if present.
        else if (supportedUrlLanguage) {
            setLocale(supportedUrlLanguage);
            navigateIfNeeded({
                remove:
                    supportedUrlLanguage === defaultLocale
                        ? supportedUrlLanguage
                        : undefined,

                navigate,
                pathname: match?.pathname,
            });
        }
        // we then fallback to browser language.
        // we add the locale in the url if different than default value
        else if (supportedBrowserLanguage) {
            setLocale(supportedBrowserLanguage);
            navigateIfNeeded({
                remove: unsupportedUrlLanguage,
                add: supportedBrowserLanguage,
                navigate,
                pathname: match?.pathname,
            });

            // last fallback is to use default locale.
        } else {
            setLocale(defaultLocale);
            navigateIfNeeded({
                remove: unsupportedUrlLanguage,
                navigate,
                pathname: match?.pathname,
            });
        }
    }, [match, user, isLoading, navigate, locale]);
};
