import React, { useContext, useState } from 'react';
import { useDevice } from '@landr/maestro';
import { CreatorSession } from 'libs/CreatorSession/CreatorSession';
import { useMountEffect } from 'hooks/useMountEffect';
import { neverop } from 'utils/noop';
import { getIsDAWPlugin } from 'helpers/executionEnvironment';
import { getCreatorSessionSetup } from './CreatorSessionContext.helpers';

export type CreatorSessionContextType = Pick<
    CreatorSession,
    | 'addTracks'
    | 'hasTrack'
    | 'currentTime'
    | 'getTrackGain'
    | 'pause'
    | 'removeAllTracks'
    | 'removeTrack'
    | 'setBaseBpm'
    | 'setBaseKey'
    | 'subscribe'
    | 'togglePause'
    | 'unsubscribe'
    | 'updateTrackBpm'
    | 'updateTrackBpmMultiplier'
    | 'updateTrackGain'
    | 'updateTrackTranspose'
    | 'generateMixdown'
    | 'cancelMixdown'
    | 'isInitialized'
    | 'setTrackUIReady'
    | '__resumeContext'
>;

// DEFAULT CONTEXT
const defaultCreatorSession: CreatorSessionContextType = {
    addTracks: neverop(
        'CreatorSession',
        'addTracks',
        new Promise<void>(() => undefined),
    ),
    hasTrack: neverop('CreatorSession', 'hasTrack', false),
    currentTime: 0,
    getTrackGain: neverop('CreatorSession', 'getTrackGain', 0),
    pause: neverop('CreatorSession', 'pause'),
    removeAllTracks: neverop('CreatorSession', 'removeAllTracks'),
    removeTrack: neverop('CreatorSession', 'removeTrack'),
    setBaseBpm: neverop('CreatorSession', 'setBaseBpm'),
    setBaseKey: neverop('CreatorSession', 'setBaseKey'),
    subscribe: neverop('CreatorSession', 'subscribe'),
    togglePause: neverop('CreatorSession', 'togglePause'),
    unsubscribe: neverop('CreatorSession', 'unsubscribe'),
    updateTrackBpm: neverop('CreatorSession', 'updateTrackBpm'),
    updateTrackBpmMultiplier: neverop(
        'CreatorSession',
        'updateTrackBpmMultiplier',
    ),
    updateTrackGain: neverop('CreatorSession', 'updateTrackGain'),
    updateTrackTranspose: neverop('CreatorSession', 'updateTrackTranspose'),
    generateMixdown: neverop(
        'CreatorSession',
        'generateMixdown',
        new Promise<Blob>(() => new Blob()),
    ),
    cancelMixdown: neverop('CreatorSession', 'cancelMixdown'),
    setTrackUIReady: neverop('CreatorSession', 'setTrackUIReady'),
    isInitialized: false,
    __resumeContext: neverop('CreatorSession', '__resumeContext'),
};

// CONTEXT
export const CreatorSessionContext =
    React.createContext<CreatorSessionContextType>(defaultCreatorSession);

export interface CreatorSessionContextProviderProps {
    children: React.ReactNode;
    isSafari: boolean;
}

// PROVIDER
// Used to initialize a "Session" in Creator, which encapsulates all the
// WebAudio API logic. It has its own state which should not be directly
// manipulated. Communication is handled via exposed functions and subscriptions.
// Should only be invoked once per page load.
export const CreatorSessionProvider = ({
    children,
}: CreatorSessionContextProviderProps): JSX.Element => {
    const [creatorSession, setCreatorSession] = useState(defaultCreatorSession);
    const isDAWPlugin = getIsDAWPlugin();

    // TODO: SP-8238 reverify that isSafari works as expected
    const { isAndroid, isIos, isSafari } = useDevice();
    const isLegacyDevice = isAndroid || isIos || isSafari;

    useMountEffect(
        getCreatorSessionSetup(setCreatorSession, isLegacyDevice, isDAWPlugin),
    );

    return (
        <CreatorSessionContext.Provider value={creatorSession}>
            {children}
        </CreatorSessionContext.Provider>
    );
};

export const useCreatorSession = (): CreatorSessionContextType =>
    useContext(CreatorSessionContext);

export default CreatorSessionProvider;
