import React, {
    useEffect,
    useReducer,
    useRef,
    useContext,
    useCallback,
} from 'react';
import { useToggle } from '@landr/maestro';
import { useMilestonesController } from 'controllers/business/Milestones/Milestones.controller';
import { useCreatorSession } from 'contexts/CreatorSessionContext/CreatorSessionContext';
import useExecutionEnvironment from 'hooks/useExecutionEnvironment';
import { useTrackPageVisited } from 'hooks/useTrackPageVisited';
import { neverop } from 'utils/noop';
import { CreatorContextType } from './Creator.types';
import creatorReducer, { defaultCreatorState } from './Creator.reducer';
import { useFetchSamples } from './hooks/useFetchSamples';
import { useOpenStateHandlers } from './hooks/useOpenStateHandlers';
import { usePlaybackStateHandlers } from './hooks/usePlaybackStateHandlers';
import { useTrackHandlers } from './hooks/useTrackHandlers';
import { useSessionLoadingHandlers } from './hooks/useSessionLoadingHandlers';
import { useGenerateMixdown } from './hooks/useGenerateMixdown';
import { getIsVisible } from './helpers/visibility.helpers';

export const CreatorLandingPageName = 'CreatorLandingPage';

export const DefaultCreatorContext: CreatorContextType = {
    state: defaultCreatorState,
    __resumeContext: neverop('CreatorController', '__resumeContext'),
    addTrack: neverop('CreatorController', 'addTrack'),
    autoOpenAndPlay: neverop('CreatorController', 'autoOpenAndPlay'),
    closeCreator: neverop('CreatorController', 'closeCreator'),
    closeLoadSessionModal: neverop(
        'CreatorController',
        'closeLoadSessionModal',
    ),
    closeRemoveAllModal: neverop('CreatorController', 'closeRemoveAllModal'),
    closeShareModal: neverop('CreatorController', 'closeShareModal'),
    dispatch: neverop('CreatorController', 'dispatch'),
    dispatchLoadSession: neverop('CreatorController', 'dispatchLoadSession'),
    isLandingPageVisited: true,
    isVisible: true,
    isInitialized: false,
    getCurrentTime: neverop('CreatorController', 'getCurrentTime', 0),
    getTrackGain: neverop('CreatorController', 'getTrackGain', 0),
    loadSessionByGuid: neverop('CreatorController', 'loadSessionByGuid'),
    openCreator: neverop('CreatorController', 'openCreator'),
    openLoadSessionModal: neverop('CreatorController', 'openLoadSessionModal'),
    openRemoveAllModal: neverop('CreatorController', 'openRemoveAllModal'),
    openShareModal: neverop('CreatorController', 'openShareModal'),
    pause: neverop('CreatorController', 'pause'),
    removeAllTracks: neverop('CreatorController', 'removeAllTracks'),
    removeTrack: neverop('CreatorController', 'removeTrack'),
    storeSession: neverop(
        'CreatorController',
        'storeSession',
        new Promise<null>((resolve) => resolve(null)),
    ),
    toggleCreator: neverop('CreatorController', 'toggleCreator'),
    togglePause: neverop('CreatorController', 'togglePause'),
    trackLandingPageVisited: neverop(
        'CreatorController',
        'trackLandingPageVisited',
    ),
    updateTrackBpm: neverop('CreatorController', 'updateTrackBpm'),
    updateTrackBpmMultiplier: neverop(
        'CreatorController',
        'updateTrackBpmMultiplier',
    ),
    updateTrackGain: neverop('CreatorController', 'updateTrackGain'),
    updateTrackTranspose: neverop('CreatorController', 'updateTrackTranspose'),
    subscriptions: {
        subscribe: neverop('CreatorController', 'subscribe'),
        unsubscribe: neverop('CreatorController', 'unsubscribe'),
    },
    generateMixdown: neverop(
        'CreatorController',
        'noopPromise',
        new Promise<undefined>((resolve) => resolve(undefined)),
    ),
    cancelMixdown: neverop('CreatorController', 'cancelMixdown'),
    setTrackUIReady: neverop('CreatorController', 'setTrackUIReady'),
};

export const CreatorContext = React.createContext<CreatorContextType>(
    DefaultCreatorContext,
);

export const useCreatorContext = (): CreatorContextType => {
    return useContext(CreatorContext);
};

export const CreatorContextProvider: React.FC = ({ children }) => {
    // CONTEXT
    const creatorSession = useCreatorSession();
    const { isDAWPlugin } = useExecutionEnvironment();
    const { perform: performMilestone } = useMilestonesController();

    // REDUCER
    const [state, dispatch] = useReducer(creatorReducer, defaultCreatorState);

    // REFS
    const samplesRef = useRef(state.samples);

    samplesRef.current = state.samples;

    // STATE
    // TODO: Those useToggle() can be removed from the context as they are not used internally - https://mixgenius.atlassian.net/browse/SP-5353
    const {
        isActive: isRemoveAllModalOpen,
        activate: openRemoveAllModal,
        deactivate: closeRemoveAllModal,
    } = useToggle();

    const {
        isActive: isShareModalOpen,
        activate: openShareModal,
        deactivate: closeShareModal,
    } = useToggle();

    // MEMOS
    const { samplesInSession } = useFetchSamples(
        state,
        creatorSession,
        dispatch,
    );

    // HANDLERS
    const {
        isUiPaused,
        setIsSessionProcessing,
        isSessionProcessing,
        togglePause,
    } = usePlaybackStateHandlers(dispatch);

    const {
        autoOpenAndPlay,
        isCreatorOpen,
        toggleCreator,
        closeCreator,
        openCreator,
    } = useOpenStateHandlers(togglePause, setIsSessionProcessing);

    const {
        addTrack,
        removeTrack,
        updateTrackGain,
        updateTrackBpm,
        updateTrackBpmMultiplier,
        updateTrackTranspose,
    } = useTrackHandlers(state, dispatch, samplesInSession, performMilestone);

    const {
        dispatchLoadSession,
        loadSessionByGuid,
        storeSession,
        isLoadSessionModalOpen,
        closeLoadSessionModal,
        openLoadSessionModal,
    } = useSessionLoadingHandlers(
        state,
        dispatch,
        setIsSessionProcessing,
        openCreator,
    );

    const { generateMixdown, cancelMixdown } =
        useGenerateMixdown(creatorSession);

    const getCurrentTime = useCallback(
        () => creatorSession.currentTime,
        [creatorSession],
    );

    // EFFECTS
    useEffect(() => {
        if (creatorSession.isInitialized) {
            creatorSession.setBaseBpm(state.bpm);
        }
    }, [state.bpm, creatorSession]);

    useEffect(() => {
        if (creatorSession.isInitialized) {
            creatorSession.setBaseKey(state.keyRoot, state.keyQuality);
        }
    }, [state.keyRoot, state.keyQuality, creatorSession]);

    const {
        isPageVisited: isLandingPageVisited,
        trackPageVisited: trackLandingPageVisited,
    } = useTrackPageVisited(CreatorLandingPageName);

    const isVisible = getIsVisible(isDAWPlugin, state.samples.size);

    return (
        <CreatorContext.Provider
            value={{
                state: {
                    ...state,
                    isRemoveAllModalOpen,
                    isLoadSessionModalOpen,
                    isShareModalOpen,
                    isUiPaused,
                    isSessionProcessing,
                    samplesInSession,
                    isCreatorOpen,
                },
                dispatch,
                addTrack,
                autoOpenAndPlay,
                closeCreator,
                closeLoadSessionModal,
                closeRemoveAllModal,
                closeShareModal,
                dispatchLoadSession,
                isLandingPageVisited,
                isVisible,
                isInitialized: creatorSession.isInitialized,
                getCurrentTime,
                getTrackGain: creatorSession.getTrackGain,
                loadSessionByGuid,
                openCreator,
                openLoadSessionModal,
                openRemoveAllModal,
                openShareModal,
                removeTrack,
                removeAllTracks: creatorSession.removeAllTracks,
                subscriptions: {
                    subscribe: creatorSession.subscribe,
                    unsubscribe: creatorSession.unsubscribe,
                },
                storeSession,
                toggleCreator,
                togglePause,
                trackLandingPageVisited,
                pause: creatorSession.pause,
                updateTrackBpm,
                updateTrackBpmMultiplier,
                updateTrackGain,
                updateTrackTranspose,
                generateMixdown,
                cancelMixdown,
                setTrackUIReady: creatorSession.setTrackUIReady,
                __resumeContext: creatorSession.__resumeContext,
            }}
        >
            {children}
        </CreatorContext.Provider>
    );
};

export default CreatorContextProvider;
