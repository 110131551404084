import { EventIds } from 'constants/eventIds';
import { useCallback } from 'react';
import { DownloadableItem } from 'types';
import { noop } from 'utils/noop';
import { DownloadType, ProblemDetailsType } from 'types/enums';
import { getProblemDetailsType } from 'types/errors';
import { ApolloError, useApolloClient } from '@apollo/client';
import { useUserCreditsContext } from 'contexts/UserCreditsContext/UserCreditsContext';
import {
    RequestPurchaseQueries,
    RequestPurchaseQueryItemName,
} from 'apollo/purchase';
import { InteractionSource } from 'utils/analytics/AnalyticsV2Service.generated';
import { PurchaseActionTypes } from '../Purchase.types';
import { PurchaseAction } from './purchaseReducer';
import { useUpdateIsDownloaded } from './useUpdateIsDownloaded';
import { getPurchaseVariables } from './getPurchaseVariables';

type HandlePurchaseParams = {
    item: DownloadableItem;
    interactionSource?: InteractionSource;
    onSuccess?: () => void;
};

export type HandlePurchase = (params: HandlePurchaseParams) => Promise<void>;

export const useHandlePurchase = ({
    skipDownload,
    dispatch,
}: {
    skipDownload: boolean;
    dispatch: React.Dispatch<PurchaseAction>;
}): HandlePurchase => {
    const apolloClient = useApolloClient();
    const { getUserCredits } = useUserCreditsContext();
    const updateIsDownloaded = useUpdateIsDownloaded();

    const onPurchaseItemCompleted = useCallback(
        (item: DownloadableItem, downloadUrl: string, onSuccess = noop) => {
            // Refetch user credits
            getUserCredits();
            updateIsDownloaded(item);

            if (!skipDownload) {
                dispatch({
                    type: PurchaseActionTypes.StartDownload,
                    downloadUrl,
                });
                return;
            }

            dispatch({ type: PurchaseActionTypes.Exit });
            onSuccess();

            return;
        },
        [dispatch, getUserCredits, skipDownload, updateIsDownloaded],
    );

    const onPurchaseItemError = useCallback(
        (error: ApolloError | undefined, itemId: string) => {
            const extensions = error?.graphQLErrors[0]?.extensions;
            const correlationId = (
                extensions?.exception as { correlationId?: string }
            )?.correlationId;

            if (extensions) {
                const { exception } = extensions;

                const type = getProblemDetailsType(
                    (exception as { type?: string })?.type,
                    error.message,
                );

                const isNotEnoughCreditsError =
                    type === ProblemDetailsType.NotEnoughCredits;
                const isPoorStanding =
                    type === ProblemDetailsType.PoorStandingOrSuspendedAccount;
                const isPlanPaused = type === ProblemDetailsType.PlanPaused;

                if (isNotEnoughCreditsError) {
                    dispatch({
                        type: PurchaseActionTypes.ShowNotEnoughCreditsWarning,
                    });
                    return;
                } else if (isPoorStanding) {
                    dispatch({
                        type: PurchaseActionTypes.ShowPoorStandingOrSuspended,
                    });
                    return;
                } else if (isPlanPaused) {
                    dispatch({
                        type: PurchaseActionTypes.ShowPlanPaused,
                    });
                    return;
                }
            }

            dispatch({
                type: PurchaseActionTypes.ExitWithError,
                error: {
                    message: 'Failed to purchase',
                    id: EventIds.FailedPurchase,
                    error,
                    extraInfos: {
                        correlationId: correlationId,
                        itemId: itemId,
                    },
                },
            });
        },
        [dispatch],
    );

    const handlePurchase = async ({
        item,
        interactionSource,
        onSuccess,
    }: {
        item: DownloadableItem;
        interactionSource?: InteractionSource;
        onSuccess?: () => void;
    }) => {
        const purchaseVariables = getPurchaseVariables(item);

        try {
            const { data } = await apolloClient.query({
                query: RequestPurchaseQueries[item.type],
                fetchPolicy: 'no-cache',
                variables: { ...purchaseVariables, interactionSource },
            });

            const downloadUrl =
                data[RequestPurchaseQueryItemName[item.type]].url;

            if (downloadUrl || item.type === DownloadType.CreatorMixdown) {
                onPurchaseItemCompleted(item, downloadUrl, onSuccess);
            } else {
                onPurchaseItemError(
                    new ApolloError({
                        errorMessage:
                            'Request returned but "url" was not provided',
                    }),
                    item.id,
                );
            }
        } catch (error) {
            onPurchaseItemError(error as ApolloError, item.id);
        }
    };

    return handlePurchase;
};
