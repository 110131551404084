import React, { useContext, useState, useCallback } from 'react';
import { neverop } from 'utils/noop';
import Gradient from 'components/Gradient/Gradient';
import getOpacityFromForegroundColor from 'helpers/getOpacityFromForegroundColor';
import { defaultTheme } from '@landr/maestro-legacy';

const defaultBackgroundColor = '#2c3c4d';
const defaultOpacity = defaultTheme.opacities['subtle'];

type Gradients = {
    background: string;
    opacity: number;
};

const useGradientController = () => {
    const [gradients, setGradients] = useState<Gradients>({
        background: defaultBackgroundColor,
        opacity: defaultOpacity,
    });

    const updateGradient = useCallback(
        (updateBackgroundColor?: string, updateForegroundColor?: string) => {
            const opacity = updateForegroundColor
                ? getOpacityFromForegroundColor(updateForegroundColor)
                : defaultOpacity;

            setGradients({
                background: updateBackgroundColor ?? defaultBackgroundColor,
                opacity: opacity,
            });
        },
        [],
    );

    return { updateGradient, ...gradients };
};

export type GradientContextType = {
    updateGradient: (
        updateBackgroundColor?: string,
        updateForegroundColor?: string,
    ) => void;
};

export const defaultGradientContext = {
    updateGradient: neverop('GradientContext', 'updateGradient'),
};

export const GradientContext = React.createContext<GradientContextType>(
    defaultGradientContext,
);

export const useGradientContext = (): GradientContextType =>
    useContext(GradientContext);

export const GradientProvider = ({
    children,
}: {
    children: React.ReactNode;
}): React.ReactElement => {
    const { updateGradient, background, opacity } = useGradientController();

    return (
        <GradientContext.Provider value={{ updateGradient }}>
            <Gradient backgroundColor={background} opacity={opacity} />
            {children}
        </GradientContext.Provider>
    );
};

export default GradientProvider;
