// Creator Session

import { gql } from '@apollo/client';
import { CREATOR_KEY_FRAGMENT, TRACK_FRAGMENT } from './creator.fragment';

export const SESSION_BY_ID = gql`
    query CreatorSessionById($sessionId: ID!) {
        creatorSessionById(sessionId: $sessionId) {
            guid
            bpm
            key {
                ...CreatorKeyFragment
            }
            samples {
                ...TrackFragment
            }
        }
    }
    ${CREATOR_KEY_FRAGMENT}
    ${TRACK_FRAGMENT}
`;

export const STORE_SESSION = gql`
    mutation StoreCreatorSession($session: CreatorSessionInput) {
        storeCreatorSession(session: $session) {
            guid
        }
    }
`;
