import { useCallback } from 'react';
import { Languages } from 'types/enums';
import { useAuthentication } from 'components/AuthenticationProvider/AuthenticationProvider';
import { i18n } from 'utils/setupTranslations';

export const useToLocalizedPath = () => {
    const { isAuthenticated } = useAuthentication();

    const toLocalizedPath = useCallback(
        (path: string) => {
            if (i18n.locale === Languages.EN || isAuthenticated) {
                return path;
            } else {
                return `/${i18n.locale}${path}`;
            }
        },
        [isAuthenticated],
    );

    return toLocalizedPath;
};
