import { CreatorDownloadSource } from 'types';
import { DownloadType } from 'types/enums';

export const isCreatorDownloadSource = (
    item: unknown,
): item is CreatorDownloadSource => {
    return (
        (item as CreatorDownloadSource).type === DownloadType.Creator ||
        (item as CreatorDownloadSource).type === DownloadType.CreatorMulti ||
        (item as CreatorDownloadSource).type === DownloadType.CreatorMixdown
    );
};
