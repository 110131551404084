import { EventIds } from 'constants/eventIds';
import { ApolloClient } from '@apollo/client';
import { noop } from 'utils/noop';
import { log } from 'utils/log';
import { SampleStream } from 'apollo/sample';
import { SESSION_BY_ID, STORE_SESSION } from 'apollo/creator/creator.gql';
import {
    CreatorSessionByIdQuery,
    CreatorSessionByIdQueryVariables,
} from 'types/generated/graphql';
import { CreatorAction, CreatorStoredState } from './Creator.types';

export const apolloGetSampleUrl = (
    sampleGuid: string,
    apolloClient: ApolloClient<object>,
    dispatch: React.Dispatch<CreatorAction>,
    errorNotification: () => void = noop,
): void => {
    apolloClient
        .query({
            query: SampleStream,
            fetchPolicy: 'no-cache',
            variables: { sampleGuid },
        })
        .then((payload: any) => {
            dispatch({
                type: 'setStreamUrl',
                payload: {
                    guid: sampleGuid,
                    streamUrl: payload.data.sampleStream.url,
                },
            });
        })
        .catch((e: Error) => {
            dispatch({
                type: 'removeSamples',
                payload: [sampleGuid],
            });

            log.error(
                `Failed to fetch a creator sample stream URL`,
                EventIds.FailedToFetchCreatorStreamURL,
                e,
                { sampleGuid: sampleGuid },
            );

            errorNotification();
        });
};

export const apolloGetSessionById = async (
    sessionId: string,
    apolloClient: ApolloClient<object>,
): Promise<CreatorStoredState | null> => {
    const session: CreatorStoredState | null = await apolloClient
        .query<CreatorSessionByIdQuery, CreatorSessionByIdQueryVariables>({
            query: SESSION_BY_ID,
            variables: { sessionId },
        })
        .then((payload) => {
            return payload.data.creatorSessionById ?? null;
        })
        .catch((e: Error) => {
            log.error(
                `Failed to fetch a session by it's Id`,
                EventIds.FailedGetSessionById,
                e,
            );
            return null;
        });

    return session;
};

export const apolloStoreSession = async (
    session: CreatorStoredState,
    apolloClient: ApolloClient<object>,
): Promise<string | null> => {
    const storeSession = await apolloClient
        .mutate({
            mutation: STORE_SESSION,
            variables: {
                session: session,
            },
        })
        .then((payload: any) => {
            return payload.data.storeCreatorSession.guid;
        })
        .catch((e: Error) => {
            log.error(
                'An error occurred while storing session',
                EventIds.StoreSessionError,
                e,
            );
            return null;
        });

    return storeSession;
};
