/**
 * Allows for downloads of samples by creating an iframe to trigger a download in a user's browser.
 *
 * @param {string} url The URL of the samples of pack to be downloaded. This should be a value returned from the API.
 */

export const download = (url: string | null | undefined): boolean => {
    if (url === null || url === undefined || url === '') {
        return false;
    }

    const iframe = document.createElement('iframe');

    document.body.appendChild(iframe);
    iframe.src = url;
    // Make the iframe invisible but still rendered
    iframe.style.position = 'absolute';
    iframe.style.top = '-10px';
    iframe.style.left = '-10px';
    iframe.style.height = '0px';
    iframe.style.width = '0px';
    iframe.style.border = 'none';
    iframe.style.visibility = 'hidden';

    // Destroy the iframe after a short delay so we don't have lingering iframes
    setTimeout(function () {
        if (iframe.parentElement === document.body) {
            document.body.removeChild(iframe);
        }
    }, 5000); // 5s is chosen to make sure there is enough time for the iframe to trigger the download before removing the iframe

    return true;
};

export const _cleanup = (link: HTMLAnchorElement) => {
    setTimeout(link.remove, 5000); // 5s is chosen to make sure there is enough link to trigger
};

export const _getDownloader =
    (link: HTMLAnchorElement, url: string, cleanup: typeof _cleanup) =>
    async (response: Response) => {
        const blob = await response.blob();
        const filename = new URL(url).pathname.split('/').filter(Boolean).pop();

        link.href = URL.createObjectURL(blob);
        link.download = filename as string;
        link.click();

        // Remove the link
        cleanup(link);
    };

/**
 * DO NOT USE
 *
 * Allows for downloads of samples and zips by fetching the data and then appending to a link
 * NOTE: The logic for extracting file names do not appear to working in all cases,
 * it will have to be fixed before being used
 * TODO: https://mixgenius.atlassian.net/browse/SP-8248
 *
 * @param {string} url The URL of the samples of pack to be downloaded. This should be a value returned from the API.
 */
export const corsSafeDownload = (url: string | null | undefined): boolean => {
    if (url === null || url === undefined || url === '') {
        return false;
    }

    const link = document.createElement('a');

    fetch(url, { mode: 'cors' }).then(_getDownloader(link, url, _cleanup));

    return true;
};
