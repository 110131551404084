import { EventIds } from 'constants/eventIds';
import { DownloadableItem } from 'types';
import { DownloadType } from 'types/enums';
import { useApolloClient } from '@apollo/client';
import {
    RequestCostPreviewQueries,
    RequestCostPreviewQueryItemName,
} from 'apollo/cost';
import { useSubscriptionsAndAllowedFeaturesContext } from 'contexts/SubscriptionsAndAllowedFeaturesContext/SubscriptionsAndAllowedFeaturesContext';
import { PurchaseActionTypes } from '../Purchase.types';
import { PurchaseAction } from './purchaseReducer';
export type HandleCostPreviewParams = {
    item: DownloadableItem;
};

export type HandleCostPreview = (params: HandleCostPreviewParams) => void;

export const useCostPreview = ({
    dispatch,
}: {
    dispatch: React.Dispatch<PurchaseAction>;
}): HandleCostPreview => {
    const {
        refetchIfNeeded: refetchSubscriptionsAndAllowedFeatures,
        isTrialSubscription,
    } = useSubscriptionsAndAllowedFeaturesContext();
    const apolloClient = useApolloClient();

    const handleCostPreview = async ({ item }: HandleCostPreviewParams) => {
        const shouldUseId = [
            DownloadType.Pack,
            DownloadType.Repack,
            DownloadType.Collection,
            DownloadType.CollectionMulti,
        ].includes(item.type);

        try {
            const { data } = await apolloClient.query({
                query: RequestCostPreviewQueries[item.type],
                fetchPolicy: 'no-cache',
                variables: {
                    guid: shouldUseId ? item.id : item.itemIds,
                },
            });

            const costPreviewData =
                data[RequestCostPreviewQueryItemName[item.type]];

            if (costPreviewData) {
                const {
                    credits: creditCost = 0,
                    remainingCredits = 0,
                    unavailabilityReason,
                } = costPreviewData;

                const isHasCost = creditCost > 0;
                const isNotEnoughCreditsError = remainingCredits < creditCost;
                const isPoorStanding =
                    unavailabilityReason === 'PoorStandingAccount';
                const isPlanPaused = unavailabilityReason === 'PlanPaused';

                refetchSubscriptionsAndAllowedFeatures({
                    isPoorStanding,
                    isPlanPaused,
                });

                if (isHasCost && isPoorStanding) {
                    dispatch({
                        type: PurchaseActionTypes.ShowPoorStandingOrSuspended,
                    });
                    return;
                } else if (isHasCost && isPlanPaused) {
                    dispatch({
                        type: PurchaseActionTypes.ShowPlanPaused,
                    });
                    return;
                } else if (isHasCost && isTrialSubscription) {
                    dispatch({
                        type: PurchaseActionTypes.ShowActivateTrialSubscription,
                    });
                    return;
                } else if (isNotEnoughCreditsError) {
                    dispatch({
                        type: PurchaseActionTypes.ShowNotEnoughCreditsWarning,
                        purchaseCostInCredits: creditCost,
                    });
                    return;
                }

                dispatch({
                    type: PurchaseActionTypes.SetPurchaseCost,
                    purchaseCostInCredits: creditCost,
                    remainingCredits,
                });
            } else {
                throw new Error(
                    `Fetched ${item.type} did not contain cost preview data`,
                );
            }
        } catch (error) {
            dispatch({
                type: PurchaseActionTypes.ExitWithError,
                error: {
                    message: `Failed to fetch ${item.type} download cost`,
                    id: EventIds.RequestDownloadCostFailed,
                    error: error as Error,
                },
            });
        }
    };

    return handleCostPreview;
};
