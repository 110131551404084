import { useMemo, useReducer } from 'react';
import { useNotificationsContext } from 'contexts/NotificationsContext/NotificationsContext';
import { useModalContext } from 'contexts/ModalContext/ModalContext';
import useExecutionEnvironment from 'hooks/useExecutionEnvironment';
import { useGenerateMixdown } from 'controllers/business/Creator/hooks/useGenerateMixdown';
import { useCreatorSession } from 'contexts/CreatorSessionContext/CreatorSessionContext';
import { useSubscriptionsAndAllowedFeaturesContext } from 'contexts/SubscriptionsAndAllowedFeaturesContext/SubscriptionsAndAllowedFeaturesContext';
import { useCostPreview } from './helpers/useCostPreview';
import { useHandlePurchase } from './helpers/useHandlePurchase';
import { useHandleDownload } from './helpers/useHandleDownload';
import { usePurchaseControllerCallbacks } from './helpers/usePurchaseControllerCallbacks';
import { usePurchaseFlow } from './helpers/usePurchaseFlow';
import { useHandlePurchaseError } from './helpers/useHandlePurchaseError';
import { useErrorNotification } from './helpers/useErrorNotification';
import {
    purchaseReducer,
    initialPurchaseState,
} from './helpers/purchaseReducer';
import { IPurchaseContext } from './Purchase.types';

export const usePurchaseController = (): IPurchaseContext => {
    // REDUCER
    const [purchaseState, dispatch] = useReducer(
        purchaseReducer,
        initialPurchaseState,
    );

    const {
        activeStep,
        isLoading,
        item,
        purchaseCostInCredits,
        purchaseQueue,
        skipDownload,
    } = purchaseState;

    // CONTEXTS
    const { createNotification } = useNotificationsContext();
    const { isDAWPlugin } = useExecutionEnvironment();
    const { hasSubscription } = useSubscriptionsAndAllowedFeaturesContext();
    const { openModal } = useModalContext();

    // HELPERS
    const showErrorNotification = useErrorNotification({
        skipDownload,
        createNotification,
    });
    const creatorSession = useCreatorSession();
    const { generateMixdown } = useGenerateMixdown(creatorSession);

    // FLOW HANDLERS
    const handleError = useHandlePurchaseError({ showErrorNotification });
    const handlePurchase = useHandlePurchase({ dispatch, skipDownload });
    const handleDownload = useHandleDownload({ handleError, generateMixdown });
    const handleCostPreview = useCostPreview({ dispatch });

    // Most flow logic lives here
    usePurchaseFlow({
        dispatch,
        handleCostPreview,
        handleDownload,
        handleError,
        handlePurchase,
        hasSubscription,
        openModal,
        purchaseState,
    });

    // DERIVED PROPERTIES
    const queuedItemIDs = useMemo(
        () =>
            [
                item?.itemIds ?? [],
                ...purchaseQueue.map((config) => config.item.itemIds),
            ].flat(2),
        [item, purchaseQueue],
    );

    // CALLBACKS
    const {
        approveCreditUse,
        startPurchase,
        cancelPurchase,
        showNotEnoughCreditsWarning,
        showPoorStandingOrSuspended,
    } = usePurchaseControllerCallbacks({
        activeStep,
        dispatch,
        isDAWPlugin,
        purchaseQueue,
    });

    return {
        item,
        isLoading,
        purchaseCostInCredits,
        approveCreditUse,
        queuedItemIDs,
        startPurchase,
        cancelPurchase,
        showNotEnoughCreditsWarning,
        showPoorStandingOrSuspended,
    };
};
