// eslint-disable-next-line no-restricted-imports
import { i18n } from '@lingui/core';
import { en, fr, es } from 'make-plural/plurals';
import { messages as enMessages } from 'locale/en/messages';
import { messages as frMessages } from 'locale/fr/messages';
import { messages as esMessages } from 'locale/es/messages';
import { Languages } from 'types/enums';

i18n.loadLocaleData({
    [Languages.EN]: { plurals: en },
    [Languages.FR]: { plurals: fr },
    [Languages.ES]: { plurals: es },
});

i18n.load(Languages.EN, enMessages);
i18n.load(Languages.FR, frMessages);
i18n.load(Languages.ES, esMessages);

if (process.env.NODE_ENV === 'test') {
    i18n.activate(Languages.EN);
}

export { i18n };
