import { gql } from '@apollo/client';

export const GENRE_FRAGMENT = gql`
    fragment GenreFragment on Genre {
        code
    }
`;

export const INSTRUMENT_FRAGMENT = gql`
    fragment InstrumentFragment on Instrument {
        code
        isFamily
    }
`;

export const SFX_FRAGMENT = gql`
    fragment SfxFragment on Sfx {
        code
    }
`;

export const TAG_FRAGMENT = gql`
    fragment TagFragment on Tag {
        code
        displayName
    }
`;

export const FILTER_FAMILY_FRAGMENT = gql`
    fragment FilterFamilyFragment on FilterFamily {
        code
        subFilters {
            code
        }
    }
`;

export const FILTER_WITH_LABEL_FRAGMENT = gql`
    fragment FilterWithLabelFragment on FilterWithLabel {
        code
        label
    }
`;
