import { EXECUTION_CONTEXT_URL_PARAM } from '../../src/constants';

const ParamsToRemove = ['error', 'error_description'];

/**
 * Get the URI encoded return url (location.href) - used
 * to provide a path back to the current page when prompting the user to temporarily
 * leave the app, such as for authentication or purchase flows.
 *
 * Note: This path is URIComponent encoded, making it suitable for embedding in another URL,
 * but will not work with `history.push`.
 */
export function getReturnUrl() {
    const params = new URLSearchParams(window.location.search);

    params.delete(EXECUTION_CONTEXT_URL_PARAM);

    const paramsString = params.toString();
    const search = paramsString ? `?${paramsString}` : '';
    const pathname = window.location.pathname ?? '';

    const returnUrl = `${window.location.origin}${pathname}${search}`;

    return encodeURIComponent(returnUrl);
}

/**
 * Get the URI encoded return path (location.pathname + location.search) - used
 * to provide a path back to the current page when prompting the user to temporarily
 * leave the app, such as for authentication or purchase flows.
 *
 * Note: This path will is URI encoded, making it suitable for use with `history.push`,
 * but will not work if embedded in another URL.*
 */
export function getTargetUrl() {
    const params = new URLSearchParams(window.location.search);

    ParamsToRemove.forEach((param) => params.delete(param));

    return encodeURI(`${window.location.pathname}?${params.toString()}`);
}
