import { EventIds } from 'constants/eventIds';
import { MixdownMaxSeconds, MixdownMinSeconds } from 'constants/mixdown';
import { CreatorSessionContextType } from 'contexts/CreatorSessionContext/CreatorSessionContext';
import { useNotificationsContext } from 'contexts/NotificationsContext/NotificationsContext';
import { CONFIG } from 'utils/config';
import { log } from 'utils/log';
import { CreatorMixdownError } from 'contexts/NotificationsContext/notifications';

export type GenerateMixdown = (
    downloadEnabled?: boolean,
) => Promise<Blob | undefined>;

export const useGenerateMixdown = (
    creatorSession: CreatorSessionContextType,
): {
    generateMixdown: GenerateMixdown;
    cancelMixdown: () => void;
} => {
    const { createNotification } = useNotificationsContext();

    const generateMixdown = async (downloadEnabled = false) => {
        downloadEnabled =
            downloadEnabled || CONFIG.VITE_DOWNLOAD_MIXDOWN === 'true';

        try {
            return await creatorSession.generateMixdown(
                MixdownMinSeconds,
                MixdownMaxSeconds,
                downloadEnabled,
            );
        } catch (error) {
            createNotification(new CreatorMixdownError());

            log.error(
                'Error during Creator mixdown generation',
                EventIds.CreatorMixdownMp3ConversionFailed,
                error,
            );
        }
    };

    const cancelMixdown = () => {
        creatorSession.cancelMixdown();
    };

    return { generateMixdown, cancelMixdown };
};
