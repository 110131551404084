import { ApolloCache } from '@apollo/client';

export const evictCacheByKeys = (
    cache: ApolloCache<any>,
    substrings: (string | undefined)[],
) => {
    const cacheKeys = Object.keys((cache as any)?.data?.data?.ROOT_QUERY || {});

    cacheKeys?.forEach((cacheKey) => {
        if (
            substrings.every(
                (substring) => !substring || !cacheKey.includes(substring),
            )
        ) {
            return;
        }

        cache.evict({ id: 'ROOT_QUERY', fieldName: cacheKey });
    });

    cache.gc();
};
