import { DownloadableItem } from 'types/downloadableItem';
import { InteractionSource } from 'utils/analytics/AnalyticsV2Service.generated';
import {
    PurchaseActionTypes,
    PurchaseConfig,
    PurchaseError,
    PurchaseSteps,
} from '../Purchase.types';

export type PurchaseState = {
    activeStep: PurchaseSteps;
    downloadUrl: string | undefined;
    error: PurchaseError | undefined;
    isLoading: boolean;
    item: DownloadableItem | undefined;
    purchaseCostInCredits: number;
    purchaseQueue: PurchaseConfig[];
    remainingCredits: number;
    skipDownload: boolean;
    skipCreditUseApproval: boolean;
    interactionSource?: InteractionSource;
    onSuccess?: () => void;
};

export const initialPurchaseState: PurchaseState = {
    activeStep: PurchaseSteps.Empty,
    downloadUrl: undefined,
    error: undefined,
    isLoading: false,
    item: undefined,
    purchaseCostInCredits: 0,
    purchaseQueue: [],
    remainingCredits: 0,
    skipDownload: false,
    skipCreditUseApproval: false,
    interactionSource: undefined,
    onSuccess: undefined,
};

export type PurchaseAction =
    | {
          type: PurchaseActionTypes.Exit;
      }
    | {
          type: PurchaseActionTypes.ExitWithError;
          error: PurchaseError;
      }
    | {
          type: PurchaseActionTypes.Start;
          item: DownloadableItem;
          purchaseQueue: PurchaseConfig[];
          skipCreditUseApproval: boolean;
          skipDownload: boolean;
          interactionSource?: InteractionSource;
          onSuccess?: () => void;
      }
    | {
          type: PurchaseActionTypes.UpdateQueue;
          purchaseQueue: PurchaseConfig[];
      }
    | {
          type: PurchaseActionTypes.SetPurchaseCost;
          purchaseCostInCredits: number;
          remainingCredits: number;
      }
    | {
          type: PurchaseActionTypes.Resume;
      }
    | {
          type: PurchaseActionTypes.StartDownload;
          downloadUrl: string;
      }
    | {
          type: PurchaseActionTypes.ShowNotEnoughCreditsWarning;
          purchaseCostInCredits?: number;
      }
    | {
          type: PurchaseActionTypes.ApproveCreditUse;
      }
    | {
          type: PurchaseActionTypes.ShowPoorStandingOrSuspended;
      }
    | {
          type: PurchaseActionTypes.ShowPlanPaused;
      }
    | {
          type: PurchaseActionTypes.ShowActivateTrialSubscription;
      };

export function purchaseReducer(
    state: PurchaseState,
    action: PurchaseAction,
): PurchaseState {
    let newState = state;

    switch (action.type) {
        case PurchaseActionTypes.Exit:
            newState = {
                ...initialPurchaseState,
                purchaseQueue: state.purchaseQueue,
                activeStep: PurchaseSteps.Empty,
            };
            break;
        case PurchaseActionTypes.ExitWithError:
            newState = {
                ...state,
                isLoading: false,
                error: action.error,
                activeStep: PurchaseSteps.DidError,
            };
            break;
        case PurchaseActionTypes.Start:
            newState = {
                ...initialPurchaseState,
                activeStep: PurchaseSteps.AwaitingCostPreview,
                isLoading: true,
                item: action.item,
                purchaseQueue: action.purchaseQueue,
                skipCreditUseApproval: action.skipCreditUseApproval,
                skipDownload: action.skipDownload,
                interactionSource: action.interactionSource,
                onSuccess: action.onSuccess,
            };
            break;

        case PurchaseActionTypes.UpdateQueue:
            newState = {
                ...state,
                purchaseQueue: action.purchaseQueue,
            };
            break;
        case PurchaseActionTypes.Resume:
            newState = {
                ...state,
                activeStep: PurchaseSteps.AwaitingCostPreview,
                isLoading: true,
            };
            break;
        case PurchaseActionTypes.SetPurchaseCost:
            newState = {
                ...state,
                activeStep: PurchaseSteps.CostPreviewReady,
                purchaseCostInCredits: action.purchaseCostInCredits,
                remainingCredits: action.remainingCredits,
                isLoading: true,
            };
            break;
        case PurchaseActionTypes.ShowNotEnoughCreditsWarning:
            newState = {
                ...state,
                purchaseCostInCredits:
                    action.purchaseCostInCredits ?? state.purchaseCostInCredits,
                activeStep: PurchaseSteps.NotEnoughCredits,
                isLoading: false,
            };
            break;

        case PurchaseActionTypes.ShowPoorStandingOrSuspended:
            newState = {
                ...state,
                activeStep: PurchaseSteps.PoorStandingOrSuspended,
                isLoading: false,
            };
            break;

        case PurchaseActionTypes.ShowPlanPaused:
            newState = {
                ...state,
                activeStep: PurchaseSteps.PlanPaused,
                isLoading: false,
            };
            break;
        case PurchaseActionTypes.ShowActivateTrialSubscription:
            newState = {
                ...state,
                activeStep: PurchaseSteps.TrialSubscription,
                isLoading: false,
            };
            break;
        case PurchaseActionTypes.ApproveCreditUse:
            newState = {
                ...state,
                activeStep: PurchaseSteps.CreditUseApproved,
            };
            break;
        case PurchaseActionTypes.StartDownload:
            newState = {
                ...state,
                activeStep: PurchaseSteps.ReadyToDownload,
                downloadUrl: action.downloadUrl,
                isLoading: false,
            };
            break;
        default:
            newState = state;
    }

    return newState;
}
