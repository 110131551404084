import { NotificationTimeout } from 'constants/notifications';
import { NotificationVariant } from '@landr/maestro';
import { v4 as uuidV4 } from 'uuid';

export abstract class NotificationBase {
    id: string;
    timeout?: number;
    variant: NotificationVariant;

    constructor(id?: string) {
        this.id = id ?? uuidV4();
        this.variant = 'success';
        this.timeout = NotificationTimeout;
    }

    getMessage: (isMobile?: boolean) => string | React.ReactNode = () => '';
}

export type RemoveNotificationType = (id: string) => void;

export type NotificationsContextType = {
    notifications: NotificationBase[];
    createNotification: <T extends NotificationBase>(notification: T) => void;
    removeNotification: RemoveNotificationType;
};
