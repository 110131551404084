import { EventIds } from 'constants/eventIds';
import unmuteAudio from 'unmute-ios-audio';
import { KeyRoot } from 'types/enums';
import { log } from 'utils/log';
import { isInRange } from 'helpers/isInRange';
import { DefaultCreatorConfig } from 'controllers/business/Creator/helpers/misc.helpers';
import { CreatorBpmMultiplier } from 'controllers/business/Creator/Creator.types';
import { KeyQuality, KeyRootApi } from 'types/generated/graphql';

/**
 * @module CreatorSessionUtils
 */

const IntervalTolerance = 0.01; // in percent / 100

export const KeyAsNumber = {
    [KeyRoot.C]: 0,
    [KeyRoot.Csharp]: 1,
    [KeyRootApi.CSharpDFlat]: 1,
    [KeyRoot.Db]: 1,
    [KeyRoot.D]: 2,
    [KeyRoot.Dsharp]: 3,
    [KeyRootApi.DSharpEFlat]: 3,
    [KeyRoot.Eb]: 3,
    [KeyRoot.E]: 4,
    [KeyRoot.F]: 5,
    [KeyRoot.Fsharp]: 6,
    [KeyRootApi.FSharpGFlat]: 6,
    [KeyRoot.Gb]: 6,
    [KeyRoot.G]: 7,
    [KeyRoot.Gsharp]: 8,
    [KeyRootApi.GSharpAFlat]: 8,
    [KeyRoot.Ab]: 8,
    [KeyRoot.A]: 9,
    [KeyRoot.Asharp]: 10,
    [KeyRootApi.ASharpBFlat]: 10,
    [KeyRoot.Bb]: 10,
    [KeyRoot.B]: 11,
};

// Given a musical key name represented in either the format "A#" or "ASharpBFlat"
// and a key quality, "major" or "minor", returns an integer from 0 to 11, starting
// with 0 for C and ending with 11 for B
export function getKeyAsSemitone(
    root?: KeyRoot | KeyRootApi,
    quality?: KeyQuality,
): number | null {
    // Do not pitchshift sample without key
    if (!root) {
        return null;
    }

    let keyAsNumber = KeyAsNumber[root];

    if (quality === KeyQuality.Minor) {
        // convert to relative major by adding three semitones
        keyAsNumber += 3;
        // no keys bigger than 11
        keyAsNumber %= 12;
    }

    return keyAsNumber;
}

export const wrapOffsetToRange = (
    offset: number,
    maxOffset: number,
    octaveShift: 1 | 2,
): number => {
    const octaveShiftInSemitone = octaveShift * 12;
    let newOffset = offset;

    if (newOffset > maxOffset) {
        newOffset = -(octaveShiftInSemitone - newOffset);
    } else if (newOffset < -maxOffset) {
        newOffset = octaveShiftInSemitone + newOffset;
    }

    return newOffset;
};

// Get the smallest scale interval between two keyes
export function getKeyOffset(base: number, key: number): number {
    const tentativeOffset = base - key;

    return wrapOffsetToRange(tentativeOffset, 5, 1);
}

// Get the interval within an acceptable range of -12 to +12
export function getSemitoneShift(offset: number): number {
    return wrapOffsetToRange(offset, 12, 1);
}

// Returns the scale factor needed to convert a given bpm to a base BPM
export function getTimeFactor(
    baseBPM: number,
    bpm?: number,
    bpmMultiplier = 'None' as CreatorBpmMultiplier,
): [number, CreatorBpmMultiplier, number] {
    const _baseBpm = baseBPM;
    let bpmMultiplied;
    const isBpmInRange = isInRange(bpm, { min: 0, max: 2400 });
    const isBaseBpmInRange = isInRange(baseBPM, {
        min: DefaultCreatorConfig.MIN_SESSION_BPM,
        max: DefaultCreatorConfig.MAX_SESSION_BPM,
    });

    if (!bpm || !isBpmInRange || !isBaseBpmInRange) {
        return [1, bpmMultiplier, 1];
    }

    switch (bpmMultiplier) {
        case 'Half':
            bpmMultiplied = bpm * 2;
            break;
        case 'Double':
            bpmMultiplied = bpm / 2;
            break;
        default:
            bpmMultiplied = bpm;
            break;
    }

    const bpmScaleBeforeMultiplier =
        Math.round((_baseBpm * 10000) / bpm) / 10000;
    const bpmScale = Math.round((_baseBpm * 10000) / bpmMultiplied) / 10000;

    if (
        (bpmScale < DefaultCreatorConfig.TRACK_MIN_TIME_FACTOR &&
            bpmMultiplier === 'Half') ||
        (bpmScale > DefaultCreatorConfig.TRACK_MAX_TIME_FACTOR &&
            bpmMultiplier === 'Double')
    ) {
        return getTimeFactor(baseBPM, bpm, 'None');
    }

    // Sample is too fast, and BPM matching means slowing it down to less than
    // half time. So instead we calculate the ratio at half the sample's bpm.
    if (bpmScale < DefaultCreatorConfig.TRACK_MIN_TIME_FACTOR) {
        return getTimeFactor(baseBPM, bpmMultiplied, 'Double');
    }

    // Sample is too slow, and BPM matching means speeding it up to more than
    // double time. So instead we calculate the ratio at twice the sample's bpm.
    if (bpmScale > DefaultCreatorConfig.TRACK_MAX_TIME_FACTOR) {
        return getTimeFactor(baseBPM, bpmMultiplied, 'Half');
    }

    return [bpmScale, bpmMultiplier, bpmScaleBeforeMultiplier];
}

// Given a duration and a bpmScale (such as the one returned from getBPMScale),
// returns a new duration scaled by the bpmScale
export function getScaledDuration(
    durationInSeconds: number,
    bpmScale: number,
): number {
    const BIG_DURATION_MULTIPLIER = 10000;
    const BIG_SCALE_MULTIPLIER = 100; // BIG_DURATION_MULTIPLIER / 100
    const SCALED_DURATION_MULTIPLIER = 100; // BIG_DURATION_MULTIPLIER / BIG_SCALE_MULTIPLIER

    if (bpmScale === 1) {
        return durationInSeconds;
    }

    // Scale in the integers domain to avoid rounding errors
    const bigDuration = durationInSeconds * BIG_DURATION_MULTIPLIER;
    const bigScale = bpmScale * BIG_SCALE_MULTIPLIER; // (scale * DURATION_SCALE_FACTOR) / 100
    const bigScaledDuration = bigDuration / bigScale;
    const scaledDuration = bigScaledDuration / SCALED_DURATION_MULTIPLIER;

    return scaledDuration;
}

export function getIntervalCount(
    duration: number,
    baseDuration: number,
): number {
    return Math.floor(baseDuration / duration + IntervalTolerance);
}

const VolumeKnobGainMap = new Map([
    [0, 0],
    [1, 0.14],
    [2, 0.28],
    [3, 0.42],
    [4, 0.56],
    [5, 0.7],
    [6, 0.84],
    [7, 1.0],
    [8, 1.14],
    [9, 1.28],
    [10, 1.42],
]);

export function getGainFromKnobIndex(gainKnobIndex: number): number {
    const gain = VolumeKnobGainMap.get(gainKnobIndex);

    return typeof gain !== 'undefined' ? gain : 1;
}

export function getPeakAmplitude(
    leftBuffer: Float32Array,
    rightBuffer: Float32Array,
): number {
    const { length } = leftBuffer;

    let peak = 0;

    for (let i = 0; i < length; i++) {
        const leftSample = Math.abs(leftBuffer[i]);
        const rightSample = Math.abs(rightBuffer[i]);

        if (leftSample > peak) {
            peak = leftSample;
        }

        if (rightSample > peak) {
            peak = rightSample;
        }
    }

    return peak;
}

/**
 * This function try to return a product of the value that fit within the range
 * Some value / range combinaison make it impossible, and expect a returned number to be out of the range in that case
 * @param value - The original number
 * @param range - an object containing a min and max value
 * @param counter - internal counter to prevent infinite loop
 */

export function productWithinRange(
    value: number,
    range: { min: number; max: number },
    counter = 1,
): number {
    // Max of 5 recursive calls
    if (counter > 5) {
        return value;
    }

    const isSmaller = value < range.min;
    const isBigger = value > range.max;
    const outOfRange = isSmaller || isBigger;

    if (outOfRange && value) {
        const factor = isSmaller ? 2 : 0.5;

        return productWithinRange(value * factor, range, ++counter);
    }

    return value;
}

/**
 * Given a duration and a BPM, returns the number of bars (4/4 time signature is
 * assumed). No rounding is applied.
 * @param duration - in seconds
 * @param bpm - beats per minute
 * @return length in 4/4 bars
 */

export function getDurationInBars(duration: number, bpm: number): number {
    const beatCount = bpm * (duration / 60);

    return beatCount / 4;
}

/**
 * Given a number of musical bars will return the closest "dyadic interval",
 * i.e. value of the 2's power series — 1, 2, 4, 8, 16, ...
 *
 * In the case of being exactly between two intervals, the LESSER interval will
 * be returned:
 *  clampToDyadicInterval(2.9) === 2
 *  clampToDyadicInterval(3.1) === 4
 *  clampToDyadicInterval(3) === 2    // exactly between 2 and 4
 *
 * @param bars - length in bars
 * @return nearest dyadic interval
 */

export function clampToDyadicInterval(bars: number): number {
    if (bars <= 1) {
        return 1;
    }

    let nearestInterval: number;

    const logRatio = Math.log(bars) / Math.log(2);
    const mantissa = logRatio - Math.floor(logRatio);

    // Round down if we're between intervals
    if (Math.pow(2, mantissa).toFixed(1) === '1.5') {
        nearestInterval = Math.floor(logRatio);
    } else {
        nearestInterval = Math.round(logRatio);
    }

    return Math.pow(2, nearestInterval);
}

/**
 * BPM are a measure of time, we could extrapolate a bpm from a file duration
 * Comparing with original bpm help to find how much adjustement is needed to get a whole number of beat
 * Know limitations -
 * Only work with 3/4, 4/4 and their equivalent time signature
 * Only work with loop properly cropped, without extra silence
 * Because we use the original BPM to find the closest BPM,
 * the output quality depends on the original BPM closest to reality
 * @param duration - the sample duration ( work with seconds or miliseconds )
 * @param originalBpm - the sample meta bpm
 * @return an array containing adjusted bpm, number of beat, time signature and number of bar
 */

export function getBpmFromDuration(
    duration: number,
    originalBpm: number,
): number {
    if (!duration) {
        return originalBpm;
    }

    if (!originalBpm) {
        // No bpm ? no problem just divide duration until we are within bpm range
        // Note: This assume everything is 4/4 and will not find correct bpm of 3/4 time signature, but they will be sync
        const beatDuration = productWithinRange(duration, {
            min: 0.25, // 240 bpm
            max: 0.75, // 80 bpm
        });

        // convert the beat length into a bpm
        originalBpm = 60 / beatDuration;
    }

    // Make sure originalBpm is within acceptable range
    const originalBpmWithinRange = productWithinRange(originalBpm, {
        min: 40,
        max: 240,
    });

    // Base on the BPM provided, how many beat in this sample ?
    const beatLength = 60 / originalBpmWithinRange;
    const beatsFromDuration = duration / beatLength;

    // Partial beat it's not a thing, round it
    const beatsRounded = Math.round(beatsFromDuration);

    // If beatsRounded doesn't match a /3 or /4 time signature,
    // The bpm provide was off or we have odd time signature
    // Get closest number of beat within a /3 or /4 time signature
    const modulo3 = beatsRounded % 3;
    const modulo4 = beatsRounded % 4;
    const beatsCount = beatsRounded - Math.min(modulo3, modulo4);

    // By how much the originalBpm was off ?
    const factor = beatsFromDuration / beatsCount;

    // Get the adjusted BPM
    const bpm = originalBpmWithinRange / factor;

    // Make sure output bpm is within acceptable range
    const bpmWithinRange = productWithinRange(bpm, {
        min: 40,
        max: 240,
    });

    return parseFloat(bpmWithinRange.toFixed(6));
}

const RecommendedOffsets = [-9, -7, -5, 0, 3, 5, 7];

const aMinusB = (a: number, b: number): number => a - b;

export const getRecommendedOffsets = (offsetBy: number): number[] => {
    const offsetRecommendedValues = RecommendedOffsets.map((recommendation) => {
        return wrapOffsetToRange(recommendation + offsetBy, 12, 2);
    });

    offsetRecommendedValues.sort(aMinusB);

    return offsetRecommendedValues;
};

export const unlockWebAudioContext = (context: AudioContext): void => {
    if (!context || !('currentTime' in context)) {
        log.error(
            `Invalid AudioContext`,
            EventIds.InvalidAudioContext,
            undefined,
            {
                info: `unlockWebAudioContext: You need to pass an instance of AudioContext to this method call`,
            },
        );

        return;
    }

    if ('ontouchstart' in window) {
        const unlock = (): void => {
            try {
                unmuteAudio();
            } catch (error) {
                log.error(
                    `Failed To Unlock AudioContext`,
                    EventIds.FailedToUnlockAudioContext,
                    error,
                );
            }
            document.body.removeEventListener('touchstart', unlock);
            document.body.removeEventListener('touchend', unlock);
        };

        document.body.addEventListener('touchstart', unlock, false);
        document.body.addEventListener('touchend', unlock, false);
    }
};

export function getDyadicBarsLengthInSeconds(
    lengthInBars: number,
    baseBpm: number,
): number {
    const barsLength = clampToDyadicInterval(lengthInBars);
    const barsLengthTime = barsLength * 4 * (1 / baseBpm) * 60;

    return barsLengthTime;
}

export function getDyadicBarsLengthInSamples(
    lengthInBars: number,
    baseBpm: number,
    sampleRate: number,
): number {
    const lengthInSeconds = getDyadicBarsLengthInSeconds(lengthInBars, baseBpm);
    const lengthInSamples = Math.floor(lengthInSeconds * sampleRate);

    return lengthInSamples;
}

export function getSampleCountFromSeconds(
    seconds: number,
    sampleRate: number,
): number {
    return seconds * sampleRate;
}

export function downloadMixdown(
    mp3Blob: Blob,
    keyRoot: KeyRoot | KeyRootApi,
    keyQuality: KeyQuality,
    bpm: number,
): void {
    const link = document.createElement('a');

    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1;
    const date = now.getDate().toString().padStart(2, '0');
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');

    const filename = `LANDR-Creator-fulltrack-${bpm}-${keyRoot}${keyQuality}-${year}${month}${date}-${hours}${minutes}${seconds}.mp3`;

    const url = URL.createObjectURL(mp3Blob);

    link.setAttribute('href', url);
    link.setAttribute('download', filename);

    link.style.visibility = 'hidden';

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
}
