import React, { useState, useContext } from 'react';
import { noop } from 'utils/noop';
import { ModalsEnum } from 'types/enums';
import { analytics } from 'utils/analytics';
import { enumToObject } from 'utils/generics/enumToObject';
import {
    ModalContextType,
    OpenModalType,
    CloseModalType,
} from './ModalContext.type';
import { modalInstrumentationV2 } from './ModalContext.helpers';

const isModalVisibleDefault = enumToObject<ModalsEnum, boolean>(
    ModalsEnum,
    () => false,
);

const openModalDefault = enumToObject<ModalsEnum, OpenModalType>(
    ModalsEnum,
    () => noop,
);

const closeModalDefault = enumToObject<ModalsEnum, CloseModalType>(
    ModalsEnum,
    () => noop,
);

export const defaultModalContext = {
    openModal: openModalDefault,
    closeModal: closeModalDefault,
    isModalVisible: isModalVisibleDefault,
};

export const ModalContext =
    React.createContext<ModalContextType>(defaultModalContext);

export const useModalContext = (): ModalContextType => useContext(ModalContext);

export const ModalProvider = ({
    children,
}: {
    children: React.ReactNode;
}): React.ReactElement => {
    // isModalVisibleDefault already map the enum to false for each modal
    const [isModalVisible, setIsModalVisible] = useState(isModalVisibleDefault);

    // Private function to open / close the modal
    const _openModal =
        (k: string) =>
        (onOpenCallback = noop) => {
            setIsModalVisible({
                ...isModalVisibleDefault,
                ...{ [k]: true },
            });

            const analyticsParams = modalInstrumentationV2[k as ModalsEnum];

            if (analyticsParams) {
                analytics.trackModalOpened(analyticsParams);
            }

            onOpenCallback();
        };

    const _closeModal =
        (k: string) =>
        (onCloseCallback = noop) => {
            setIsModalVisible(isModalVisibleDefault);

            onCloseCallback();
        };

    // For each value of ModalsEnum, map a props with a function to open / close this modal
    const openModal = enumToObject<ModalsEnum, OpenModalType>(
        ModalsEnum,
        _openModal,
    );

    const closeModal = enumToObject<ModalsEnum, CloseModalType>(
        ModalsEnum,
        _closeModal,
    );

    const context = {
        openModal,
        closeModal,
        isModalVisible,
    };

    return (
        <ModalContext.Provider value={context}>
            {children}
        </ModalContext.Provider>
    );
};

export default ModalProvider;
