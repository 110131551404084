import {
    OnboardingContextStateType,
    OnboardingAction,
} from './OnboardingContext.type';

export function onboardingReducer(
    state: OnboardingContextStateType,
    action: OnboardingAction,
): OnboardingContextStateType {
    let newState;

    switch (action.type) {
        case 'addWalkThrough':
            newState = {
                ...state,
                walkThrough: [...state.walkThrough, ...action.payload.steps],
            };

            return newState;

        case 'enterWalkThrough':
            newState = {
                ...state,
                source: action.payload.source,
                current: 0,
                step: state.walkThrough[0],
                total: state.walkThrough.length,
            };

            return newState;

        case 'nextStep':
            newState = {
                ...state,
                current: action.payload.nextStep,
                step: state.walkThrough[action.payload.nextStep],
            };
            action.payload.callback();

            return newState;

        case 'exitWalkThrough':
            newState = {
                ...state,
                walkThrough: [],
                source: undefined,
                step: undefined,
                current: 0,
                total: 0,
            };

            return newState;

        default:
            return {
                ...state,
            };
    }
}
