import { useEffect } from 'react';
import { ActivePlayerCallback } from '../types';

export const useKeyboardEvents = ({
    activePlayerCallback,
    activePlayerIdRef,
}: {
    activePlayerCallback: React.MutableRefObject<ActivePlayerCallback | null>;
    activePlayerIdRef: React.MutableRefObject<string | undefined>;
}) => {
    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent): void => {
            if (
                e.repeat ||
                !activePlayerCallback.current ||
                !activePlayerIdRef.current
            ) {
                return;
            }

            switch (e.key) {
                case ' ':
                    e.preventDefault();
                    activePlayerCallback.current.togglePlaying();
                    break;
                case 'Enter':
                    e.preventDefault();
                    activePlayerCallback.current.playFromBeginning();
                    break;
                case 'ArrowRight':
                    e.preventDefault();
                    activePlayerCallback.current.playForward();
                    break;
                case 'ArrowLeft':
                    e.preventDefault();
                    activePlayerCallback.current.playRewind();
                    break;
                default:
                // Do nothing
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return (): void => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [activePlayerCallback, activePlayerIdRef]);
};

export default useKeyboardEvents;
