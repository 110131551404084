import { KeyRootApi } from 'types/generated/graphql';

export const FlatGlyph = '\u266D';
export const SharpGlyph = '\u266F';

export enum KeyRoot {
    C = 'C',
    Csharp = 'C#',
    Db = 'Db',
    D = 'D',
    Dsharp = 'D#',
    Eb = 'Eb',
    E = 'E',
    F = 'F',
    Fsharp = 'F#',
    Gb = 'Gb',
    G = 'G',
    Gsharp = 'G#',
    Ab = 'Ab',
    A = 'A',
    Asharp = 'A#',
    Bb = 'Bb',
    B = 'B',
}

export function toSharpKeyRoot(value?: KeyRoot | string): KeyRoot {
    switch (value) {
        case KeyRootApi.A:
            return KeyRoot.A;
        case KeyRootApi.B:
            return KeyRoot.B;
        case KeyRootApi.C:
            return KeyRoot.C;
        case KeyRootApi.D:
            return KeyRoot.D;
        case KeyRootApi.E:
            return KeyRoot.E;
        case KeyRootApi.F:
            return KeyRoot.F;
        case KeyRootApi.G:
            return KeyRoot.G;
        case KeyRootApi.ASharpBFlat:
            return KeyRoot.Asharp;
        case KeyRootApi.CSharpDFlat:
            return KeyRoot.Csharp;
        case KeyRootApi.DSharpEFlat:
            return KeyRoot.Dsharp;
        case KeyRootApi.FSharpGFlat:
            return KeyRoot.Fsharp;
        case KeyRootApi.GSharpAFlat:
            return KeyRoot.Gsharp;

        default:
            return KeyRoot.C;
    }
}

export function toFlatKeyRoot(value?: KeyRoot | string): KeyRoot {
    switch (value) {
        case KeyRootApi.A:
            return KeyRoot.A;
        case KeyRootApi.B:
            return KeyRoot.B;
        case KeyRootApi.C:
            return KeyRoot.C;
        case KeyRootApi.D:
            return KeyRoot.D;
        case KeyRootApi.E:
            return KeyRoot.E;
        case KeyRootApi.F:
            return KeyRoot.F;
        case KeyRootApi.G:
            return KeyRoot.G;
        case KeyRootApi.ASharpBFlat:
            return KeyRoot.Bb;
        case KeyRootApi.CSharpDFlat:
            return KeyRoot.Db;
        case KeyRootApi.DSharpEFlat:
            return KeyRoot.Eb;
        case KeyRootApi.FSharpGFlat:
            return KeyRoot.Gb;
        case KeyRootApi.GSharpAFlat:
            return KeyRoot.Ab;

        default:
            return KeyRoot.C;
    }
}

export function toKeyRootApi(keyRoot?: KeyRoot | string): KeyRootApi {
    switch (keyRoot) {
        case KeyRoot.A:
            return KeyRootApi.A;
        case KeyRoot.Asharp:
        case KeyRoot.Bb:
        case KeyRootApi.ASharpBFlat:
            return KeyRootApi.ASharpBFlat;
        case KeyRoot.B:
            return KeyRootApi.B;
        case KeyRoot.C:
            return KeyRootApi.C;
        case KeyRoot.Csharp:
        case KeyRoot.Db:
        case KeyRootApi.CSharpDFlat:
            return KeyRootApi.CSharpDFlat;
        case KeyRoot.D:
            return KeyRootApi.D;
        case KeyRoot.Dsharp:
        case KeyRoot.Eb:
        case KeyRootApi.DSharpEFlat:
            return KeyRootApi.DSharpEFlat;
        case KeyRoot.E:
            return KeyRootApi.E;
        case KeyRoot.F:
            return KeyRootApi.F;
        case KeyRoot.Fsharp:
        case KeyRoot.Gb:
        case KeyRootApi.FSharpGFlat:
            return KeyRootApi.FSharpGFlat;
        case KeyRoot.G:
            return KeyRootApi.G;
        case KeyRoot.Gsharp:
        case KeyRoot.Ab:
        case KeyRootApi.GSharpAFlat:
            return KeyRootApi.GSharpAFlat;

        default:
            return KeyRootApi.C;
    }
}

export enum KeySign {
    Flat = 'Flat',
    Sharp = 'Sharp',
}

export const getNormalizedRootValue = (
    root?: KeyRootApi | string,
    sign?: KeySign | string,
): KeyRoot => {
    const normalizedRootValue =
        sign === KeySign.Sharp ? toSharpKeyRoot(root) : toFlatKeyRoot(root);

    return normalizedRootValue;
};

export const toUnicodeKeyRoot = (keyRoot: string): string => {
    switch (keyRoot) {
        case KeyRootApi.A:
            return KeyRootApi.A;
        case KeyRootApi.B:
            return KeyRootApi.B;
        case KeyRootApi.C:
            return KeyRootApi.C;
        case KeyRootApi.D:
            return KeyRootApi.D;
        case KeyRootApi.E:
            return KeyRootApi.E;
        case KeyRootApi.F:
            return KeyRootApi.F;
        case KeyRootApi.G:
            return KeyRootApi.G;
        case KeyRootApi.ASharpBFlat:
            return `${KeyRootApi.A}${SharpGlyph} / ${KeyRootApi.B}${FlatGlyph}`;
        case KeyRootApi.CSharpDFlat:
            return `${KeyRootApi.C}${SharpGlyph} / ${KeyRootApi.D}${FlatGlyph}`;
        case KeyRootApi.DSharpEFlat:
            return `${KeyRootApi.D}${SharpGlyph} / ${KeyRootApi.E}${FlatGlyph}`;
        case KeyRootApi.FSharpGFlat:
            return `${KeyRootApi.F}${SharpGlyph} / ${KeyRootApi.G}${FlatGlyph}`;
        case KeyRootApi.GSharpAFlat:
            return `${KeyRootApi.G}${SharpGlyph} / ${KeyRootApi.A}${FlatGlyph}`;

        default:
            return KeyRootApi.C;
    }
};
