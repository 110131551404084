import { gql } from '@apollo/client';

export const GetSeo = gql`
    query GetSeoByPath($pathname: ID) {
        seoByPath(pathname: $pathname) {
            title
            description
            header
            byline
        }
    }
`;
