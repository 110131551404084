// lowercase, so we have case-insensitive search
export enum FiltersParams {
    bpmlowerbound = 'bpmlowerbound',
    bpmupperbound = 'bpmupperbound',
    genres = 'genres',
    instruments = 'instruments',
    keyquality = 'keyquality',
    keyrootnote = 'keyrootnote',
    orderby = 'orderby',
    orderbydirection = 'orderbydirection',
    tags = 'tags',
    textsearch = 'textsearch',
    type = 'type',
    sfxs = 'sfxs',
}

export enum NavigationParams {
    page = 'page',
    tab = 'tab',
}

export enum AudioConfigParams {
    autoPlay = 'autoplay',
}

export const Params = {
    ...NavigationParams,
    ...FiltersParams,
    ...AudioConfigParams,
};
