import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
`;

const ShrinkableWrapper = styled(Wrapper)`
    flex-shrink: 1;
`;

export const NoShrinkWrapper = styled(Wrapper)`
    flex-grow: 1;
    flex-shrink: 0;
`;

const Ellipsis = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    max-width: 100%;
`;

export const Shrinkable: React.FC = ({ children }) => (
    <ShrinkableWrapper>
        <Ellipsis>{children}</Ellipsis>
    </ShrinkableWrapper>
);

export const NoShrink: React.FC = ({ children }) => (
    <NoShrinkWrapper>
        <Ellipsis>{children}</Ellipsis>
    </NoShrinkWrapper>
);
