import React, { useContext, useCallback } from 'react';
import { neverop } from 'utils/noop';
import { FiltersParams } from 'types/params';
import { i18n } from 'utils/setupTranslations';
import useApiFilters, { defaultApiFilters } from './hooks/useApiFilters';
import { ApiFiltersContextType } from './types';

export const DefaultApiFiltersContext = {
    getFilterLabel: neverop('ApiFiltersContext', 'getFilterLabel', ''),
    apiFilters: defaultApiFilters,
};

export const ApiFiltersContext = React.createContext<ApiFiltersContextType>(
    DefaultApiFiltersContext,
);

export const useApiFiltersContext = (): ApiFiltersContextType =>
    useContext(ApiFiltersContext);

export const ApiFiltersProvider = ({
    children,
}: {
    children: React.ReactNode;
}): React.ReactElement => {
    const { apiFilters } = useApiFilters();

    const getFilterLabel = useCallback(
        (filter: string, params: FiltersParams) =>
            (params === 'tags' &&
                apiFilters?.tags?.find((tag) => tag?.code === filter)?.label) ||
            i18n._(filter),
        [apiFilters],
    );

    const context = {
        getFilterLabel,
        apiFilters,
    };

    return (
        <ApiFiltersContext.Provider value={context}>
            {children}
        </ApiFiltersContext.Provider>
    );
};

export default ApiFiltersProvider;
