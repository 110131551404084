import { useApolloClient, ApolloClient } from '@apollo/client';
import { DownloadType } from 'types/enums';
import { DownloadableItem } from 'types';
import { PackIsDownloaded } from 'apollo/pack/pack.gql';
import { RepackIsDownloaded } from 'apollo/repack/repack.gql';
import { SampleIsDownloaded } from 'apollo/sample';
import { evictCacheByKeys } from 'helpers';
import DownloadPageQueryConfig from 'apollo/downloads/downloads';
import { getPurchaseVariables } from './getPurchaseVariables';

const optimisticPackIsDownloaded = (
    apolloClient: ApolloClient<object>,
    item: DownloadableItem,
) => {
    const variables = getPurchaseVariables(item);

    evictCacheByKeys(apolloClient.cache, [
        item.id,
        DownloadPageQueryConfig.packs.pathToItems,
        DownloadPageQueryConfig.samples.pathToItems,
    ]);

    apolloClient.writeQuery({
        query: PackIsDownloaded,
        variables,
        data: {
            pack: {
                __typename: 'Pack',
                guid: item.id,
                isDownloaded: true,
            },
        },
    });
};

const optimisticRePackIsDownloaded = (
    apolloClient: ApolloClient<object>,
    item: DownloadableItem,
) => {
    const variables = getPurchaseVariables(item);

    evictCacheByKeys(apolloClient.cache, [
        item.id,
        DownloadPageQueryConfig.packs.pathToItems,
        DownloadPageQueryConfig.samples.pathToItems,
    ]);
    apolloClient.writeQuery({
        query: RepackIsDownloaded,
        variables,
        data: {
            repack: {
                __typename: 'Repack',
                guid: item.id,
                isDownloaded: true,
            },
        },
    });
};

const optimisticSampleIsDownloaded = (
    apolloClient: ApolloClient<object>,
    sampleGuid: string,
) => {
    evictCacheByKeys(apolloClient.cache, [
        sampleGuid,
        DownloadPageQueryConfig.samples.pathToItems,
    ]);

    apolloClient.writeQuery({
        query: SampleIsDownloaded,
        variables: { guid: sampleGuid },
        data: {
            sample: {
                __typename: 'Sample',
                guid: sampleGuid,
                isDownloaded: true,
            },
        },
    });
};

const optimisticSamplesIsDownloaded = (
    apolloClient: ApolloClient<object>,
    item: DownloadableItem,
) => {
    item?.itemIds.map((sampleGuid) =>
        optimisticSampleIsDownloaded(apolloClient, sampleGuid),
    );
};

export const useUpdateIsDownloaded = (): ((item: DownloadableItem) => void) => {
    const apolloClient = useApolloClient();

    const updateIsDownloaded = (item: DownloadableItem) => {
        switch (item.type) {
            case DownloadType.Pack:
                optimisticPackIsDownloaded(apolloClient, item);
                break;
            case DownloadType.Repack:
                optimisticRePackIsDownloaded(apolloClient, item);
                break;
            default:
                optimisticSamplesIsDownloaded(apolloClient, item);
                break;
        }
    };

    return updateIsDownloaded;
};
