import { useEffect } from 'react';
import { noop } from 'utils/noop';

export const useMountEffect = (
    effect: () => void,
    cleanup: () => void = noop,
): void =>
    useEffect(() => {
        effect();
        return cleanup;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

export default useMountEffect;
