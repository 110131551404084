import { OpenModal } from 'contexts/ModalContext/ModalContext.type';

export const handleNotEnoughCredits = ({
    hasSubscription,
    openModal,
}: {
    hasSubscription: boolean;
    openModal: OpenModal;
}) => {
    if (hasSubscription) {
        openModal.paidUserPaidDownloadUpsell();
    } else {
        openModal.freeUserPaidDownloadUpsell();
    }
};
