import React, { useReducer, useContext } from 'react';
import { noop } from 'utils/noop';
import { useToLocalizedPath } from 'hooks/useToLocalizedPath/useToLocalizedPath';
import {
    WalkThrough,
    WalkThroughs,
    OnboardingContextType,
} from './OnboardingContext.type';
import { getPluginWelcomeTour, getCreator } from './onboardings';
import { onboardingReducer } from './OnboardingContext.reducer';

interface OnboardingProviderProps {
    children: React.ReactNode;
}

const defaultOnboardingState = {
    source: undefined,
    step: undefined,
    walkThrough: [],
    current: 0,
    total: 0,
};

export const OnboardingContext = React.createContext<OnboardingContextType>({
    requestOnboarding: noop,
    WalkThrough: WalkThrough,
    state: defaultOnboardingState,
    next: noop,
    exit: noop,
});

export const useOnboardingContext = (): OnboardingContextType =>
    useContext(OnboardingContext);

export const OnboardingProvider = ({
    children,
}: OnboardingProviderProps): JSX.Element => {
    const [state, dispatch] = useReducer(
        onboardingReducer,
        defaultOnboardingState,
    );

    const toLocalizedPath = useToLocalizedPath();

    const OnboardingWalkThrough: WalkThroughs = {
        [WalkThrough.PluginWelcomeTour]: getPluginWelcomeTour(toLocalizedPath),
        [WalkThrough.Creator]: getCreator(),
    };

    const { walkThrough } = state;

    const exit = (): void => {
        dispatch({
            type: 'exitWalkThrough',
        });
    };

    const next = (nextStep: number, callback: () => void): void => {
        if (walkThrough[nextStep]) {
            dispatch({
                type: 'nextStep',
                payload: { nextStep: nextStep, callback: callback },
            });
        } else {
            exit();
        }
    };

    const requestOnboarding = (walkThroughNames: WalkThrough[]): void => {
        if (state.walkThrough.length === 0) {
            let source = '';

            // For each walk through in the request
            walkThroughNames.forEach((walkThroughName) => {
                const isWalkThroughPerformed = localStorage.getItem(
                    `onboarding-${walkThroughName}`,
                );

                if (!isWalkThroughPerformed) {
                    source += walkThroughName;
                    localStorage.setItem(
                        `onboarding-${walkThroughName}`,
                        'true',
                    );
                    dispatch({
                        type: 'addWalkThrough',
                        payload: {
                            steps: OnboardingWalkThrough[walkThroughName],
                        },
                    });
                }
            });

            // Enter walk through if we got source
            if (source) {
                dispatch({
                    type: 'enterWalkThrough',
                    payload: {
                        source: source,
                    },
                });
            }
        }
    };

    return (
        <OnboardingContext.Provider
            value={{
                requestOnboarding,
                WalkThrough,
                state,
                next,
                exit,
            }}
        >
            {children}
        </OnboardingContext.Provider>
    );
};

export default OnboardingProvider;
