import React, { useContext } from 'react';
import {
    usePutUserMilestones,
    useUserMilestones,
} from 'apollo/milestones/milestones.hooks';
import { neverop } from 'utils/noop';
import { useAuthentication } from 'components/AuthenticationProvider/AuthenticationProvider';
import {
    clearLocalMilestones,
    getLocalMilestones,
    updateSingleLocalMilestone,
} from './helpers/localMilestones';
import { MilestonesControllerType } from './Milestones.types';
import usePerformMilestones from './hooks/usePerformMilestones';
import usePersistedMilestones from './hooks/usePersistedMilestones';
import useSessionMilestones from './hooks/useSessionMilestones';
import useIsReady from './hooks/useIsReady';

export const DefaultMilestonesController: MilestonesControllerType = {
    get: neverop('MilestonesController', 'get', false),
    perform: neverop('MilestonesController', 'performed'),
    isReady: false,
};

export const MilestonesController =
    React.createContext<MilestonesControllerType>(DefaultMilestonesController);

export const useMilestonesController = (): MilestonesControllerType => {
    return useContext(MilestonesController);
};

export const MilestonesProvider: React.FC = ({ children }) => {
    const { isAuthenticated, isGuestUser } = useAuthentication();

    const putUserMilestones = usePutUserMilestones();
    const { milestones: persistedMilestones, loading } = useUserMilestones();

    const {
        getSessionMilestone,
        updateSingleSessionMilestone,
        updateAllSessionMilestones,
    } = useSessionMilestones();

    const { updatePersistedMilestone, updateAllPersistedMilestones } =
        usePersistedMilestones({ putUserMilestones, persistedMilestones });

    const perform = usePerformMilestones({
        isAuthenticated,
        updateSingleSessionMilestone,
        updateAllSessionMilestones,
        persistedMilestones,
        loading,
        updatePersistedMilestone,
        updateAllPersistedMilestones,
        clearLocalMilestones,
        getLocalMilestones,
        updateSingleLocalMilestone,
    });

    const isReady = useIsReady({ isGuestUser, persistedMilestones });

    return (
        <MilestonesController.Provider
            value={{
                get: getSessionMilestone,
                perform,
                isReady,
            }}
        >
            {children}
        </MilestonesController.Provider>
    );
};

export default MilestonesProvider;
