import { DownloadBinResponse } from '@landr/core.models';
import { ProblemDetailsType } from '../enums';
import { APIError } from './APIError';

export class DownloadBinAPIError extends APIError {
    constructor(response: DownloadBinResponse) {
        super();

        this.message = response.errorMessage || '';
        this.title = response.errorMessage || '';
        this.type = ProblemDetailsType.DownloadBinZipFailed;
    }
}
