import { EventIds } from 'constants/eventIds';
import { KeySign } from 'types/enums';
import { log } from 'utils/log';
import { CreatorSessionContextType } from 'contexts/CreatorSessionContext/CreatorSessionContext';
import {
    KeyQuality,
    KeyRootApi,
    PackState,
    SampleState,
} from 'types/generated/graphql';
import {
    CreatorSamplesMapType,
    CreatorSerializableState,
    CreatorContextStateType,
    CreatorSerializableSample,
    CreatorStoredState,
    CreatorBpmMultiplier,
} from '../Creator.types';

export const DefaultCreatorConfig = {
    MAX_TRACKS: 8,
    SESSION_BPM: 120,
    MIN_SESSION_BPM: 40,
    MAX_SESSION_BPM: 240,
    SESSION_KEY_ROOT: KeyRootApi.C,
    SESSION_KEY_QUALITY: KeyQuality.Major,
    SESSION_KEY_SIGN: KeySign.Flat,
    TRACK_GAIN: 7,
    TRACK_PITCH_TRANSPOSE: undefined,
    TRACK_BPM_MULTIPLIER: 'None' as CreatorBpmMultiplier,
    TRACK_MIN_TIME_FACTOR: 0.5,
    TRACK_MAX_TIME_FACTOR: 2,
};

export const getSamplesByStatus = (
    samplesFromState: CreatorSamplesMapType,
    creatorSession: Pick<CreatorSessionContextType, 'hasTrack'>,
): {
    newSamples: CreatorSamplesMapType;
    samplesWithoutStreamUrl: CreatorSamplesMapType;
    samplesWithoutStream: CreatorSamplesMapType;
    samplesReadyToAdd: CreatorSamplesMapType;
    samplesInSession: CreatorSamplesMapType;
    samplesTakenDown: CreatorSamplesMapType;
} => {
    const newSamples = new Map();
    const samplesWithoutStreamUrl = new Map();
    const samplesWithoutStream = new Map();
    const samplesInSession = new Map();
    const samplesReadyToAdd = new Map();
    const samplesTakenDown = new Map();

    samplesFromState.forEach((sample, id): void => {
        if (
            sample.sampleState === SampleState.TakenDown ||
            sample.pack?.packState === PackState.TakenDown
        ) {
            samplesTakenDown.set(id, sample);

            return;
        }

        if (!sample.name || !sample.waveformUrl) {
            newSamples.set(id, sample);

            return;
        }

        if (!sample.streamUrl) {
            samplesWithoutStreamUrl.set(id, sample);

            return;
        }

        if (!sample.stream) {
            samplesWithoutStream.set(id, sample);

            return;
        }

        if (!creatorSession.hasTrack(id)) {
            samplesReadyToAdd.set(id, sample);

            return;
        }

        samplesInSession.set(id, sample);
    });

    return {
        newSamples,
        samplesWithoutStreamUrl,
        samplesWithoutStream,
        samplesReadyToAdd,
        samplesInSession,
        samplesTakenDown,
    };
};

export function prepareStateForStoring(
    state: CreatorContextStateType,
): CreatorStoredState {
    const samplesIterator = state?.samples?.entries();
    const samples = samplesIterator
        ? [...samplesIterator].map(([id, sample]) => {
              return {
                  guid: id,
                  bpmMultiplier: sample.bpmMultiplier,
                  bpm: sample.bpm,
                  gain: sample.gain,
                  pitchTranspose: sample.pitchTranspose,
              };
          })
        : [];

    const key = {
        root: state?.keyRoot,
        quality: state?.keyQuality,
        sign: state?.keySign,
    };

    const sanitizedState: CreatorStoredState = {
        version: 1,
        guid: state?.instrumentationSessionId,
        bpm: state?.bpm,
        key,
        samples,
    };

    return sanitizedState;
}

export function sanitizeParsedSamples(
    unsanitizedSamples: CreatorSerializableSample[],
): CreatorSerializableSample[] {
    if (!unsanitizedSamples || typeof unsanitizedSamples !== 'object') {
        return [];
    }

    const clonedSamples = [...unsanitizedSamples];

    const maxUnsanitizedSamples = clonedSamples.splice(
        0,
        DefaultCreatorConfig.MAX_TRACKS,
    );

    // Warn about too many samples
    if (clonedSamples.length > 0) {
        log.warn(
            `UnsanitizedSamples was containing ${clonedSamples.length} extra sample(s)`,
            EventIds.TooManyUnsanitizedSamples,
        );
    }

    // Make sure we have a guid
    const filteredSamples = maxUnsanitizedSamples.filter((sample) => {
        const { guid } = sample as CreatorSerializableSample;

        if (typeof guid !== 'string' || encodeURIComponent(guid) !== guid) {
            return false;
        }

        return true;
    });

    // Warn about sample without guid
    if (filteredSamples.length !== maxUnsanitizedSamples.length) {
        const diff = maxUnsanitizedSamples.length - filteredSamples.length;

        log.warn(
            `They was ${diff} samples without guid`,
            EventIds.UnsanitizedSamplesWithoutGuid,
        );
    }

    // Sanitized samples, add default value for missing props
    const samples = filteredSamples.map(
        (
            unsanitizedSample: CreatorSerializableSample,
        ): CreatorSerializableSample => {
            const {
                bpmMultiplier: unsanitizedBpmMultiplier,
                guid: unsanitizedGuid,
                bpm: unsanitizedBpm,
                gain: unsanitizedGain,
                pitchTranspose: unsanitizedPitchTranspose,
            } = unsanitizedSample as CreatorSerializableSample;

            const guid = encodeURIComponent(unsanitizedGuid);

            const bpmMultiplier =
                unsanitizedBpmMultiplier === 'None' ||
                unsanitizedBpmMultiplier === 'Half' ||
                unsanitizedBpmMultiplier === 'Double'
                    ? unsanitizedBpmMultiplier
                    : DefaultCreatorConfig.TRACK_BPM_MULTIPLIER;

            // If not a number, define to -1 so we do not include bpm in the return
            const bpm =
                typeof unsanitizedBpm === 'number' ? unsanitizedBpm : -1;

            const gain =
                typeof unsanitizedGain === 'number'
                    ? unsanitizedGain
                    : DefaultCreatorConfig.TRACK_GAIN;

            const pitchTranspose =
                typeof unsanitizedPitchTranspose === 'number'
                    ? unsanitizedPitchTranspose
                    : DefaultCreatorConfig.TRACK_PITCH_TRANSPOSE;

            return {
                guid,
                bpmMultiplier,
                gain,
                pitchTranspose,
                ...(bpm > 0 && {
                    bpm,
                }),
            };
        },
    );

    return samples;
}

// Sanitized state, add default value for missing props
export function sanitizeParsedState(
    unsanitizedParsedState: Partial<CreatorStoredState>,
): CreatorSerializableState {
    const {
        bpm: unsanitizedBpm,
        key: unsanitizedKey,
        samples: unsanitizedSamples,
    } = unsanitizedParsedState as CreatorStoredState;

    const bpm =
        typeof unsanitizedBpm === 'number'
            ? unsanitizedBpm
            : DefaultCreatorConfig.SESSION_BPM;

    const keyQuality =
        unsanitizedKey?.quality === KeyQuality.Minor
            ? KeyQuality.Minor
            : KeyQuality.Major;

    const keySign =
        unsanitizedKey?.sign === KeySign.Sharp ? KeySign.Sharp : KeySign.Flat;

    const keyRoot = Object.values(KeyRootApi).includes(unsanitizedKey?.root)
        ? unsanitizedKey.root
        : DefaultCreatorConfig.SESSION_KEY_ROOT;

    const samples = sanitizeParsedSamples(unsanitizedSamples);

    return {
        bpm,
        keyQuality,
        keySign,
        keyRoot,
        samples,
    };
}
