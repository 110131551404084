const Prefix = 'milestone';

export const getLocalMilestones = () => {
    const localMilestone: string[] = [];

    for (let i = 0; i < localStorage.length; i++) {
        const keyId = localStorage.key(i);

        if (keyId && keyId.includes(Prefix)) {
            localStorage.getItem(keyId);
            const milestone = keyId.replace(`${Prefix}-`, '');

            localMilestone.push(milestone);
        }
    }

    return localMilestone;
};

export const updateSingleLocalMilestone = (milestone: string) => {
    localStorage.setItem(`${Prefix}-${milestone}`, 'true');
};

export const clearLocalMilestones = () => {
    for (let i = 0; i < localStorage.length; i++) {
        const keyId = localStorage.key(i);

        if (keyId && keyId.includes(Prefix)) {
            localStorage.removeItem(keyId);
        }
    }
};
