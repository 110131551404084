import React, { useContext } from 'react';
import { noop } from 'utils/noop';
import { useNotificationsController } from './Notifications.controller';
import Notifications from './components/Notifications';
import { NotificationsContextType } from './types';

export const defaultNotificationsContext = {
    notifications: [],
    removeNotification: noop,
    createNotification: noop,
};

export const NotificationsContext =
    React.createContext<NotificationsContextType>(defaultNotificationsContext);

export const useNotificationsContext = (): NotificationsContextType =>
    useContext(NotificationsContext);

export const NotificationsProvider = ({
    children,
}: {
    children: React.ReactNode;
}): React.ReactElement => {
    const context = useNotificationsController();

    return (
        <NotificationsContext.Provider value={context}>
            <Notifications />
            {children}
        </NotificationsContext.Provider>
    );
};

export default NotificationsProvider;
