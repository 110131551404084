import { FieldMergeFunction } from '@apollo/client';

// A merge function suitable for using with our PaginatedVirtualScroll component
export const virtualListMerge: FieldMergeFunction = (
    existing = [],
    incoming = [],
    context,
) => {
    const merged = [...existing];

    if (context.args) {
        const { offset = 0 } = context.args;

        for (let i = 0; i < incoming.length; ++i) {
            merged[offset + i] = incoming[i];
        }
    }

    return merged;
};

export const mergeOnlyNonNull = (
    existing: any | null = null,
    incoming: any | null,
) => {
    return incoming ?? existing;
};

const labelFieldsMerge = {
    guid: { merge: mergeOnlyNonNull },
    name: { merge: mergeOnlyNonNull },
    description: { merge: mergeOnlyNonNull },
    artworkUrl: { merge: mergeOnlyNonNull },
    coverUrl: { merge: mergeOnlyNonNull },
    isExclusive: { merge: mergeOnlyNonNull },
    slug: { merge: mergeOnlyNonNull },
    seoMetadata: { merge: mergeOnlyNonNull },
    sampleCount: { merge: mergeOnlyNonNull },
    packCount: { merge: mergeOnlyNonNull },
};

const artistFieldsMerge = {
    guid: { merge: mergeOnlyNonNull },
    name: { merge: mergeOnlyNonNull },
    description: { merge: mergeOnlyNonNull },
    artworkUrl: { merge: mergeOnlyNonNull },
    portraitUrl: { merge: mergeOnlyNonNull },
    coverUrl: { merge: mergeOnlyNonNull },
    isExclusive: { merge: mergeOnlyNonNull },
    slug: { merge: mergeOnlyNonNull },
    seoMetadata: { merge: mergeOnlyNonNull },
    sampleCount: { merge: mergeOnlyNonNull },
    packCount: { merge: mergeOnlyNonNull },
};

const packsAndRepacksFieldsMerge = {
    artworkUrl: { merge: mergeOnlyNonNull },
    backgroundColor: { merge: mergeOnlyNonNull },
    creatorSession: { merge: mergeOnlyNonNull },
    description: { merge: mergeOnlyNonNull },
    foregroundColor: { merge: mergeOnlyNonNull },
    guid: { merge: mergeOnlyNonNull },
    isDownloaded: { merge: mergeOnlyNonNull },
    isExclusive: { merge: mergeOnlyNonNull },
    isFavorited: { merge: mergeOnlyNonNull },
    isFree: { merge: mergeOnlyNonNull },
    name: { merge: mergeOnlyNonNull },
    packState: { merge: mergeOnlyNonNull },
    premiumCodes: { merge: mergeOnlyNonNull },
    slug: { merge: mergeOnlyNonNull },
    type: { merge: mergeOnlyNonNull },
    mainGenre: { merge: mergeOnlyNonNull },
    genres: { merge: mergeOnlyNonNull },
    artist: { merge: mergeOnlyNonNull },
    label: { merge: mergeOnlyNonNull },
    seoMetadata: { merge: mergeOnlyNonNull },
};

export const cacheConfig = {
    typePolicies: {
        Artist: { keyFields: ['guid'], fields: { ...artistFieldsMerge } },
        Label: { keyFields: ['guid'], fields: { ...labelFieldsMerge } },
        Pack: {
            keyFields: ['guid'],
            fields: { ...packsAndRepacksFieldsMerge },
        },
        Repack: {
            keyFields: ['guid'],
            fields: {
                ...packsAndRepacksFieldsMerge,
                ...{
                    subType: {
                        merge: mergeOnlyNonNull,
                    },
                },
            },
        },
        Sample: { keyFields: ['guid'] },
        UserCollection: { keyFields: ['guid'] },
        UserCollectionsFeed: {
            fields: {
                feed: {
                    merge: virtualListMerge,
                    keyArgs: ['query'],
                },
            },
        },
        Query: {
            fields: {
                userCollectionsFeed: {
                    keyArgs: ['query'],
                },
            },
        },
        SampleWaveform: { keyFields: ['guid'] },
        SubscriptionsAndAllowedFeatures: {
            // Singleton types that have no identifying field have an empty array for their keyFields.
            keyFields: [],
        },
    },
};
