import { EventIds } from 'constants/eventIds';
import { auth } from '../auth/auth';
import { analytics, log } from '..';

export async function sendAnalyticsIdentify() {
    let analyticsProperties = null;

    const user = await auth.getUser();

    if (user) {
        try {
            analyticsProperties = await auth.getAnalyticsProperties();
        } catch (e) {
            log.error(
                'Error getting analytics properties',
                EventIds.FailedGetAnalyticsPropertiesError,
                e,
            );
        }

        // identify with feature flags
        await analytics.identify(user.userId, analyticsProperties);
    }
}
