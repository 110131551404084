import { gql } from '@apollo/client';

export const SamplesPurchase = gql`
    query SamplesPurchase($guid: [String], $interactionSource: String) {
        samplesPurchase(guid: $guid, interactionSource: $interactionSource) {
            url
        }
    }
`;

export const CreatorSamplesPurchase = gql`
    query CreatorSamplesPurchase(
        $purchasableItem: PurchasableCreatorSession!
        $interactionSource: String
    ) {
        creatorSamplesPurchase(
            purchasableItem: $purchasableItem
            interactionSource: $interactionSource
        ) {
            url
        }
    }
`;

export const CreatorMixdownPurchase = gql`
    query CreatorMixdownPurchase(
        $purchasableItem: PurchasableCreatorSession!
        $interactionSource: String
    ) {
        creatorMixdownPurchase(
            purchasableItem: $purchasableItem
            interactionSource: $interactionSource
        ) {
            url
        }
    }
`;

export const PackPurchase = gql`
    query PackPurchase($guid: String, $interactionSource: String) {
        packPurchase(guid: $guid, interactionSource: $interactionSource) {
            url
        }
    }
`;

export const RepackPurchase = gql`
    query RepackPurchase($guid: String, $interactionSource: String) {
        repackPurchase(guid: $guid, interactionSource: $interactionSource) {
            url
        }
    }
`;

export const CollectionPurchase = gql`
    query CollectionPurchase($guid: String, $interactionSource: String) {
        collectionPurchase(guid: $guid, interactionSource: $interactionSource) {
            url
        }
    }
`;
