import React, {
    useState,
    useCallback,
    useContext,
    useMemo,
    useEffect,
} from 'react';
import { noop } from 'utils/noop';
import xor from 'lodash/xor';
import { useMatch, useLocation } from 'react-router';
import { RoutePaths } from 'types/enums';
import { Sample } from 'types/generated/graphql';
import { SampleState, PackState } from 'types/generated/graphql';
import { useToLocalizedPath } from 'hooks/useToLocalizedPath/useToLocalizedPath';
import { NavigationParams } from 'types/params';

export interface SelectedSamplesContextType {
    samples: Sample[];
    toggleSampleSelection: (selectedSample: Sample) => void;
    toggleAllSamplesSelection: (samples: Sample[]) => void;
    dropSamplesSelection: (samples: Sample[]) => void;
    clearSamplesSelection: () => void;
    updateSamples: (samples: Sample[]) => void;
    isSampleSelected: (sound?: Sample) => boolean;
    areAllSamplesSelected: (samples?: Sample[]) => boolean;
    areSomeSamplesSelected: (samples?: Sample[]) => boolean;
}

const emptyContext = {
    samples: [],
    toggleSampleSelection: noop,
    toggleAllSamplesSelection: noop,
    dropSamplesSelection: noop,
    clearSamplesSelection: noop,
    updateSamples: noop,
    isSampleSelected: () => false,
    areAllSamplesSelected: () => false,
    areSomeSamplesSelected: () => false,
};

export const SelectedSamplesContext =
    React.createContext<SelectedSamplesContextType>(emptyContext);

export const useSelectedSamplesContext = (): SelectedSamplesContextType =>
    useContext(SelectedSamplesContext);

export const SelectedSamplesProvider = ({
    children,
}: {
    children: React.ReactNode;
}): React.ReactElement => {
    const [selectedSamples, setSelectedSamples] = useState<Sample[]>(
        emptyContext.samples,
    );
    const [selectedCollectionSamples, setSelectedCollectionSamples] = useState<
        Sample[]
    >(emptyContext.samples);

    const toLocalizedPath = useToLocalizedPath();

    const isCollectionDetailsPage = useMatch(
        toLocalizedPath(`/${RoutePaths.UserCollections}/:guid`),
    );

    const isDownloadsPage = useMatch(
        toLocalizedPath(`/${RoutePaths.Downloads}`),
    );

    const location = useLocation();
    const { search, pathname } = location;

    const tab = useMemo(() => {
        const query = new URLSearchParams(search);

        return query.get(NavigationParams.tab);
    }, [search]);

    useEffect(() => {
        setSelectedSamples([]);
        setSelectedCollectionSamples([]);
    }, [tab]);

    useEffect(() => {
        setSelectedSamples([]);
        setSelectedCollectionSamples([]);
    }, [pathname]);

    const filterSamplesIfTakenDown = useCallback(
        (samples: Sample[]) => {
            if (isDownloadsPage) {
                return samples.filter(
                    (sample) =>
                        sample.sampleState !== SampleState.TakenDown &&
                        sample.pack?.packState !== PackState.TakenDown,
                );
            }

            return samples;
        },
        [isDownloadsPage],
    );

    const samples = useMemo(() => {
        if (isCollectionDetailsPage) {
            return selectedCollectionSamples;
        }

        return filterSamplesIfTakenDown(selectedSamples);
    }, [
        selectedSamples,
        isCollectionDetailsPage,
        selectedCollectionSamples,
        filterSamplesIfTakenDown,
    ]);

    const setSamples = useCallback(
        (samples: Sample[]) => {
            const filteredSamples = filterSamplesIfTakenDown(samples);

            isCollectionDetailsPage
                ? setSelectedCollectionSamples(samples)
                : setSelectedSamples(filteredSamples);
        },
        [
            isCollectionDetailsPage,
            filterSamplesIfTakenDown,
            setSelectedCollectionSamples,
            setSelectedSamples,
        ],
    );

    const toggleSampleSelection = useCallback(
        (sound: Sample) => {
            const toggledSamples = xor(
                isCollectionDetailsPage
                    ? selectedCollectionSamples
                    : selectedSamples,
                [sound],
            );

            setSamples(toggledSamples);
        },
        [
            isCollectionDetailsPage,
            selectedCollectionSamples,
            selectedSamples,
            setSamples,
        ],
    );

    const toggleAllSamplesSelection = useCallback(
        (samples: Sample[]) => {
            const toggledSamples = xor(
                isCollectionDetailsPage
                    ? selectedCollectionSamples
                    : selectedSamples,
                samples,
            );

            setSamples(toggledSamples);
        },
        [
            isCollectionDetailsPage,
            selectedCollectionSamples,
            selectedSamples,
            setSamples,
        ],
    );

    const clearSamplesSelection = useCallback(() => {
        setSamples([]);
    }, [setSamples]);

    const dropSamplesSelection = useCallback(
        (samples: Sample[]) => {
            const droppedSamples = (
                isCollectionDetailsPage
                    ? selectedCollectionSamples
                    : selectedSamples
            ).filter((i) => !samples.includes(i));

            setSamples(droppedSamples);
        },
        [
            isCollectionDetailsPage,
            selectedCollectionSamples,
            selectedSamples,
            setSamples,
        ],
    );

    const areAllSamplesSelected = useCallback(
        (samples?: Sample[]) => {
            if (
                samples &&
                samples?.length !== 0 &&
                selectedSamples &&
                selectedSamples.length !== 0
            ) {
                if (isCollectionDetailsPage) {
                    return samples.every((sample) =>
                        selectedCollectionSamples.some(
                            (selectedSample) =>
                                selectedSample.guid === sample.guid,
                        ),
                    );
                }

                const filteredSamples = filterSamplesIfTakenDown(samples);

                const allSelected = filteredSamples.every((sample) =>
                    selectedSamples.some(
                        (selectedSample) => selectedSample.guid === sample.guid,
                    ),
                );

                return allSelected;
            }

            return false;
        },

        [
            isCollectionDetailsPage,
            selectedCollectionSamples,
            selectedSamples,
            filterSamplesIfTakenDown,
        ],
    );

    const areSomeSamplesSelected = useCallback(
        (samples?: Sample[]) => {
            if (
                samples &&
                samples?.length !== 0 &&
                selectedSamples &&
                selectedSamples.length !== 0
            ) {
                if (isCollectionDetailsPage) {
                    return samples.some((sample) =>
                        selectedCollectionSamples.some(
                            (selectedSample) =>
                                selectedSample.guid === sample.guid,
                        ),
                    );
                }
                const someSelected = samples.some((sample) =>
                    selectedSamples.some(
                        (selectedSample) => selectedSample.guid === sample.guid,
                    ),
                );

                return someSelected;
            }

            return false;
        },

        [isCollectionDetailsPage, selectedCollectionSamples, selectedSamples],
    );

    const isSampleSelected = useCallback(
        (sound?: Sample) => {
            if (sound) {
                if (isCollectionDetailsPage) {
                    return selectedCollectionSamples.some(
                        (selectedSample) => selectedSample.guid === sound.guid,
                    );
                }

                return selectedSamples.some(
                    (selectedSample) => selectedSample.guid === sound.guid,
                );
            }

            return false;
        },

        [isCollectionDetailsPage, selectedCollectionSamples, selectedSamples],
    );

    const updateSamples = useCallback(
        (samples: Sample[]) => {
            const updatedSamplesById = samples.reduce((samplesById, sample) => {
                samplesById.set(sample.guid, sample);
                return samplesById;
            }, new Map<string, Sample>());

            const currentSelectedSamples = isCollectionDetailsPage
                ? selectedCollectionSamples
                : selectedSamples;

            const updatedSamples = currentSelectedSamples.reduce(
                (updatedSamples, sample) => {
                    if (updatedSamplesById.has(sample.guid)) {
                        updatedSamples.push(
                            updatedSamplesById.get(sample.guid)!,
                        );
                    } else {
                        updatedSamples.push(sample);
                    }

                    return updatedSamples;
                },
                [] as Sample[],
            );

            setSamples(updatedSamples);
        },
        [
            selectedCollectionSamples,
            selectedSamples,
            isSampleSelected,
            setSamples,
        ],
    );

    const context = {
        samples,
        toggleSampleSelection,
        toggleAllSamplesSelection,
        dropSamplesSelection,
        clearSamplesSelection,
        isSampleSelected,
        areAllSamplesSelected,
        areSomeSamplesSelected,
        updateSamples,
    };

    return (
        <SelectedSamplesContext.Provider value={context}>
            {children}
        </SelectedSamplesContext.Provider>
    );
};

export default SelectedSamplesProvider;
