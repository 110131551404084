import React from 'react';
import { Container, Group } from '@landr/maestro';
import { useNotificationsContext } from 'contexts/NotificationsContext/NotificationsContext';
import { NotificationBase } from '../types';
import * as Styled from './styled';
import { useNotification } from './useNotification';

export const Notification = ({
    notification,
}: {
    notification: NotificationBase;
}) => {
    const { variant, message, timeout, handleRemove } =
        useNotification(notification);

    if (!message) {
        return null;
    }

    return (
        <Styled.Notification
            variant={variant}
            kind="temporary"
            onClick={handleRemove}
            onClose={handleRemove}
            timeout={timeout ?? undefined}
        >
            <Styled.MessageWrapper>{message}</Styled.MessageWrapper>
        </Styled.Notification>
    );
};

const Notifications: React.FC = () => {
    const { notifications } = useNotificationsContext();

    return (
        <Styled.Portal position="fixed" zIndex="alert">
            <Container size="ms">
                <Group direction="vertical">
                    {notifications.map((notification) => (
                        <Group.Item key={notification.id}>
                            <Notification notification={notification} />
                        </Group.Item>
                    ))}
                </Group>
            </Container>
        </Styled.Portal>
    );
};

export default Notifications;
