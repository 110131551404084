import { EventIds } from 'constants/eventIds';
import { OperationDefinitionNode } from 'graphql';
import { ApolloLink } from '@apollo/client';
import { log } from 'utils/log';

const formatMessage = (
    operationType: string,
    operationName: string,
    elapsed: number,
): string => {
    const parts = [`${operationType}`, '>', `${operationName}`];

    if (operationType !== 'subscription') {
        parts.push(`(completed in ${elapsed} ms)`);
    }

    return parts.join(' ');
};

export const loggerLink = new ApolloLink((operation, forward) => {
    const startTime = new Date().getTime();

    return forward(operation).map((result) => {
        const { operationName } = operation;
        const operationType = (
            operation.query.definitions[0] as OperationDefinitionNode
        )?.operation;

        const operationHeaders = operation.getContext().headers;
        const timeElapsed = new Date().getTime() - startTime;

        const message = formatMessage(
            operationType,
            operationName,
            timeElapsed,
        );

        log.info(message, EventIds.ApolloTraceLogs, {
            operationType,
            operationName,
            timeElapsed,
            correlationId:
                operationHeaders && operationHeaders['X-CorrelationId'],
        });

        return result;
    });
});
