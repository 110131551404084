import { useCallback } from 'react';
import debounce from 'lodash/debounce';
import { useCreatorSession } from 'contexts/CreatorSessionContext/CreatorSessionContext';
import { useNotificationsContext } from 'contexts/NotificationsContext/NotificationsContext';
import { getGainFromKnobIndex } from 'libs/CreatorSession/CreatorSession.utils';
import { RemovedFromCreatorSuccess } from 'contexts/NotificationsContext/notifications';
import {
    CreatorSampleMetaType,
    CreatorContextStateType,
    CreatorAction,
    CreatorSamplesMapType,
    CreatorBpmMultiplier,
} from '../Creator.types';

type UseTrackHandlersType = {
    updateTrackGain: (guid: string, knobIndex: number) => void;
    removeTrack: (guid: string, sampleName?: string) => void;
    addTrack: (guid: string, onAdded?: () => void, bpm?: number) => void;
    updateTrackBpm: (
        id: string,
        newBpm: number,
        interactionSource: string,
    ) => void;
    updateTrackBpmMultiplier: (
        id: string,
        newBpmMultiplier: CreatorBpmMultiplier,
        interactionSource: string,
    ) => void;
    updateTrackTranspose: (
        id: string,
        newTranspose: number,
        interactionSource: string,
    ) => void;
};

export const useTrackHandlers = (
    state: CreatorContextStateType,
    dispatch: React.Dispatch<CreatorAction>,
    samplesInSession: CreatorSamplesMapType,
    performMilestone: (
        milestone: string,
        shouldUpdateSession?: boolean | undefined,
    ) => void,
): UseTrackHandlersType => {
    const creatorSession = useCreatorSession();

    const { createNotification } = useNotificationsContext();

    const addTrack = (
        guid: string,
        onAdded?: () => void,
        bpm?: number,
    ): void => {
        performMilestone('SampleAddedToCreator', true);

        dispatch({
            type: 'addSamples',
            payload: {
                guids: [guid],
                options: {
                    onAdded,
                },
                sample: {
                    bpm,
                },
            },
        });
    };

    const removeTrack = useCallback(
        (guid: string, itemName?: string) => {
            creatorSession.removeTrack(guid);

            dispatch({ type: 'removeSamples', payload: [guid] });

            createNotification(new RemovedFromCreatorSuccess(itemName));
        },
        [
            state.samples,
            state.bpm,
            state.keyRoot,
            state.keyQuality,
            state.instrumentationSessionId,
            creatorSession,
            dispatch,
            samplesInSession.size,
            createNotification,
        ],
    );

    const updateTrackBpm = useCallback(
        (id: string, newBpm: number) => {
            creatorSession.updateTrackBpm(id, newBpm);
        },
        [
            creatorSession,
            state.bpm,
            state.instrumentationSessionId,
            samplesInSession.size,
            state.samples,
            state.keyRoot,
            state.keyQuality,
        ],
    );

    const updateTrackBpmMultiplier = useCallback(
        (id: string, newBpmMultiplier: CreatorBpmMultiplier) => {
            creatorSession.updateTrackBpmMultiplier(id, newBpmMultiplier);
        },
        [
            creatorSession,
            state.bpm,
            state.instrumentationSessionId,
            state.samples,
            state.keyRoot,
            state.keyQuality,
        ],
    );

    const updateTrackTranspose = useCallback(
        (id: string, newTranspose: number) => {
            creatorSession.updateTrackTranspose(id, newTranspose);
        },
        [
            creatorSession,
            state.bpm,
            state.instrumentationSessionId,
            state.samples,
            state.keyRoot,
            state.keyQuality,
        ],
    );

    const _dispatchGainKnobUpdate = debounce(
        (guid: string, knobIndex: number): void => {
            const map = new Map<string, Partial<CreatorSampleMetaType>>();

            map.set(guid, {
                gain: knobIndex,
            });

            dispatch({
                type: 'updateSamples',
                payload: {
                    samples: map,
                },
            });
        },
        500,
    );

    const updateTrackGain = useCallback(
        (guid: string, knobIndex: number) => {
            const gain = getGainFromKnobIndex(knobIndex);

            _dispatchGainKnobUpdate(guid, knobIndex);
            creatorSession.updateTrackGain(guid, gain);
        },
        [creatorSession, _dispatchGainKnobUpdate],
    );

    return {
        updateTrackGain,
        removeTrack,
        addTrack,
        updateTrackBpm,
        updateTrackBpmMultiplier,
        updateTrackTranspose,
    };
};
