import { useEffect, useCallback } from 'react';
import useMountEffect from 'hooks/useMountEffect';

const usePerformMilestones = ({
    isAuthenticated,
    updateSingleSessionMilestone,
    updateAllSessionMilestones,
    persistedMilestones = [],
    loading,
    updatePersistedMilestone,
    updateAllPersistedMilestones,
    clearLocalMilestones,
    getLocalMilestones,
    updateSingleLocalMilestone,
}: {
    isAuthenticated: boolean;
    updateSingleSessionMilestone: (milestone: string) => void;
    updateAllSessionMilestones: (milestones: string[]) => void;
    persistedMilestones?: string[];
    loading: boolean;
    updatePersistedMilestone: (milestone: string) => void;
    updateAllPersistedMilestones: (milestones: string[]) => void;
    clearLocalMilestones: () => void;
    getLocalMilestones: () => string[];
    updateSingleLocalMilestone: (milestone: string) => void;
}) => {
    /**
     * Updating the session flag immediately,
     * even if we might re-updating them once the authentication resolves.
     * This means, if your milestone impact something visible in the UI,
     * you might want to wait for `isReady=true`
     */
    useMountEffect(() => {
        const localMilestones = getLocalMilestones();

        // Cast to Set to remove duplicates
        const milestones = [
            ...new Set([...localMilestones, ...persistedMilestones]),
        ];

        updateAllSessionMilestones(milestones);
    });

    useEffect(() => {
        const localMilestones = getLocalMilestones();

        // Cast to Set to remove duplicates
        const milestones = [
            ...new Set([...localMilestones, ...persistedMilestones]),
        ];

        if (isAuthenticated && !loading && milestones.length) {
            updateAllSessionMilestones(milestones);
            updateAllPersistedMilestones(milestones);
            // Once the milestones are persisted that can be removed from localStorage
            clearLocalMilestones();
        }
        // Only needs to be run after authentication has completed and the
        // session query has loaded
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, loading]);

    const perform = useCallback(
        (milestone: string, shouldUpdateSession = true) => {
            if (shouldUpdateSession) {
                updateSingleSessionMilestone(milestone);
            }

            if (isAuthenticated) {
                updatePersistedMilestone(milestone);
            }

            updateSingleLocalMilestone(milestone);
        },
        [
            isAuthenticated,
            updateSingleSessionMilestone,
            updatePersistedMilestone,
            updateSingleLocalMilestone,
        ],
    );

    return perform;
};

export default usePerformMilestones;
