import { useEffect, useCallback } from 'react';
import { storeSessionLocally } from '../helpers/stateHash.helpers';
import { CreatorContextStateType } from '../Creator.types';

export function useAutosave(state: CreatorContextStateType): void {
    const autoSaveSession = useCallback((): void => {
        // Store only if we have samples
        const hasSample = state.samples.size > 0;

        if (hasSample) {
            storeSessionLocally(state);
        }
    }, [state]);

    // Store session on page unload
    useEffect(() => {
        window.addEventListener('pagehide', autoSaveSession);

        return (): void => {
            window.removeEventListener('pagehide', autoSaveSession);
        };
    }, [autoSaveSession]);
}
