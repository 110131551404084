import {
    UnbHeightPx,
    NavHeightPxDesktop,
    NavHeightPxMobile,
} from 'constants/navBar';
import React from 'react';
import styled from 'styled-components';
import {
    Group,
    Box,
    Heading,
    Paragraph,
    useBreakpoint,
    Button,
    Logo,
    Container,
    ContainerProps,
} from '@landr/maestro';
import { spacing } from '@landr/maestro-legacy';
import { I18nProvider } from '@lingui/react';
import { i18n } from 'utils/setupTranslations';
import AppStyleProvider from 'AppGlobalStyle';
import { Trans } from '@lingui/macro';
import { Helmet } from 'react-helmet';

// PageBody do not take 100% of the viewport
const ErrorPageBody = styled(Box)`
    position: relative;
    margin-left: ${spacing('xl')};
    margin-right: ${spacing('xl')};
    height: calc(100vh - (${UnbHeightPx}px + ${NavHeightPxDesktop}px));

    ${({ theme }): string => theme.breakpoint('md')`
        height: calc(100vh - (${UnbHeightPx}px + ${NavHeightPxMobile}px));
    `}
`;

const StyledLink = styled.a`
    text-decoration: underline;
`;

const refreshPage = () => {
    window?.location?.reload();
};

interface PageUnknownErrorProps {
    isGlobal?: boolean;
    status?: number;
}

// `PageUnknownError` wrap VerboseError component in a page layout.
// This is what is returned by `withErrorBoundary(Component, 'page');`
// It should only be use for pages or components that include page layout structure.
// If your component do not have page layout structure, use `<VerboseError />` directly
// For more info consult error/README.md
const PageUnknownError: React.FC<PageUnknownErrorProps> = ({
    isGlobal = false,
    status,
}) => {
    const isMd = useBreakpoint('md');

    let size: ContainerProps['size'];
    let title: JSX.Element;
    let description: JSX.Element;

    switch (status) {
        case 423:
        case 503:
            size = 'md';
            title = <Trans>Content temporary unavailable</Trans>;
            description = (
                <Trans>
                    Refresh the page to try again. If the problem persists{' '}
                    <StyledLink
                        href="https://support.landr.com/hc/en-us/requests/new"
                        target="_blank"
                    >
                        contact support
                    </StyledLink>{' '}
                    for more help.
                </Trans>
            );
            break;

        default:
            size = 'ms';
            title = <Trans>Something wasn't plugged right.</Trans>;
            description = (
                <Trans>
                    We couldn't find what you were looking for. If the problem
                    persists, check that your browser is up-to-date or{' '}
                    <StyledLink
                        href="https://support.landr.com/hc/en-us/requests/new"
                        target="_blank"
                    >
                        contact support
                    </StyledLink>{' '}
                    for more help.
                </Trans>
            );
    }

    return (
        <>
            {isGlobal && (
                <Helmet>
                    <meta name="prerender-status-code" content="500" />
                </Helmet>
            )}
            <I18nProvider i18n={i18n}>
                <AppStyleProvider>
                    <ErrorPageBody>
                        <Group
                            justifyContent="center"
                            alignItems="center"
                            height="100%"
                        >
                            <Container size={size}>
                                <Group
                                    direction="vertical"
                                    alignItems="center"
                                    textAlign="center"
                                    py="jb"
                                >
                                    {isGlobal && (
                                        <Box mb="xl">
                                            <Logo variant="brand" />
                                        </Box>
                                    )}
                                    <Heading
                                        size={isMd ? 'xxxl' : 'mg'}
                                        data-testid="big-title"
                                        mb="md"
                                        as="h1"
                                    >
                                        {title}
                                    </Heading>
                                    <Paragraph
                                        contrast="low"
                                        size="md"
                                        mb="xxl"
                                    >
                                        {description}
                                    </Paragraph>
                                    {!status ? (
                                        <Button
                                            variant="secondary"
                                            isRounded
                                            as="a"
                                            href="/"
                                        >
                                            <Trans>Back to Samples</Trans>
                                        </Button>
                                    ) : (
                                        <Button
                                            variant="secondary"
                                            isRounded
                                            onClick={refreshPage}
                                        >
                                            <Trans>Refresh</Trans>
                                        </Button>
                                    )}
                                </Group>
                            </Container>
                        </Group>
                    </ErrorPageBody>
                </AppStyleProvider>
            </I18nProvider>
        </>
    );
};

export default PageUnknownError;
