import React, { useContext } from 'react';
import { neverop } from 'utils/noop';
import { usePurchaseController } from 'controllers/business/Purchase/Purchase.controller';
import { IPurchaseContext as IPurchaseControllerContext } from 'controllers/business/Purchase/Purchase.types';

export const defaultPurchaseControllerContext = {
    purchaseCostInCredits: 0,
    item: undefined,
    isLoading: false,
    queuedItemIDs: [],
    approveCreditUse: neverop('PurchaseController', 'approveCreditUse'),
    cancelPurchase: neverop('PurchaseController', 'cancelPurchase'),
    startPurchase: neverop('PurchaseController', 'startPurchase'),
    showNotEnoughCreditsWarning: neverop(
        'PurchaseController',
        'showNotEnoughCreditsWarning',
    ),
    showPoorStandingOrSuspended: neverop(
        'PurchaseController',
        'showPoorStandingOrSuspended',
    ),
};

export const PurchaseControllerContext =
    React.createContext<IPurchaseControllerContext>(
        defaultPurchaseControllerContext,
    );

export const usePurchaseControllerContext = (): IPurchaseControllerContext =>
    useContext(PurchaseControllerContext);

export const PurchaseControllerProvider = ({
    children,
}: {
    children: React.ReactNode;
}): JSX.Element => {
    const context = usePurchaseController();

    return (
        <PurchaseControllerContext.Provider value={context}>
            {children}
        </PurchaseControllerContext.Provider>
    );
};

export default PurchaseControllerProvider;
