import { LandrSamplesBrand } from 'constants/brands';
import {
    PlayFilled,
    ReplaceOutlined,
    DragOutlined,
    CogOutlined,
} from '@landr/maestro';
import { t } from '@lingui/macro';
import { RoutePaths } from 'types/enums';
import { Step } from '../OnboardingContext.type';

const PluginSyncPos = { top: 0, left: 94 };
const PluginDragPos = { bottom: 0, right: 28 };
const PluginGeneralSettinggPos = { top: 0, right: 28 };

export const getPluginWelcomeTour = (
    toLocalizedPath: (path: string) => string,
): Step[] => [
    {
        pathname: toLocalizedPath(
            `/${RoutePaths.Collections}/free-starter-collection`,
        ),
        target: { default: PluginSyncPos },
        placement: 'bottom',
        icon: ReplaceOutlined,
        title: t`Sync with your DAW`,
        content: t`Set your key and BPM then sync ${LandrSamplesBrand} to your DAW. Sync can be turned off any time.`,
    },
    {
        pathname: toLocalizedPath(
            `/${RoutePaths.Collections}/free-starter-collection`,
        ),
        target: {
            default: `[data-testId="samples-table-body"] button[aria-label="${t`Play`}"]`,
        },
        placement: 'auto',
        icon: PlayFilled,
        title: t`Play a sample`,
        content: t`Click play ([icon:PlayFilled]) to hear your first sample. Use the up [icon: CaretUpOutlined] and down [icon: CaretDownOutlined] arrow keys to switch between samples.`,
        listenToTarget: true,
    },
    {
        pathname: toLocalizedPath(
            `/${RoutePaths.Collections}/free-starter-collection`,
        ),
        target: { default: PluginDragPos },
        placement: 'top',
        icon: DragOutlined,
        title: t`Drag, drop & you’re done`,
        content: t`Grab the waveform or the arrow icon to drag and drop your sample into your DAW.`,
    },
    {
        pathname: toLocalizedPath(
            `/${RoutePaths.Collections}/free-starter-collection`,
        ),
        target: { default: PluginGeneralSettinggPos },
        placement: 'bottom',
        icon: CogOutlined,
        title: t`Adjust your settings`,
        content: t`Set your download location or get the latest update.`,
    },
];
