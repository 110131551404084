// IMPORTANT: Updating imports here should be added to the `manualChunk.initialTheme`
// entry in `vite.config.ts`
import React from 'react';
import { ResponsiveProvider } from '@landr/maestro';
import {
    color,
    defaultTheme,
    MaestroThemeProvider,
    GlobalStyle,
} from '@landr/maestro-legacy';
import { createGlobalStyle } from 'styled-components';

const AppGlobalStyle = createGlobalStyle`
    @-webkit-keyframes fadeIn {
        0% {
            opacity: 0;
        }
        80% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    body {
        color: ${color('text.base')};
        background: ${color('background.base')};
        margin: 0;
        width: 100%; 
        height: 100%;
        overflow-y: scroll; /* prevents shifting background due to scrollbar disappearing when opening a modal */
        overflow-x: hidden;
        overscroll-behavior: none;
    }

    a {
        text-decoration: none;
        color: inherit;
    }

    #root {
        min-height: 100%;
        display: flex;
        flex-direction: column;
    }
`;

export const AppStyleProvider = ({
    children,
}: {
    children: React.ReactNode;
}): React.ReactElement => {
    return (
        <ResponsiveProvider theme={defaultTheme}>
            <MaestroThemeProvider mode="dark" theme={defaultTheme}>
                <GlobalStyle />
                <AppGlobalStyle />
                {children}
            </MaestroThemeProvider>
        </ResponsiveProvider>
    );
};

export default AppStyleProvider;
