import { gql } from '@apollo/client';

export const BRANDTYPE_EXCERPT_FRAGMENT = gql`
    fragment BrandtypeExcerptFragment on Brandtype {
        guid
        name
        slug
    }
`;

export const BRANDTYPE_DETAIL_FRAGMENT = gql`
    fragment BrandtypeDetailFragment on Brandtype {
        ...BrandtypeExcerptFragment
        description
        sampleCount
        packCount
    }
    ${BRANDTYPE_EXCERPT_FRAGMENT}
`;
