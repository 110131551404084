import { useMemo } from 'react';
import { Languages } from 'types/enums';

export const useLocalePrefixes = () => {
    const prefixes = useMemo(
        () => [
            ...Object.values(Languages).map((locale) =>
                locale === Languages.EN ? '/' : `/${locale}/`,
            ),
        ],
        [],
    );

    return prefixes;
};
