export enum Languages {
    EN = 'en',
    FR = 'fr',
    ES = 'es',
}

export enum LanguageToLocale {
    'en' = 'en_US',
    'fr' = 'fr_FR',
    'es' = 'es_ES',
}

export enum UnsupportedLanguages {
    DE = 'de',
    IT = 'it',
    JA = 'ja',
    PT = 'pt',
}
