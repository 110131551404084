import { VersionChange } from 'types/enums';
import { CustomEvents } from 'types/enums/customEvents';

export const dispatchUpdateRequiredEvent = (
    versionChange: VersionChange,
): void => {
    const event = new CustomEvent(CustomEvents.CLIENT_UPDATE_REQUIRED, {
        detail: { versionChange },
    });

    window.dispatchEvent(event);
};
