import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { apolloClient } from 'utils/apollo/apolloClient';

export const SamplesApolloProvider = ({
    children,
}: {
    children: React.ReactNode;
}): React.ReactElement => {
    return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default SamplesApolloProvider;
