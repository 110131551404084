import { defaultLocale } from 'constants/locale';
import { EventIds } from 'constants/eventIds';
import { Languages } from 'types/enums';
import { i18n } from 'utils/setupTranslations';
import { log } from 'utils';
import { getIsSupportedLocale } from './getIsSupportedLocale';

export const activateLanguage = (locale: Languages | undefined) => {
    const isLocaleSupported = getIsSupportedLocale(locale);

    if (isLocaleSupported) {
        i18n.activate(locale);

        const htmlTag = document.getElementsByTagName('html')[0];

        htmlTag && htmlTag.setAttribute('lang', locale);
    } else {
        log.warn(
            'Activate language with unsupported locale',
            EventIds.UnsupportedLocale,
            undefined,
            { locale },
        );
        i18n.activate(defaultLocale);
    }
};
