import { gql } from '@apollo/client';

export const Milestones = gql`
    query Milestones {
        milestones
    }
`;

export const PutMilestones = gql`
    mutation PutMilestones($milestones: [String!]!) {
        putMilestones(milestones: $milestones)
    }
`;
