export function isInRange(
    number: number | undefined,
    range: { min: number; max: number },
): boolean {
    if (number === undefined) {
        return false;
    }

    const isNumberInRange = number >= range.min && number <= range.max;

    return isNumberInRange;
}
