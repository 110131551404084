import React from 'react';
import styled from 'styled-components';
import { NotificationBar, NotificationBarProps, Box } from '@landr/maestro';
import { zIndex } from '@landr/maestro-legacy';
import { createPortal } from 'react-dom';
import { createContainer } from 'utils/createContainer';

export const containerId = 'app-notification-bar';

const NotificationBarWrapper = styled(Box)`
    position: fixed;
    width: 100%;
    z-index: ${zIndex('overlay')};
`;

// The hidden notification bar ensures that spacing at the top of the page remains consistent.
const HiddenNotificationBarWrapper = styled(Box)`
    visibility: hidden;
`;

const AppNotificationBar: React.FC<NotificationBarProps> = (props) => {
    return (
        <>
            {createPortal(
                <>
                    <NotificationBarWrapper>
                        <NotificationBar {...props} />
                    </NotificationBarWrapper>
                    <HiddenNotificationBarWrapper>
                        <NotificationBar {...props} />
                    </HiddenNotificationBarWrapper>
                </>,
                document.getElementById(containerId) ||
                    createContainer(containerId),
            )}
        </>
    );
};

export default AppNotificationBar;
