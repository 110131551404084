import { gql } from '@apollo/client';
import {
    META_PAGE_FILTER_FRAGMENT,
    META_PAGE_TOTAL_FRAGMENT,
} from 'apollo/metaPage/metaPage.fragment';
import { SAMPLE_FRAGMENT } from 'apollo/sample/sample.fragment';
import {
    REPACK_DETAIL_FRAGMENT,
    REPACK_EXCERPT_FRAGMENT,
} from './repack.fragment';

export const PaginatedRepacks = gql`
    query PaginatedRepacks(
        $take: Int = 1
        $subType: RepackSubType
        $page: Int
        $query: String
    ) {
        paginatedRepacks(
            take: $take
            subType: $subType
            page: $page
            query: $query
        ) {
            data {
                ...RepackExcerptFragment
            }
            meta {
                ...MetaPageTotalFragment
                ...MetaPageFilterFragment
            }
        }
    }
    ${REPACK_EXCERPT_FRAGMENT}
    ${META_PAGE_FILTER_FRAGMENT}
    ${META_PAGE_TOTAL_FRAGMENT}
`;

export const RepackBySlugOrGuid = gql`
    query RepackBySlugOrGuid($repackSlugOrGuid: ID!) {
        repack(repackSlugOrGuid: $repackSlugOrGuid) {
            ...RepackDetailFragment
        }
    }
    ${REPACK_DETAIL_FRAGMENT}
`;

export const RepackIsDownloaded = gql`
    query RepackIsDownloaded($guid: ID!) {
        repack(repackSlugOrGuid: $guid) {
            guid
            isDownloaded
        }
    }
`;

export const PaginatedRepackSamples = gql`
    query PaginatedRepackSamples(
        $repackGuid: ID
        $take: Int = 1
        $page: Int = 0
        $query: String
        $offset: Int
    ) {
        paginatedRepackSamples(
            repackGuid: $repackGuid
            take: $take
            page: $page
            query: $query
            offset: $offset
        ) {
            data {
                ...SampleFragment
            }
            meta {
                ...MetaPageTotalFragment
                ...MetaPageFilterFragment
            }
            next {
                skip
            }
        }
    }
    ${SAMPLE_FRAGMENT}
    ${META_PAGE_FILTER_FRAGMENT}
    ${META_PAGE_TOTAL_FRAGMENT}
`;

export const UpdateRepackFavoritedState = gql`
    mutation UpdateRepackFavoritedState($repack: RepackFavoritedInput!) {
        updateRepackFavoritedState(repack: $repack) {
            guid
            isFavorited
        }
    }
`;

export const RepacksStream = gql`
    query RepacksStream($repackGuid: ID!) {
        repackStream(repackGuid: $repackGuid) {
            url
        }
    }
`;
