import { DocumentNode } from '@apollo/client';
import { DownloadType } from 'types/enums';
import {
    CreatorSamplesPurchase,
    CreatorMixdownPurchase,
    SamplesPurchase,
    PackPurchase,
    RepackPurchase,
    CollectionPurchase,
} from '../purchase';

export const RequestPurchaseQueries: {
    [key in DownloadType]: DocumentNode;
} = {
    [DownloadType.Creator]: CreatorSamplesPurchase,
    [DownloadType.CreatorMulti]: CreatorSamplesPurchase,
    [DownloadType.CreatorMixdown]: CreatorMixdownPurchase,
    [DownloadType.Pack]: PackPurchase,
    [DownloadType.Repack]: RepackPurchase,
    [DownloadType.Collection]: CollectionPurchase,
    [DownloadType.CollectionMulti]: CollectionPurchase,
    [DownloadType.Sample]: SamplesPurchase,
    [DownloadType.Multi]: SamplesPurchase,
    [DownloadType.Unknown]: SamplesPurchase,
};

export const RequestPurchaseQueryItemName: {
    [key in DownloadType]: string;
} = {
    [DownloadType.Creator]: 'creatorSamplesPurchase',
    [DownloadType.CreatorMulti]: 'creatorSamplesPurchase',
    [DownloadType.CreatorMixdown]: 'creatorMixdownPurchase',
    [DownloadType.Pack]: 'packPurchase',
    [DownloadType.Repack]: 'repackPurchase',
    [DownloadType.Collection]: 'collectionPurchase',
    [DownloadType.CollectionMulti]: 'collectionPurchase',
    [DownloadType.Sample]: 'samplesPurchase',
    [DownloadType.Multi]: 'samplesPurchase',
    [DownloadType.Unknown]: 'samplesPurchase',
};
