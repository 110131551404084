import React from 'react';
import styled from 'styled-components';
import { Box, InfoOutlined, Tooltip } from '@landr/maestro';
import { palette, radius } from '@landr/maestro-legacy';
import { t } from '@lingui/macro';

const ErrorWrapper = styled(Box)`
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${radius('base')};
    border: 1px solid ${palette('neutral.800')};
`;

const Icon = styled(InfoOutlined)`
    color: ${palette('neutral.800')};
`;

const IconWrapper = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

// Use `withErrorBoundary` with the variant `minimal` or use it directly in your component
// when you are handling an error and wish to render only a minimal ui.
// For more info consult error/README.md
const MinimalError = () => {
    return (
        <ErrorWrapper>
            <Tooltip content={t`Failed to load`} position="top" hasArrow>
                <IconWrapper>
                    <Icon size="md" />
                </IconWrapper>
            </Tooltip>
        </ErrorWrapper>
    );
};

export default MinimalError;
