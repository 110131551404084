export enum DownloadType {
    Creator = 'Creator',
    CreatorMulti = 'CreatorMulti',
    CreatorMixdown = 'CreatorMixdown',
    Pack = 'Pack',
    Repack = 'Repack',
    Sample = 'Sample',
    Collection = 'Collection',
    CollectionMulti = 'CollectionMulti',
    Multi = 'Multi',
    Unknown = 'Unknown',
}
