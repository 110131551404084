import React from 'react';
import { I18nProvider as LinguiI18nProvider } from '@lingui/react';
import { i18n } from 'utils/setupTranslations';
import { useI18nLocaleDetection } from 'hooks/useI18nLocaleDetection/useI18nLocaleDetection';

export const I18nProvider: React.FC = ({ children }) => {
    useI18nLocaleDetection();

    return <LinguiI18nProvider i18n={i18n}>{children}</LinguiI18nProvider>;
};
