import { gql } from '@apollo/client';

export const META_PAGE_FILTER_FRAGMENT = gql`
    fragment MetaPageFilterFragment on MetaPage {
        filters {
            sampleTypeFilters {
                name
                value
            }
            instrumentFilters {
                name
                value
            }
            genreFilters {
                name
                value
            }
            sfxFilters {
                name
                value
            }
            tagFilters {
                name
                value
            }
        }
    }
`;

export const META_PAGE_TOTAL_FRAGMENT = gql`
    fragment MetaPageTotalFragment on MetaPage {
        totalRecords
        totalSampleRecords
        totalPackRecords
    }
`;
