import { useEffect } from 'react';
import { EventObserver, Observer } from '../utils/eventObserver';

export function useObserver<E, T>(
    event: E,
    callback: (payload: T) => void,
    subscribe: (_: E, __: Observer<T>) => void,
    unsubscribe: (_: E, __: Observer<T>) => void,
    deferInitialization?: boolean,
): void {
    useEffect(() => {
        if (deferInitialization) {
            return;
        }

        const observer = new EventObserver<T>(callback);

        subscribe(event, observer);

        return (): void => {
            unsubscribe(event, observer);
        };
    }, [callback, event, subscribe, unsubscribe, deferInitialization]);
}
