import styled from 'styled-components';
import {
    Notification as MaestroNotification,
    PortalProps,
    Portal as MaestroPortal,
    Box,
} from '@landr/maestro';
import { spacing } from '@landr/maestro-legacy';

export const Portal = styled(MaestroPortal)<PortalProps>`
    width: 100%;
    padding: ${spacing('md')};
    pointer-events: none;
`;

export const Notification = styled(MaestroNotification)`
    cursor: pointer;
    pointer-events: all;

    span {
        display: block;
        max-width: calc(100% - 84px);
        overflow: hidden;
    }
`;

export const MessageWrapper = styled(Box)`
    display: flex;
    flex-wrap: nowrap;
    max-width: 100%;
    gap: 0.25em;
`;
