import { gql } from '@apollo/client';
import { SAMPLE_FRAGMENT } from 'apollo/sample/sample.fragment';
import {
    META_PAGE_FILTER_FRAGMENT,
    META_PAGE_TOTAL_FRAGMENT,
} from 'apollo/metaPage/metaPage.fragment';
import { PACK_EXCERPT_FRAGMENT } from 'apollo/pack/pack.fragment';
import {
    ARTIST_DETAIL_FRAGMENT,
    ARTIST_EXCERPT_FRAGMENT,
} from './artist.fragment';

export const ArtistBySlug = gql`
    query ArtistBySlug($slug: String) {
        artistBySlug(slug: $slug) {
            ...ArtistDetailFragment
        }
    }
    ${ARTIST_DETAIL_FRAGMENT}
`;

export const PaginatedArtists = gql`
    query PaginatedArtists(
        $take: Int
        $page: Int
        $query: String
        $offset: Int
    ) {
        paginatedArtists(
            take: $take
            page: $page
            query: $query
            offset: $offset
        ) {
            data {
                ...ArtistExcerptFragment
            }
            meta {
                totalRecords
                totalPackRecords
            }
            next {
                skip
            }
        }
    }
    ${ARTIST_EXCERPT_FRAGMENT}
`;

export const PaginatedArtistSamples = gql`
    query PaginatedArtistSamples(
        $artistGuid: ID
        $take: Int = 1
        $page: Int = 0
        $query: String
        $offset: Int
    ) {
        paginatedArtistSamples(
            artistGuid: $artistGuid
            take: $take
            page: $page
            query: $query
            offset: $offset
        ) {
            data {
                ...SampleFragment
            }
            meta {
                ...MetaPageTotalFragment
                ...MetaPageFilterFragment
            }
            next {
                skip
            }
        }
    }
    ${SAMPLE_FRAGMENT}
    ${META_PAGE_FILTER_FRAGMENT}
    ${META_PAGE_TOTAL_FRAGMENT}
`;

export const PaginatedArtistPacks = gql`
    query PaginatedArtistPacks(
        $artistGuid: ID
        $take: Int = 1
        $page: Int = 0
        $query: String
        $offset: Int
    ) {
        paginatedArtistPacks(
            artistGuid: $artistGuid
            take: $take
            page: $page
            query: $query
            offset: $offset
        ) {
            data {
                ...PackExcerptFragment
            }
            meta {
                ...MetaPageTotalFragment
                ...MetaPageFilterFragment
            }
            next {
                skip
            }
        }
    }
    ${PACK_EXCERPT_FRAGMENT}
    ${META_PAGE_FILTER_FRAGMENT}
    ${META_PAGE_TOTAL_FRAGMENT}
`;
