import { MockedResponse } from '@apollo/client/testing';
import { mockBuilder } from 'mocks/mockBuilder';
import { aSubscriptionsAndAllowedFeatures } from 'mocks/generated/graphql';
import { ActivateTrialSubscription } from './user.gql';

export const anActivateTrialSubscriptionMutationMock =
    mockBuilder<MockedResponse>({
        request: {
            query: ActivateTrialSubscription,
        },
        result: {
            data: {
                activateTrialSubscription: aSubscriptionsAndAllowedFeatures(),
            },
        },
        error: undefined,
    });

export const anActivateTrialSubscriotionMutationErrorMock =
    mockBuilder<MockedResponse>({
        request: {
            query: ActivateTrialSubscription,
        },
        result: undefined,
        error: Error('Error activating trial subscription'),
    });
