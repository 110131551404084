import { gql } from '@apollo/client';
import { COST_FRAGMENT } from './cost.fragment';

export const SamplesCostPreview = gql`
    query SamplesCostPreview($guid: [String]) {
        samplesCostPreview(guid: $guid) {
            ...CostFrament
        }
    }
    ${COST_FRAGMENT}
`;

export const CreatorSamplesCostPreview = gql`
    query CreatorSamplesCostPreview($guid: [String]) {
        creatorSamplesCostPreview(guid: $guid) {
            ...CostFrament
        }
    }
    ${COST_FRAGMENT}
`;

export const PackCostPreview = gql`
    query PackCostPreview($guid: String) {
        packCostPreview(guid: $guid) {
            ...CostFrament
        }
    }
    ${COST_FRAGMENT}
`;

export const RepackCostPreview = gql`
    query RepackCostPreview($guid: String) {
        repackCostPreview(guid: $guid) {
            ...CostFrament
        }
    }
    ${COST_FRAGMENT}
`;

export const CollectionCostPreview = gql`
    query CollectionCostPreview($guid: String) {
        collectionCostPreview(guid: $guid) {
            ...CostFrament
        }
    }
    ${COST_FRAGMENT}
`;
