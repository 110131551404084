import { EXECUTION_CONTEXT_URL_PARAM } from 'constants/executionEnvironment';
import { Location } from 'react-router-dom';
import { ExecutionEnvironment } from 'types/enums';

export function selectSample(id: string): void {
    window.open(`plugin://api/v1/samples/select/${id}`);
}

export function sendAuthToken(token = ''): void {
    window.open(`plugin://api/v1/samples/authenticate/${token}`);
}

export function didPurchase(id: string): void {
    window.open(`plugin://api/v1/samples/purchase/${id}`);
}

export function demoPlaybackStarted(id: string): void {
    window.open(`plugin://api/v1/samples/demo/${id}`);
}

export function sendLogout(): void {
    window.open(`plugin://api/v1/samples/logout/`);
}

export function sendBrowse(location: Location): void {
    const delimiter = location.search ? `&` : `?`;
    const pluginContext = `${delimiter}${EXECUTION_CONTEXT_URL_PARAM}=${ExecutionEnvironment.DAWPlugin}`;
    const url = `${location.pathname}${location.search}${pluginContext}`;

    window.open(`plugin://api/v1/samples/browse${url}`);
}
