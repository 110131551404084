import React, { useRef, useCallback, useContext } from 'react';
import { neverop } from 'utils/noop';
import {
    ActivePlayerCallback,
    ActivePlayerContextType,
    ActivePlayerProviderProps,
} from './types';
import useKeyboardEvents from './hooks/useKeyboardEvents';

export const DefaultActivePlayerContext = {
    stopActivePlayer: neverop('ActivePlayerContext', 'stopActivePlayer'),
    setActivePlayerCallback: neverop(
        'ActivePlayerContext',
        'setActivePlayerCallback',
    ),
    setActivePlayerId: neverop('ActivePlayerContext', 'setActivePlayerId'),
    getActivePlayerId: neverop('ActivePlayerContext', 'getActivePlayerId', ''),
};

export const ActivePlayerContext = React.createContext<ActivePlayerContextType>(
    DefaultActivePlayerContext,
);

export const ActivePlayerProvider = ({
    children,
}: ActivePlayerProviderProps): JSX.Element => {
    const activePlayerCallback = useRef<ActivePlayerCallback | null>(null);
    const activePlayerIdRef = useRef<string | undefined>(undefined);

    const setActivePlayerCallback = (
        updatePlayerCallback: ActivePlayerCallback,
    ): void => {
        activePlayerCallback.current = updatePlayerCallback;
    };

    const setActivePlayerId = (id?: string): void => {
        activePlayerIdRef.current = id;
    };

    const getActivePlayerId = () => {
        return activePlayerIdRef.current;
    };

    const stopActivePlayer = useCallback((): void => {
        activePlayerCallback?.current &&
            activePlayerCallback.current.stopPlaying();

        activePlayerCallback.current = null;
        activePlayerIdRef.current = undefined;
    }, []);

    useKeyboardEvents({ activePlayerCallback, activePlayerIdRef });

    return (
        <ActivePlayerContext.Provider
            value={{
                stopActivePlayer,
                setActivePlayerCallback,
                setActivePlayerId,
                getActivePlayerId,
            }}
        >
            {children}
        </ActivePlayerContext.Provider>
    );
};

export const useActivePlayerContext = (): ActivePlayerContextType =>
    useContext(ActivePlayerContext);

export default ActivePlayerProvider;
