import React, { useContext, useCallback } from 'react';
import { noop, noopArray } from 'utils/noop';
import { Sample } from 'types/generated/graphql';

enum SessionStorageItems {
    SamplesToAddToCollection = 'landr-samples-to-add-to-collection',
}

export type SessionStorageContextProps = {
    storeSamplesToAddToCollection: (samples: Sample[]) => void;
    getSamplesToAddToCollection: () => Sample[];
};

export const defaultSessionStorageContext: SessionStorageContextProps = {
    storeSamplesToAddToCollection: noop,
    getSamplesToAddToCollection: noopArray,
};

export const SessionStorageContext =
    React.createContext<SessionStorageContextProps>(
        defaultSessionStorageContext,
    );

export const useSessionStorageContext = (): SessionStorageContextProps =>
    useContext(SessionStorageContext);

export const SessionProvider = ({
    children,
}: {
    children: React.ReactNode;
}): React.ReactElement => {
    const storeSamplesToAddToCollection = useCallback((samples: Sample[]) => {
        window.sessionStorage.setItem(
            SessionStorageItems.SamplesToAddToCollection,
            JSON.stringify(samples),
        );
    }, []);

    const getSamplesToAddToCollection = useCallback((): Sample[] => {
        const samples = JSON.parse(
            window.sessionStorage.getItem(
                SessionStorageItems.SamplesToAddToCollection,
            ) || '[]',
        );

        window.sessionStorage.removeItem(
            SessionStorageItems.SamplesToAddToCollection,
        );

        if (Array.isArray(samples)) {
            return samples;
        }

        return [];
    }, []);

    return (
        <SessionStorageContext.Provider
            value={{
                storeSamplesToAddToCollection,
                getSamplesToAddToCollection,
            }}
        >
            {children}
        </SessionStorageContext.Provider>
    );
};

export default SessionProvider;
