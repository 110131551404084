import { from } from '@apollo/client';
import { errorLink } from './apolloErrorLink';
import { transportLink } from './apolloTransportLink';
import { schemaVersionLink } from './apolloSchemaVersionLink';
import { loggerLink } from './apolloLoggerLink';
import { contextLink } from './apolloContextLink';
import { retryLink } from './apolloRetryLink';

export const link = from([
    schemaVersionLink,
    contextLink,
    loggerLink,
    errorLink,
    retryLink,
    transportLink,
]);
