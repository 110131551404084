import { gql } from '@apollo/client';
import {
    META_PAGE_FILTER_FRAGMENT,
    META_PAGE_TOTAL_FRAGMENT,
} from 'apollo/metaPage/metaPage.fragment';
import { PACK_EXCERPT_FRAGMENT } from 'apollo/pack/pack.fragment';
import { REPACK_EXCERPT_FRAGMENT } from 'apollo/repack/repack.fragment';

export const PaginatedPacksAndRepacks = gql`
    query PaginatedPacksAndRepacks(
        $take: Int
        $page: Int
        $query: String
        $offset: Int
    ) {
        paginatedPacksAndRepacks(
            take: $take
            page: $page
            query: $query
            offset: $offset
        ) {
            data {
                ... on Pack {
                    ...PackExcerptFragment
                }
                ... on Repack {
                    ...RepackExcerptFragment
                }
            }
            meta {
                ...MetaPageTotalFragment
                ...MetaPageFilterFragment
            }
            next {
                skip
            }
        }
    }
    ${PACK_EXCERPT_FRAGMENT}
    ${REPACK_EXCERPT_FRAGMENT}
    ${META_PAGE_TOTAL_FRAGMENT}
    ${META_PAGE_FILTER_FRAGMENT}
`;

export const PaginatedDownloadedPacksAndRepacks = gql`
    query PaginatedDownloadedPacksAndRepacks(
        $take: Int = 1
        $page: Int = 0
        $query: String
        $offset: Int
    ) {
        paginatedDownloadedPacksAndRepacksV2(
            take: $take
            page: $page
            query: $query
            offset: $offset
        ) {
            data {
                ... on Pack {
                    ...PackExcerptFragment
                }
                ... on Repack {
                    ...RepackExcerptFragment
                }
            }
            meta {
                ...MetaPageTotalFragment
                ...MetaPageFilterFragment
            }
            next {
                skip
            }
        }
    }
    ${PACK_EXCERPT_FRAGMENT}
    ${REPACK_EXCERPT_FRAGMENT}
    ${META_PAGE_TOTAL_FRAGMENT}
    ${META_PAGE_FILTER_FRAGMENT}
`;

export const PaginatedFavoritedPacksAndRepacks = gql`
    query PaginatedFavoritedPacksAndRepacks(
        $take: Int = 1
        $page: Int = 0
        $query: String
        $offset: Int
    ) {
        paginatedFavoritedPacksAndRepacksV2(
            take: $take
            page: $page
            query: $query
            offset: $offset
        ) {
            data {
                ... on Pack {
                    ...PackExcerptFragment
                }
                ... on Repack {
                    ...RepackExcerptFragment
                }
            }
            meta {
                ...MetaPageTotalFragment
                ...MetaPageFilterFragment
            }
            next {
                skip
            }
        }
    }
    ${PACK_EXCERPT_FRAGMENT}
    ${REPACK_EXCERPT_FRAGMENT}
    ${META_PAGE_TOTAL_FRAGMENT}
    ${META_PAGE_FILTER_FRAGMENT}
`;
