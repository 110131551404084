import { DownloadableItem } from 'types';
import { OpenModal } from 'contexts/ModalContext/ModalContext.type';

export const handleCreditUseWarning = ({
    item,
    openModal,
}: {
    item: DownloadableItem;
    openModal: OpenModal;
}) => {
    openModal.creditsWarning();
};
