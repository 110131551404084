import React from 'react';
import { Button, Card, InfoFilled, Box } from '@landr/maestro';
import { Trans } from '@lingui/macro';
import { areEqual } from 'helpers/areEqual';

const refreshPage = () => {
    window?.location?.reload();
};

// You can use `withErrorBoundary` with the variant `verbose`
// Or use it directly in your component, when you are handling an error and wish to render this error ui.
// If we know the error is a 404, it should be handle.
const VerboseError: React.FC = () => (
    <Card
        display="flex"
        justifyContent="flex-start"
        flexDirection="row"
        backgroundColor="neutral.800"
    >
        <Box>
            <InfoFilled color="neutral.500" />
        </Box>
        <Box>
            <Box ml="md" mb="xxs" fontWeight="bolder">
                <Trans>Something went wrong</Trans>
            </Box>
            <Box ml="md">
                <Trans>
                    We couldn’t load this component. Please refresh to try
                    again.
                </Trans>
            </Box>
            <Button kind="minimal" size="sm" onClick={refreshPage}>
                <Trans>Refresh</Trans>
            </Button>
        </Box>
    </Card>
);

export default React.memo(VerboseError, areEqual);
