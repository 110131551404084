import { ApolloClient, InMemoryCache } from '@apollo/client';
import { CONFIG } from '../config';
import { link } from './link';
import { cacheConfig } from './cache';

export const apolloClient = new ApolloClient({
    uri: CONFIG.VITE_GRAPHQL_API,
    cache: new InMemoryCache(cacheConfig),
    link: link,
});
