import React, { useState, useContext } from 'react';
import { useModalContext } from 'contexts/ModalContext/ModalContext';
import { DownloadableItem } from 'types';

export type FreeUserFreeDownloadUpsellModalContextType = {
    downloadableItem: DownloadableItem | null;
    isOpen: boolean;
    openModal: (item: DownloadableItem) => void;
    closeModal: () => void;
};

export const defaultFreeUserFreeDownloadUpsellModalContext = {
    downloadableItem: null,
    isOpen: false,
    openModal: () => undefined,
    closeModal: () => undefined,
};

export const FreeUserFreeDownloadUpsellModalContext =
    React.createContext<FreeUserFreeDownloadUpsellModalContextType>(
        defaultFreeUserFreeDownloadUpsellModalContext,
    );

export const useFreeUserFreeDownloadUpsellModalContext =
    (): FreeUserFreeDownloadUpsellModalContextType =>
        useContext(FreeUserFreeDownloadUpsellModalContext);

export const FreeUserFreeDownloadUpsellModalProvider = ({
    children,
}: {
    children: React.ReactNode;
}): React.ReactElement => {
    const { openModal, closeModal, isModalVisible } = useModalContext();

    const [downloadableItem, setDownloadableItem] =
        useState<DownloadableItem | null>(null);

    const handleOpenModal = (item: DownloadableItem) => {
        setDownloadableItem(item);

        openModal.freeUserFreeDownloadUpsell();
    };

    const handleCloseModal = () => {
        closeModal.freeUserFreeDownloadUpsell();
    };

    const context = {
        downloadableItem,
        isOpen: isModalVisible.freeUserFreeDownloadUpsell,
        openModal: handleOpenModal,
        closeModal: handleCloseModal,
    };

    return (
        <FreeUserFreeDownloadUpsellModalContext.Provider value={context}>
            {children}
        </FreeUserFreeDownloadUpsellModalContext.Provider>
    );
};

export default FreeUserFreeDownloadUpsellModalProvider;
