// FE Events Ids [ 15000 - 15799 ]
// Reserved events ids - https://www.notion.so/landr/16ca40de80e942f3a8f25f9e944dd70a?v=c3689c08477f42418abeced2159777fd

export enum EventIds {
    // Global errors
    UnhandledError = 15000,
    ComponentUnhandledError,
    RequestFlagsFailed,
    BootstrapError,

    // UserFailureEvents
    UnsupportedLocale = 15010,

    // PrerenderEvents
    PrerenderPageHasDoneRendering = 15050,
    PrerenderPageHasDoneRenderingTwice,
    PrerenderDuplicateComponentName,
    PrerenderUnmonitoredComponentDoneRendering,
    ManualySetPrerendercloudReadyToTrue,
    PrerenderManagerInitializedWhenNotPrerendering,

    // Common
    ProblemDetailsTypeError = 15100,
    FiltersAnalyticsError,
    DownloadBinZipFailed,
    UnknownDownloadType,
    PackDetailsRenderNull,
    CreatorPageCardRenderNull,
    DuplicateQueryParams,
    FalsyDownloadableItem,
    DownloadBinFalsyResponse,
    DownloadBinNetworkProblem,
    DownloadCreatorItemWithinDaw,
    ContextMethodNotInitialized,
    ArtistDetailsFailedToRender,
    LabelDetailsFailedToRender,
    FailedToGetGeolocation,
    UnknownHeroBannerContentType,
    InvalidPlanCode,
    InvalidDownloadableItem,
    FailedFindPriceOfProductCode,
    FailedToDefinePurchaseLink,
    UnsupportedPostLoginAction,
    NoArgumentsProvidedToPostLoginAction,
    UserCollectionMismatchedOrMissing,
    FailedToGetAValidStreamUrl,
    ReactPlayerVideoError,
    RetryLazyImportFailed,
    RetryLazyImport,
    ClientUpdateContextPerformReload,
    ClientUpdateContextDisplayBanner,
    BrandtypeDetailsFailedToRender,

    // Creator
    FailedToDownloadStream = 15200,
    FailedToFetchStreamURL,
    FailedToParseHash,
    FailedToCreateHash,
    TooManyUnsanitizedSamples,
    UnsanitizedSamplesWithoutGuid,
    HashTooLong,
    CreatorSequencerMissedEvent,
    WarpWorkerError,
    SessionWithoutSamples,
    InvalidSessionGuid,
    FailedToInitTrack,
    FailedToResumeAudioContext,
    InvalidAudioContext,
    FailedToUnlockAudioContext,
    CreatorMixdownMp3ConversionFailed,
    FailToImportLameJs,
    FailedToFetchCreatorStreamURL,

    // Apollo Global errors
    ApolloAuthenticationsError = 15500,
    ApolloUnauthorizedError,
    ApolloForbiddenError,
    ApolloInternalServerError,
    ApolloNetworkError,
    ApolloError,
    ApolloTraceLogs,

    // Analytics errors
    FailedGetAnalyticsPropertiesError = 15550,

    // Query / Mutation errors
    FailedGetSessionById = 15600,
    StoreSessionError,
    FailedToFetchSample,
    FailedPurchase,
    FailedGetMerchZone,
    RequestDownloadCostFailed,
    GetEmptyPromotionalCardsRepeater,
    GetWaveformFailed,
    LabelSuggestionsError,
    PackSuggestionsError,
}
