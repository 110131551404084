import { useState } from 'react';

export const useTrackPageVisited = (
    pageName: string,
): { isPageVisited: boolean; trackPageVisited: () => void } => {
    const isPageVisitedStorage = Boolean(
        localStorage.getItem(`isVisited-${pageName}`),
    );

    // Don't change accross renders
    const [isPageVisited, setIsPageVisited] = useState(isPageVisitedStorage);

    const trackPageVisited = () => {
        if (!isPageVisitedStorage) {
            localStorage.setItem(`isVisited-${pageName}`, 'true');
        }

        setIsPageVisited(true);
    };

    return { isPageVisited, trackPageVisited };
};
