import { gql } from '@apollo/client';
import {
    FILTER_FAMILY_FRAGMENT,
    FILTER_WITH_LABEL_FRAGMENT,
} from './filters.fragment';

export const GetFilters = gql`
    query Filters {
        filters {
            instruments {
                ...FilterFamilyFragment
            }
            genres {
                ...FilterFamilyFragment
            }
            sfxs {
                ...FilterFamilyFragment
            }
            tags {
                ...FilterWithLabelFragment
            }
            sampleTypes {
                code
            }
        }
    }
    ${FILTER_WITH_LABEL_FRAGMENT}
    ${FILTER_FAMILY_FRAGMENT}
`;
