import { EventIds } from 'constants/eventIds';
import noop from 'lodash/noop';
import { log } from 'utils/log';

// Different flavors of 'no-ops'; empty functions that do nothing. Used as default
// or placeholder values to avoid lowering code coverage.

export const noopNumber = (): number => 0;
export const noopString = (): string => '';
export const noopBoolean = (): boolean => false;
export const noopArray = (): [] => [];
export const noopPromise = (): Promise<unknown> =>
    new Promise((resolve) => resolve(true));
export const getNoop = (): (() => void) => noop;

export function neverop(moduleName: string, methodName: string): () => void;
export function neverop(
    moduleName: string,
    methodName: string,
    placeholder: undefined,
): () => undefined;
export function neverop(
    moduleName: string,
    methodName: string,
    placeholder: number,
): () => number;
export function neverop(
    moduleName: string,
    methodName: string,
    placeholder: string,
): () => string;
export function neverop(
    moduleName: string,
    methodName: string,
    placeholder: boolean,
): () => boolean;
export function neverop<T>(
    moduleName: string,
    methodName: string,
    placeholder: Promise<T>,
): () => Promise<T>;
export function neverop<T>(
    moduleName: string,
    methodName: string,
    placeholder?: T,
): () => T | void {
    return () => {
        if (process.env.NODE_ENV !== 'test') {
            log.error(
                `A placeholder function ${methodName} was called in ${moduleName} instead of the initialized function. If this a Context method, make sure the Context Provider exists as a parent.`,
                EventIds.ContextMethodNotInitialized,
            );
        }

        return placeholder;
    };
}

export { noop };
