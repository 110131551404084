import { EventIds } from 'constants/eventIds';
import { DownloadableItem } from 'types';
import { ConsoleLogExtraArguments } from '@landr/core';
import { InteractionSource } from 'utils/analytics/AnalyticsV2Service.generated';

export type PurchaseConfig = {
    item: DownloadableItem;
    skipDownload: boolean;
    skipCreditUseApproval: boolean;
    interactionSource?: InteractionSource;
};

export type PurchaseContextStartPurchase = (params: {
    item: DownloadableItem;
    skipCreditUseApproval?: boolean;
    interactionSource?: InteractionSource;
    onSuccess?: () => void;
}) => void;

export interface IPurchaseContext {
    item: DownloadableItem | undefined;
    purchaseCostInCredits: number;
    isLoading: boolean;
    queuedItemIDs: string[];
    approveCreditUse: () => void;
    cancelPurchase: () => void;
    startPurchase: PurchaseContextStartPurchase;
    showNotEnoughCreditsWarning: (params: {
        purchaseCostInCredits: number;
    }) => void;
    showPoorStandingOrSuspended: () => void;
}

export enum PurchaseSteps {
    Empty = 'Empty',
    DidError = 'DidError',
    CostPreview = 'CostPreview',
    AwaitingCostPreview = 'AwaitingCostPreview',
    CostPreviewReady = 'CostPreviewReady',
    ReadyToDownload = 'ReadyToDownload',
    NotEnoughCredits = 'NotEnoughCredits',
    PoorStandingOrSuspended = 'PoorStandingOrSuspended',
    CreditUseApproved = 'CreditUseApproved',
    PlanPaused = 'PlanPaused',
    TrialSubscription = 'TrialSubscription',
}

export enum PurchaseActionTypes {
    Exit = 'Exit',
    ExitWithError = 'ExitWithError',
    Start = 'Start',
    Resume = 'Resume',
    GetCostPreview = 'GetCostPreview',
    SetPurchaseCost = 'SetPurchaseCost',
    ShowNotEnoughCreditsWarning = 'ShowNotEnoughCreditsWarning',
    ShowPoorStandingOrSuspended = 'ShowPoorStandingOrSuspended',
    ShowPlanPaused = 'ShowPlanPaused',
    ShowActivateTrialSubscription = 'ShowActivateTrialSubscription',
    ApproveCreditUse = 'ApproveCreditUse',
    StartDownload = 'StartDownload',
    UpdateQueue = 'UpdateQueue',
}

export type PurchaseError = {
    message: string;
    id: EventIds;
    error: Error | undefined;
    extraInfos?: ConsoleLogExtraArguments;
};
