import { gql } from '@apollo/client';
import {
    META_PAGE_FILTER_FRAGMENT,
    META_PAGE_TOTAL_FRAGMENT,
} from 'apollo/metaPage/metaPage.fragment';
import { SAMPLE_FRAGMENT } from 'apollo/sample/sample.fragment';
import { PACK_DETAIL_FRAGMENT, PACK_EXCERPT_FRAGMENT } from './pack.fragment';

export const PaginatedPackSamples = gql`
    query PaginatedPackSamples(
        $packGuid: ID
        $take: Int = 1
        $page: Int = 0
        $query: String
        $offset: Int
    ) {
        paginatedPackSamples(
            packGuid: $packGuid
            take: $take
            page: $page
            query: $query
            offset: $offset
        ) {
            data {
                ...SampleFragment
            }
            meta {
                ...MetaPageTotalFragment
                ...MetaPageFilterFragment
            }
            next {
                skip
            }
        }
    }
    ${META_PAGE_FILTER_FRAGMENT}
    ${META_PAGE_TOTAL_FRAGMENT}
    ${SAMPLE_FRAGMENT}
`;

export const PackBySlugOrGuid = gql`
    query PackBySlugOrGuid($packSlugOrGuid: ID!) {
        pack(packSlugOrGuid: $packSlugOrGuid) {
            ...PackDetailFragment
        }
    }
    ${PACK_DETAIL_FRAGMENT}
`;

export const SimilarPacks = gql`
    query SimilarPacks($packSlugOrGuid: ID!) {
        similarPacks(packSlugOrGuid: $packSlugOrGuid) {
            data {
                ...PackExcerptFragment
            }
        }
    }
    ${PACK_EXCERPT_FRAGMENT}
`;

export const PackIsDownloaded = gql`
    query PackIsDownloaded($guid: ID!) {
        pack(packSlugOrGuid: $guid) {
            guid
            isDownloaded
        }
    }
`;

export const UpdatePackFavoritedState = gql`
    mutation UpdatePackFavoritedState($pack: PackFavoritedInput!) {
        updatePackFavoritedState(pack: $pack) {
            guid
            isFavorited
        }
    }
`;

export const PacksStream = gql`
    query PacksStream($packGuid: ID!) {
        packStream(packGuid: $packGuid) {
            url
        }
    }
`;
