import React from 'react';
import PageMetadata from 'components/PageMetadata';
import useBrowseSeo from 'hooks/useBrowseSeo';

export const Browse = () => {
    const { metaData } = useBrowseSeo();
    const { title, description, imageUrl } = metaData;

    return (
        <PageMetadata
            title={title}
            description={description}
            imageUrl={imageUrl}
        />
    );
};
