import React from 'react';
import { useLocation } from 'react-router-dom';
import { Languages } from 'types/enums';
import { usePrefixByLocales } from 'hooks/usePrefixByLocales/usePrefixByLocales';
import { i18n } from 'utils/setupTranslations';

export const useAlternateLinks = () => {
    const localePrefixes = usePrefixByLocales();
    const location = useLocation();

    const currentLocalPrefix = localePrefixes[i18n.locale as Languages];

    const pathnameWithoutLocale = location.pathname.replace(
        currentLocalPrefix,
        '',
    );

    const localeLinks = Object.entries(localePrefixes).map(
        ([locale, prefix]) => {
            const alternateUrl =
                window.location.origin + prefix + pathnameWithoutLocale;

            return (
                <link
                    rel="alternate"
                    hrefLang={locale}
                    href={alternateUrl}
                    key={`alternate-${locale}`}
                />
            );
        },
    );

    const xDefaultHref = `${window.location.origin}${pathnameWithoutLocale}`;

    const xDefaultLink = (
        <link
            rel="alternate"
            hrefLang="x-default"
            href={xDefaultHref}
            key={`alternate-x-default`}
        />
    );

    return { localeLinks, xDefaultLink };
};
