import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { t } from '@lingui/macro';
import PageMetadata from 'components/PageMetadata';
import { getSanitizeSlug } from 'helpers/getSanitizeSlug';
import { BrandtypeBySlug } from 'apollo/brandtype/brandtype.gql';
import {
    BrandtypeBySlugQuery,
    BrandtypeBySlugQueryVariables,
} from 'types/generated/graphql';
import { useSeoMetaData } from 'hooks/useSeoMetaData';

export const BrandtypeDetails = () => {
    const { slug } = useParams<{ slug: string }>();
    const { data, loading, error } = useQuery<
        BrandtypeBySlugQuery,
        BrandtypeBySlugQueryVariables
    >(BrandtypeBySlug, {
        variables: { slug: getSanitizeSlug(slug) },
    });

    const { loading: seoMetaDataLoading, seoMetaData } = useSeoMetaData({
        withWildcard: false,
    });

    if (loading || seoMetaDataLoading) {
        return null;
    }

    if (!data?.brandtypeBySlug || error) {
        return <PageMetadata />;
    }

    const { brandtypeBySlug } = data;

    const defaultTitle = t`Download ${brandtypeBySlug.name} Audio Samples, Sounds and Loops`;
    const defaultDescription = t`Download samples and sounds from ${brandtypeBySlug.name}. Get the best royalty free sample packs, loops, drum kits and sound libraries from LANDR Samples.`;

    const title = seoMetaData?.title ? seoMetaData.title : defaultTitle;
    const description = seoMetaData?.description
        ? seoMetaData?.description
        : defaultDescription;

    return <PageMetadata title={title} description={description} />;
};
