import { noop } from 'utils/noop';
import React, { useContext, useState } from 'react';
import { UserCollection, Sample } from 'types/generated/graphql';

export type CollectionModalContextType = {
    collection: UserCollection | null;
    setCollection: React.Dispatch<React.SetStateAction<UserCollection | null>>;
    samplesToDelete: Sample[];
    setSamplesToDelete: React.Dispatch<React.SetStateAction<Sample[]>>;
    samplesToAdd: Sample[];
    setSamplesToAdd: React.Dispatch<React.SetStateAction<Sample[]>>;
};

export const defaultCollectionModalContext = {
    collection: null,
    setCollection: noop,
    samplesToDelete: [],
    setSamplesToDelete: noop,
    samplesToAdd: [],
    setSamplesToAdd: noop,
};

export const CollectionModalContext =
    React.createContext<CollectionModalContextType>(
        defaultCollectionModalContext,
    );

export const useCollectionModalContext = (): CollectionModalContextType =>
    useContext(CollectionModalContext);

export const CollectionModalContextProvider = ({
    children,
}: {
    children: React.ReactNode;
}): React.ReactElement => {
    const [collection, setCollection] =
        useState<CollectionModalContextType['collection']>(null);

    const [samplesToDelete, setSamplesToDelete] = useState<
        CollectionModalContextType['samplesToDelete']
    >([]);

    const [samplesToAdd, setSamplesToAdd] = useState<
        CollectionModalContextType['samplesToAdd']
    >([]);

    return (
        <CollectionModalContext.Provider
            value={{
                collection,
                setCollection,
                samplesToDelete,
                setSamplesToDelete,
                samplesToAdd,
                setSamplesToAdd,
            }}
        >
            {children}
        </CollectionModalContext.Provider>
    );
};

export default CollectionModalContextProvider;
