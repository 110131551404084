import { gql } from '@apollo/client';

export const UserProfile = gql`
    query UserProfile {
        userProfile {
            userId
            email
            firstname
            lastname
            profilePicture72Url
            country
        }
    }
`;

export const UserCredits = gql`
    query UserCredits {
        userCredits {
            remaining
        }
    }
`;

export const UserAgreements = gql`
    query UserAgreements {
        userAgreements {
            agreements {
                requireUpdate
                kind
                version
            }
        }
    }
`;

export const AccountBalanceSummary = gql`
    query AccountBalanceSummary {
        accountBalanceSummary {
            accountingStatus
        }
    }
`;

export const SubscriptionsAndAllowedFeatures = gql`
    query SubscriptionsAndAllowedFeatures {
        subscriptionsAndAllowedFeatures {
            hasStudioPlan
            hasSamplesPlan
            hasSubscription
            upsellingAllowed
            samplePaidContentAccess {
                isAllowed
                unallowedReason {
                    reason
                    unallowedByPlan
                }
            }
        }
    }
`;

export const AcceptUserAgreements = gql`
    mutation AcceptUserAgreements($agreements: AcceptUserAgreementsInput!) {
        acceptUserAgreements(agreements: $agreements)
    }
`;

export const ActivateTrialSubscription = gql`
    mutation ActivateTrialSubscription {
        activateTrialSubscription {
            ... on SubscriptionsAndAllowedFeatures {
                samplePaidContentAccess {
                    isAllowed
                    unallowedReason {
                        reason
                        unallowedByPlan
                    }
                }
            }
        }
    }
`;
