import { useCallback } from 'react';
import { DownloadableItem } from 'types/downloadableItem';
import { InteractionSource } from 'utils/analytics/AnalyticsV2Service.generated';
import {
    PurchaseActionTypes,
    PurchaseConfig,
    PurchaseSteps,
} from '../Purchase.types';
import { PurchaseAction } from './purchaseReducer';

export type UsePurchaseControllerCallbacks = (args: {
    activeStep: PurchaseSteps;
    dispatch: React.Dispatch<PurchaseAction>;
    isDAWPlugin: boolean;
    purchaseQueue: PurchaseConfig[];
}) => {
    startPurchase: (args: { item: DownloadableItem }) => void;
    approveCreditUse: () => void;
    cancelPurchase: () => void;
    showNotEnoughCreditsWarning: (args: {
        purchaseCostInCredits: number;
    }) => void;
    showPoorStandingOrSuspended: () => void;
};

export const usePurchaseControllerCallbacks: UsePurchaseControllerCallbacks = ({
    activeStep,
    dispatch,
    isDAWPlugin,
    purchaseQueue,
}) => {
    const startPurchase = useCallback(
        ({
            item,
            skipCreditUseApproval = false,
            interactionSource,
        }: {
            item: DownloadableItem;
            skipCreditUseApproval?: boolean;
            interactionSource?: InteractionSource;
        }): void => {
            const skipDownload = isDAWPlugin;

            const purchaseConfig = {
                item,
                skipDownload,
                skipCreditUseApproval,
                interactionSource,
            };

            // We can only start immediately if the queue is empty and the flow is in
            // empty state. Otherwise we push the config to queue.
            if (activeStep !== PurchaseSteps.Empty || purchaseQueue.length) {
                dispatch({
                    type: PurchaseActionTypes.UpdateQueue,
                    purchaseQueue: [...purchaseQueue, purchaseConfig],
                });
            } else {
                dispatch({
                    type: PurchaseActionTypes.Start,
                    ...purchaseConfig,
                    purchaseQueue: [],
                });
            }
        },
        [activeStep, dispatch, isDAWPlugin, purchaseQueue],
    );

    const approveCreditUse = useCallback(() => {
        dispatch({
            type: PurchaseActionTypes.ApproveCreditUse,
        });
    }, [dispatch]);

    const cancelPurchase = useCallback(() => {
        dispatch({
            type: PurchaseActionTypes.Exit,
        });
    }, [dispatch]);

    const showNotEnoughCreditsWarning = useCallback(
        ({ purchaseCostInCredits }: { purchaseCostInCredits: number }) => {
            dispatch({
                type: PurchaseActionTypes.ShowNotEnoughCreditsWarning,
                purchaseCostInCredits,
            });
        },
        [dispatch],
    );

    const showPoorStandingOrSuspended = useCallback(() => {
        dispatch({
            type: PurchaseActionTypes.ShowPoorStandingOrSuspended,
        });
    }, [dispatch]);

    return {
        startPurchase,
        approveCreditUse,
        cancelPurchase,
        showNotEnoughCreditsWarning,
        showPoorStandingOrSuspended,
    };
};
