import { v4 as uuidv4 } from 'uuid';
import { KeySign, KeyRoot, getNormalizedRootValue } from 'types/enums';
import { noop } from 'utils/noop';
import {
    KeyQuality,
    KeyRootApi,
    PackState,
    PackType,
    SampleState,
    SampleType,
} from 'types/generated/graphql';
import {
    CreatorContextStateType,
    CreatorSamplesMapType,
    CreatorContextComputedStateType,
    CreatorAction,
    CreatorBpmMultiplier,
    CreatorSampleType,
} from './Creator.types';

export const defaultCreatorState: CreatorContextStateType &
    CreatorContextComputedStateType = {
    instrumentationSessionId: uuidv4(),
    bpm: 120,
    isCreatorOpen: false,
    isRemoveAllModalOpen: false,
    isLoadSessionModalOpen: false,
    isShareModalOpen: false,
    isSessionProcessing: false,
    isUiPaused: true,
    keyQuality: KeyQuality.Major,
    keyRoot: KeyRootApi.C,
    keySign: KeySign.Flat,
    normalizedRootValue: KeyRoot.C,
    onboarding: [],
    samplesInSession: new Map(),
    samples: new Map(),
    isSessionBpmDefined: false,
    isSessionKeyDefined: false,
};

const defaultCreatorSampleControlsType = {
    semitoneShift: 0,
    timeFactor: 1,
    timeFactorBeforeMultiplier: 1,
    bpmMultiplier: 'None' as CreatorBpmMultiplier,
    bpm: 0,
    gain: 7,
    pitchTranspose: undefined,
    recommendedValues: [0],
    scaledDuration: null,
    isAutoload: false,
};
const defaultCreatorSampleStatusType = {
    onAdded: noop,
    streamUrl: '',
    stream: undefined,
};

export const defaultCreatorSample: CreatorSampleType = {
    ...defaultCreatorSampleControlsType,
    ...defaultCreatorSampleStatusType,
    fetching: false,
    sampleState: SampleState.Available,
    guid: '',
    isDownloaded: false,
    isFavorited: false,
    credits: 0,
    duration: 0,
    instruments: [],
    sfxs: [],
    tags: [],
    waveformUrl: '',
    key: {
        code: undefined,
        quality: undefined,
        root: undefined,
        sanitizedCode: undefined,
    },
    name: '',
    sampleType: SampleType.Loop,
    pack: {
        guid: '',
        isDownloaded: false,
        isExclusive: false,
        isFavorited: false,
        isFree: false,
        name: '',
        packState: PackState.Published,
        slug: '',
        type: PackType.Pack,
    },
};

function creatorReducer(
    state: CreatorContextStateType,
    action: CreatorAction,
): CreatorContextStateType {
    const { samples } = state;
    let newState;
    let sample;
    let updatedSamples: CreatorSamplesMapType;

    switch (action.type) {
        case 'newInstrumentationSessionId':
            return { ...state, instrumentationSessionId: uuidv4() };
        case 'changeBPM':
            return {
                ...state,
                bpm: action.payload,
                isSessionBpmDefined: true,
            };
        case 'changeKey':
            return {
                ...state,
                keyRoot: action.payload.root,
                keyQuality: action.payload.quality,
                keySign: action.payload.sign,
                normalizedRootValue: getNormalizedRootValue(
                    action.payload.root,
                    action.payload.sign,
                ),

                isSessionKeyDefined: true,
            };
        case 'updateSample':
            updatedSamples = new Map(samples);

            sample = samples.get(action.payload.guid);

            if (!sample) {
                return state;
            }

            // Note: We had a bug, where updateSample overwritten creatorMeta
            // because it was running after `updateSamples` from the loading session
            // it was fixed by not updating CreatorMeta when updating the sample
            updatedSamples.set(action.payload.guid, {
                ...sample,
                ...action.payload.sample,
            });

            return { ...state, samples: updatedSamples };
        case 'updateSamples':
            updatedSamples = new Map(samples);

            action.payload.samples.forEach((updatedSample, id) => {
                sample = samples.get(id);

                if (!sample) {
                    return;
                }

                updatedSamples.set(id, {
                    ...sample,
                    ...updatedSample,
                });
            });

            return { ...state, samples: updatedSamples };
        case 'addSamples':
            action.payload.guids.forEach((sampleId) => {
                samples.set(sampleId, {
                    ...defaultCreatorSample,
                    ...action.payload.options,
                    ...action.payload.sample,
                    guid: sampleId,
                    isAutoload: action.payload?.isAutoload,
                });
            });

            return { ...state, samples: new Map(samples) };
        case 'removeSamples':
            action.payload.forEach((sampleId) => {
                samples.delete(sampleId);
            });

            newState = {
                ...state,
                samples: new Map(samples),
                ...(!samples.size && {
                    isSessionBpmDefined: false,
                    isSessionKeyDefined: false,
                }),
            };

            return newState;
        case 'removeAllSamples':
            return {
                ...state,
                samples: new Map(),
                isSessionBpmDefined: false,
                isSessionKeyDefined: false,
            };
        case 'setFetchStatus':
            sample = samples.get(action.payload.guid);

            if (!sample) {
                return state;
            }

            sample.fetching = action.payload.fetchStatus;
            samples.set(action.payload.guid, sample);

            return { ...state, samples: new Map(samples) };
        case 'setStream':
            sample = samples.get(action.payload.guid);

            if (!sample) {
                return state;
            }

            sample.stream = action.payload.stream;
            sample.fetching = false;
            samples.set(action.payload.guid, sample);

            return { ...state, samples: new Map(samples) };
        case 'setStreamUrl':
            sample = samples.get(action.payload.guid);

            if (!sample) {
                return state;
            }

            sample.streamUrl = action.payload.streamUrl;
            samples.set(action.payload.guid, sample);

            return { ...state, samples: new Map(samples) };
        default:
            throw new Error();
    }
}

export default creatorReducer;
