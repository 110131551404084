import { User } from 'types/generated/graphql';

export const getUserCombinedName = (userProfile?: User): string | undefined => {
    if (!userProfile) {
        return;
    }

    const userName = [userProfile?.firstname, userProfile?.lastname];

    const combinedName = userName
        .filter(function (el) {
            return el;
        })
        .join(' ');

    return combinedName;
};
