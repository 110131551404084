import { EventIds } from 'constants/eventIds';
import React, { useContext, useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { log } from 'utils/log';
import { noop } from 'utils/noop';
import { Trans, t } from '@lingui/macro';
import { AppNotificationBar } from 'components/AppNotificationBar';
import { VersionChange } from 'types/enums';

export type ClientUpdateContextType = {
    updateRequired: VersionChange | false;
    setUpdateRequired: (updateRequired: VersionChange | false) => void;
};

const emptyContext = {
    setUpdateRequired: noop,
    updateRequired: false as VersionChange | false,
};

export const ClientUpdateContext =
    React.createContext<ClientUpdateContextType>(emptyContext);

export const useClientUpdateContext = (): ClientUpdateContextType =>
    useContext(ClientUpdateContext);

export const ClientUpdateProvider = ({
    children,
}: {
    children: React.ReactNode;
}): React.ReactElement => {
    const [updateRequired, setUpdateRequired] = useState<VersionChange | false>(
        false,
    );
    const { pathname } = useLocation();

    const doReload = useCallback(() => {
        log.warn(
            `Client Update Context Reload Performed`,
            EventIds.ClientUpdateContextPerformReload,
        );
        window.location.reload();
    }, []);

    const showBanner = updateRequired === VersionChange.Major;

    useEffect(() => {
        if (showBanner) {
            log.warn(
                `Client Update Context display informative banner`,
                EventIds.ClientUpdateContextDisplayBanner,
            );
        }
    }, [showBanner]);

    useEffect(() => {
        // Refresh the page on location change for Major/Minor version changes
        if (updateRequired) {
            doReload();
        }
        // Only run on location change, not when update required is set
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    return (
        <ClientUpdateContext.Provider
            value={{ updateRequired, setUpdateRequired }}
        >
            {showBanner && (
                <AppNotificationBar
                    variant="informational"
                    action={{
                        children: t`Update`,
                        onClick: doReload,
                    }}
                >
                    <Trans>
                        Updates are available. Please refresh for the best
                        experience.
                    </Trans>
                </AppNotificationBar>
            )}
            {children}
        </ClientUpdateContext.Provider>
    );
};

export default ClientUpdateProvider;
