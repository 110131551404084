export type Maybe<T> = T | undefined;
export type InputMaybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = {
    [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    Date: any;
};

export type AcceptUserAgreementsInput = {
    kinds: Array<InputMaybe<LegalAgreementKindEnum>>;
};

export type AccountBalanceSummary = {
    __typename?: 'AccountBalanceSummary';
    accountingStatus: AccountStatusEnum;
    balance?: Maybe<Scalars['Float']>;
    currencyCode?: Maybe<Scalars['String']>;
    currencySymbol?: Maybe<Scalars['String']>;
    overduePayments: Array<OverduePayment>;
};

export enum AccountStatusEnum {
    Collection = 'Collection',
    Good = 'Good',
    PoorStanding = 'PoorStanding',
}

export type Agreement = {
    __typename?: 'Agreement';
    kind: LegalAgreementKindEnum;
    requireUpdate?: Maybe<Scalars['Boolean']>;
    version?: Maybe<Scalars['String']>;
};

export type Agreements = {
    __typename?: 'Agreements';
    agreements?: Maybe<Array<Maybe<Agreement>>>;
};

export type AllSubscriptions = {
    __typename?: 'AllSubscriptions';
    subscriptions?: Maybe<Array<Maybe<CurrentSubscription>>>;
};

export type AllowedFeature = {
    __typename?: 'AllowedFeature';
    isAllowed: Scalars['Boolean'];
    unallowedReason?: Maybe<UnallowedReason>;
};

export type AlternateLanguage = {
    __typename?: 'AlternateLanguage';
    id: Scalars['ID'];
    lang: Scalars['String'];
    type: Scalars['String'];
    uid?: Maybe<Scalars['String']>;
};

export type Artist = {
    __typename?: 'Artist';
    artworkUrl?: Maybe<Scalars['String']>;
    coverUrl?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    guid: Scalars['ID'];
    isExclusive: Scalars['Boolean'];
    name?: Maybe<Scalars['String']>;
    packCount?: Maybe<Scalars['Int']>;
    portraitUrl?: Maybe<Scalars['String']>;
    sampleCount?: Maybe<Scalars['Int']>;
    /** @deprecated use seoByPath query */
    seoMetadata?: Maybe<SeoMetadata>;
    slug?: Maybe<Scalars['String']>;
};

export type Balance = {
    __typename?: 'Balance';
    balance?: Maybe<Scalars['Int']>;
};

export enum BpmMultiplier {
    Double = 'Double',
    Half = 'Half',
    None = 'None',
}

export type Brandtype = {
    __typename?: 'Brandtype';
    description?: Maybe<Scalars['String']>;
    guid: Scalars['ID'];
    name?: Maybe<Scalars['String']>;
    packCount?: Maybe<Scalars['Int']>;
    sampleCount?: Maybe<Scalars['Int']>;
    slug?: Maybe<Scalars['String']>;
};

export type Collection = {
    __typename?: 'Collection';
    artworkUrl?: Maybe<Scalars['String']>;
    guid: Scalars['ID'];
    isDownloaded: Scalars['Boolean'];
    isExclusive: Scalars['Boolean'];
    isFavorited: Scalars['Boolean'];
    isFree: Scalars['Boolean'];
    name?: Maybe<Scalars['String']>;
    slug?: Maybe<Scalars['String']>;
    subType: RepackSubType;
    type: PackType;
};

export type ContentGroup = {
    __typename?: 'ContentGroup';
    byline: Scalars['String'];
    contentLists: Array<Maybe<ContentList>>;
    guid: Scalars['ID'];
    target: ContentTarget;
    title: Scalars['String'];
    to?: Maybe<Scalars['String']>;
};

export type ContentList = {
    __typename?: 'ContentList';
    items: Array<Maybe<ContentListItem>>;
    tabName: Scalars['String'];
};

export type ContentListItem = Pack | Repack;

export enum ContentTarget {
    AuthenticatedOnly = 'AuthenticatedOnly',
    GuestAndAuthenticated = 'GuestAndAuthenticated',
    GuestOnly = 'GuestOnly',
}

export type CostPreviewResponse = {
    __typename?: 'CostPreviewResponse';
    credits?: Maybe<Scalars['Int']>;
    remainingCredits?: Maybe<Scalars['Int']>;
    unavailabilityReason?: Maybe<Scalars['String']>;
};

export type CreatorSession = {
    __typename?: 'CreatorSession';
    bpm: Scalars['Int'];
    guid: Scalars['ID'];
    isPublic?: Maybe<Scalars['Boolean']>;
    key: Key;
    name?: Maybe<Scalars['String']>;
    samples: Array<Track>;
    userId?: Maybe<Scalars['ID']>;
    version?: Maybe<Scalars['Int']>;
};

export type CreatorSessionInput = {
    bpm: Scalars['Int'];
    guid?: InputMaybe<Scalars['ID']>;
    isPublic?: InputMaybe<Scalars['Boolean']>;
    key?: InputMaybe<KeyInput>;
    name?: InputMaybe<Scalars['String']>;
    samples?: InputMaybe<Array<InputMaybe<TrackInput>>>;
    version: Scalars['Int'];
};

export type CreditBalancePayloadType = {
    __typename?: 'CreditBalancePayloadType';
    hdwav?: Maybe<Balance>;
    mp3?: Maybe<Balance>;
    sample?: Maybe<Balance>;
    wav?: Maybe<Balance>;
};

export type CurrentSubscription = {
    __typename?: 'CurrentSubscription';
    activationDate?: Maybe<Scalars['String']>;
    creationDate: Scalars['String'];
    endDate?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    isTrial: Scalars['Boolean'];
    nextPeriodStartDate?: Maybe<Scalars['String']>;
    plan: PlanFrequencyIdentifier;
    segment?: Maybe<Scalars['String']>;
    serviceOffer: ServiceOfferKind;
    status: SubscriptionStatusEnum;
};

export type Descriptor = {
    __typename?: 'Descriptor';
    code?: Maybe<Scalars['String']>;
};

export type Filter = {
    __typename?: 'Filter';
    code: Scalars['String'];
};

export type FilterCategories = {
    __typename?: 'FilterCategories';
    genres?: Maybe<Array<FilterFamily>>;
    instruments?: Maybe<Array<FilterFamily>>;
    sampleTypes?: Maybe<Array<Filter>>;
    sfxs?: Maybe<Array<FilterFamily>>;
    tags?: Maybe<Array<FilterWithLabel>>;
};

export type FilterFamily = {
    __typename?: 'FilterFamily';
    code: Scalars['String'];
    subFilters?: Maybe<Array<Filter>>;
};

export type FilterMeta = {
    __typename?: 'FilterMeta';
    name?: Maybe<Scalars['String']>;
    value?: Maybe<Scalars['Int']>;
};

export type FilterWithLabel = {
    __typename?: 'FilterWithLabel';
    code: Scalars['String'];
    label: Scalars['String'];
};

export type Filters = {
    __typename?: 'Filters';
    descriptorFilters?: Maybe<Array<Maybe<FilterMeta>>>;
    genreFilters?: Maybe<Array<Maybe<FilterMeta>>>;
    instrumentFilters?: Maybe<Array<Maybe<FilterMeta>>>;
    sampleTypeFilters?: Maybe<Array<Maybe<FilterMeta>>>;
    sfxFilters?: Maybe<Array<Maybe<FilterMeta>>>;
    tagFilters?: Maybe<Array<Maybe<FilterMeta>>>;
};

export type Genre = {
    __typename?: 'Genre';
    code?: Maybe<Scalars['String']>;
    subGenres?: Maybe<Array<Maybe<SubItems>>>;
};

export type GenreInput = {
    code?: InputMaybe<Scalars['String']>;
    subGenres?: InputMaybe<Array<InputMaybe<GenreInput>>>;
};

export type HeroBanner = {
    __typename?: 'HeroBanner';
    byline?: Maybe<Scalars['String']>;
    contentType: HeroBannerContentType;
    customArtworkUrl?: Maybe<Scalars['String']>;
    customDemoUrl?: Maybe<Scalars['String']>;
    customTags?: Maybe<Array<Maybe<Scalars['String']>>>;
    description?: Maybe<Scalars['String']>;
    entity?: Maybe<HeroBannerEntityType>;
    genres?: Maybe<Array<Maybe<Genre>>>;
    guid: Scalars['String'];
    instruments?: Maybe<Array<Maybe<Instrument>>>;
    labelPacks?: Maybe<Array<Maybe<Pack>>>;
    title?: Maybe<Scalars['String']>;
};

export enum HeroBannerContentType {
    Collection = 'Collection',
    Custom = 'Custom',
    Label = 'Label',
    Pack = 'Pack',
}

export type HeroBannerEntityType = Label | Pack | Repack;

export enum HomePageEnum {
    Chromatic = 'Chromatic',
    Dashboard = 'Dashboard',
    Distribution = 'Distribution',
    Mastering = 'Mastering',
    Network = 'Network',
    Plugins = 'Plugins',
    Projects = 'Projects',
    Samples = 'Samples',
    Sessions = 'Sessions',
}

export type Instrument = {
    __typename?: 'Instrument';
    code?: Maybe<Scalars['String']>;
    isFamily?: Maybe<Scalars['Boolean']>;
    subInstruments?: Maybe<Array<Maybe<SubItems>>>;
};

export type InstrumentInput = {
    code?: InputMaybe<Scalars['String']>;
    isFamily?: InputMaybe<Scalars['Boolean']>;
    subInstruments?: InputMaybe<Array<InputMaybe<InstrumentInput>>>;
};

export enum Interval {
    Monthly = 'Monthly',
    Yearly = 'Yearly',
}

export type Key = {
    __typename?: 'Key';
    quality: KeyQuality;
    root: KeyRootApi;
    sign: KeySign;
};

export type KeyInput = {
    quality?: InputMaybe<KeyQuality>;
    root?: InputMaybe<KeyRootApi>;
    sign?: InputMaybe<KeySign>;
};

export enum KeyQuality {
    Major = 'major',
    Minor = 'minor',
}

export enum KeyRootApi {
    A = 'A',
    ASharpBFlat = 'ASharpBFlat',
    B = 'B',
    C = 'C',
    CSharpDFlat = 'CSharpDFlat',
    D = 'D',
    DSharpEFlat = 'DSharpEFlat',
    E = 'E',
    F = 'F',
    FSharpGFlat = 'FSharpGFlat',
    G = 'G',
    GSharpAFlat = 'GSharpAFlat',
}

export enum KeySign {
    Flat = 'Flat',
    Sharp = 'Sharp',
}

export type Label = {
    __typename?: 'Label';
    artworkUrl?: Maybe<Scalars['String']>;
    coverUrl?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    guid: Scalars['ID'];
    isExclusive: Scalars['Boolean'];
    name?: Maybe<Scalars['String']>;
    packCount?: Maybe<Scalars['Int']>;
    sampleCount?: Maybe<Scalars['Int']>;
    /** @deprecated use seoByPath query */
    seoMetadata?: Maybe<SeoMetadata>;
    slug?: Maybe<Scalars['String']>;
};

export type LabelsChart = {
    __typename?: 'LabelsChart';
    items: Array<Maybe<LabelsChartItem>>;
};

export type LabelsChartItem = {
    __typename?: 'LabelsChartItem';
    artistName?: Maybe<Scalars['String']>;
    artworkUrl: Scalars['String'];
    currentRank: Scalars['Int'];
    guid: Scalars['ID'];
    isNew: Scalars['Boolean'];
    lastRank?: Maybe<Scalars['Int']>;
    name: Scalars['String'];
    slug: Scalars['String'];
    subItemsCount?: Maybe<Scalars['Int']>;
};

export enum LegalAgreementKindEnum {
    PrivacyPolicy = 'PrivacyPolicy',
    TermsOfService = 'TermsOfService',
}

export enum Locale {
    De = 'de',
    En = 'en',
    Es = 'es',
    Fr = 'fr',
    It = 'it',
    Ja = 'ja',
    Pt = 'pt',
}

export type MerchZone = {
    __typename?: 'MerchZone';
    contentGroups: Array<ContentGroup>;
};

export type MetaPage = {
    __typename?: 'MetaPage';
    filters?: Maybe<Filters>;
    totalPackRecords?: Maybe<Scalars['Int']>;
    totalRecords?: Maybe<Scalars['Int']>;
    totalRepackRecords?: Maybe<Scalars['Int']>;
    totalSampleRecords?: Maybe<Scalars['Int']>;
};

export enum MusicGenre {
    Acoustic = 'Acoustic',
    Afrobeat = 'Afrobeat',
    Americana = 'Americana',
    Blues = 'Blues',
    Chill = 'Chill',
    Choral = 'Choral',
    Classical = 'Classical',
    Country = 'Country',
    Dubstep = 'Dubstep',
    Edm = 'Edm',
    ElectricPop = 'Electric_pop',
    Electronic = 'Electronic',
    Folk = 'Folk',
    Funk = 'Funk',
    Gospel = 'Gospel',
    HeavyMetal = 'Heavy_metal',
    HeavyRock = 'Heavy_rock',
    HipHop = 'Hip_hop',
    House = 'House',
    IndiePop = 'Indie_pop',
    Jazz = 'Jazz',
    Latin = 'Latin',
    Pop = 'Pop',
    PopRock = 'Pop_rock',
    Reggae = 'Reggae',
    Reggaeton = 'Reggaeton',
    Rnb = 'Rnb',
    Rock = 'Rock',
    SingerSongwriter = 'Singer_songwriter',
    SoftPop = 'Soft_pop',
    Soul = 'Soul',
    Soundtrack = 'Soundtrack',
    Techno = 'Techno',
    Trance = 'Trance',
    Trap = 'Trap',
    Tropical = 'Tropical',
    World = 'World',
}

export type Mutation = {
    __typename?: 'Mutation';
    acceptUserAgreements?: Maybe<Scalars['Boolean']>;
    activateTrialSubscription: SubscriptionsAndAllowedFeatures;
    addSamplesToUserCollection?: Maybe<UserCollection>;
    createUserCollection?: Maybe<UserCollection>;
    createUserRecentSearch?: Maybe<Scalars['String']>;
    deleteUserCollection?: Maybe<Scalars['String']>;
    deleteUserRecentSearch?: Maybe<Scalars['String']>;
    editUserCollection?: Maybe<Scalars['String']>;
    putMilestones: Array<Scalars['String']>;
    removeSamplesFromUserCollection?: Maybe<UserCollection>;
    storeCreatorSession?: Maybe<CreatorSession>;
    updatePackFavoritedState?: Maybe<Pack>;
    updateRepackFavoritedState?: Maybe<Repack>;
    updateSampleFavoritedState?: Maybe<Sample>;
};

export type MutationAcceptUserAgreementsArgs = {
    agreements: AcceptUserAgreementsInput;
};

export type MutationAddSamplesToUserCollectionArgs = {
    collectionGuid?: InputMaybe<Scalars['ID']>;
    sampleIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type MutationCreateUserCollectionArgs = {
    name?: InputMaybe<Scalars['String']>;
    sampleCount?: InputMaybe<Scalars['Int']>;
};

export type MutationCreateUserRecentSearchArgs = {
    filterSlug: Scalars['String'];
    filterType: UserSearchFilter;
};

export type MutationDeleteUserCollectionArgs = {
    collectionGuid?: InputMaybe<Scalars['ID']>;
};

export type MutationDeleteUserRecentSearchArgs = {
    filterSlug: Scalars['String'];
    filterType: UserSearchFilter;
};

export type MutationEditUserCollectionArgs = {
    collectionGuid?: InputMaybe<Scalars['ID']>;
    name?: InputMaybe<Scalars['String']>;
};

export type MutationPutMilestonesArgs = {
    milestones: Array<Scalars['String']>;
};

export type MutationRemoveSamplesFromUserCollectionArgs = {
    collectionGuid?: InputMaybe<Scalars['ID']>;
    sampleIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type MutationStoreCreatorSessionArgs = {
    session?: InputMaybe<CreatorSessionInput>;
};

export type MutationUpdatePackFavoritedStateArgs = {
    pack: PackFavoritedInput;
};

export type MutationUpdateRepackFavoritedStateArgs = {
    repack: RepackFavoritedInput;
};

export type MutationUpdateSampleFavoritedStateArgs = {
    sample: SampleFavoritedInput;
};

export type NextPage = {
    __typename?: 'NextPage';
    skip?: Maybe<Scalars['Int']>;
};

export enum OccupationEnum {
    AR = 'A_R',
    Agent = 'Agent',
    Artist = 'Artist',
    ArtistManager = 'Artist_manager',
    Bassist = 'Bassist',
    Beatmaker = 'Beatmaker',
    Blogger = 'Blogger',
    Composer = 'Composer',
    Copywriter = 'Copywriter',
    Dj = 'DJ',
    Designer = 'Designer',
    Drummer = 'Drummer',
    Educator = 'Educator',
    Engineer = 'Engineer',
    EventProgrammer = 'Event_programmer',
    GearManufacturer = 'Gear_manufacturer',
    GraphicDesigner = 'Graphic_designer',
    Guitarist = 'Guitarist',
    Influencer = 'Influencer',
    Instrumentalist = 'Instrumentalist',
    Journalist = 'Journalist',
    Keyboardist = 'Keyboardist',
    LabelOwner = 'Label_owner',
    Lyricist = 'Lyricist',
    MediaFigure = 'Media_figure',
    Moderator = 'Moderator',
    MultiInstrumentalist = 'Multi_instrumentalist',
    MusicDirector = 'Music_director',
    Musician = 'Musician',
    Photographer = 'Photographer',
    Pianist = 'Pianist',
    PodcastHost = 'Podcast_host',
    Producer = 'Producer',
    Promoter = 'Promoter',
    Publicist = 'Publicist',
    Publisher = 'Publisher',
    RadioHost = 'Radio_host',
    Singer = 'Singer',
    Songwriter = 'Songwriter',
    SoundDesign = 'Sound_design',
    StudioOwner = 'Studio_owner',
    Videographer = 'Videographer',
    Vlogger = 'Vlogger',
    WebDesigner = 'Web_designer',
    YouTuber = 'YouTuber',
}

export type OverduePayment = {
    __typename?: 'OverduePayment';
    serviceOffer: ServiceOfferKind;
};

export type Pack = {
    __typename?: 'Pack';
    artist?: Maybe<Artist>;
    artworkUrl?: Maybe<Scalars['String']>;
    backgroundColor?: Maybe<Scalars['String']>;
    creatorSession?: Maybe<Scalars['ID']>;
    description?: Maybe<Scalars['String']>;
    foregroundColor?: Maybe<Scalars['String']>;
    genres?: Maybe<Array<Maybe<Genre>>>;
    guid: Scalars['ID'];
    /** @deprecated Use guid */
    id?: Maybe<Scalars['Int']>;
    isDownloaded?: Maybe<Scalars['Boolean']>;
    isExclusive: Scalars['Boolean'];
    isFavorited?: Maybe<Scalars['Boolean']>;
    isFree: Scalars['Boolean'];
    label?: Maybe<Label>;
    mainGenre?: Maybe<Genre>;
    name?: Maybe<Scalars['String']>;
    packState: PackState;
    premiumCodes?: Maybe<Array<Maybe<PremiumCodes>>>;
    sampleCount?: Maybe<Scalars['Int']>;
    /** @deprecated use seoByPath query */
    seoMetadata?: Maybe<SeoMetadata>;
    sfxs?: Maybe<Array<Maybe<Sfx>>>;
    slug?: Maybe<Scalars['String']>;
    type: PackType;
};

export type PackFavoritedInput = {
    guid: Scalars['ID'];
    isFavorited?: InputMaybe<Scalars['Boolean']>;
};

export enum PackState {
    Draft = 'Draft',
    PublicationFailed = 'PublicationFailed',
    Published = 'Published',
    Publishing = 'Publishing',
    TakenDown = 'TakenDown',
}

export enum PackType {
    Pack = 'Pack',
    Repack = 'Repack',
}

export type PackagesMeta = {
    __typename?: 'PackagesMeta';
    url?: Maybe<Scalars['String']>;
    version?: Maybe<Scalars['String']>;
};

export type PacksChart = {
    __typename?: 'PacksChart';
    items: Array<Maybe<PacksChartItem>>;
};

export type PacksChartItem = {
    __typename?: 'PacksChartItem';
    artistName?: Maybe<Scalars['String']>;
    artworkUrl: Scalars['String'];
    currentRank: Scalars['Int'];
    guid: Scalars['ID'];
    isNew: Scalars['Boolean'];
    lastRank?: Maybe<Scalars['Int']>;
    name: Scalars['String'];
    slug: Scalars['String'];
    subItemsCount?: Maybe<Scalars['Int']>;
};

export type PacksOrRepacks = Pack | Repack;

export type PaginatedArtists = {
    __typename?: 'PaginatedArtists';
    data?: Maybe<Array<Maybe<Artist>>>;
    meta?: Maybe<MetaPage>;
    next?: Maybe<NextPage>;
};

export type PaginatedLabels = {
    __typename?: 'PaginatedLabels';
    data?: Maybe<Array<Maybe<Label>>>;
    meta?: Maybe<MetaPage>;
    next?: Maybe<NextPage>;
};

export type PaginatedPacks = {
    __typename?: 'PaginatedPacks';
    data?: Maybe<Array<Maybe<Pack>>>;
    meta?: Maybe<MetaPage>;
    next?: Maybe<NextPage>;
};

export type PaginatedPacksOrRepacks = {
    __typename?: 'PaginatedPacksOrRepacks';
    data?: Maybe<Array<Maybe<PacksOrRepacks>>>;
    meta?: Maybe<MetaPage>;
    next?: Maybe<NextPage>;
};

export type PaginatedRepacks = {
    __typename?: 'PaginatedRepacks';
    data?: Maybe<Array<Maybe<Repack>>>;
    meta?: Maybe<MetaPage>;
    next?: Maybe<NextPage>;
};

export type PaginatedSamples = {
    __typename?: 'PaginatedSamples';
    data?: Maybe<Array<Maybe<Sample>>>;
    meta?: Maybe<MetaPage>;
    next?: Maybe<NextPage>;
};

export type PaginatedUserCollections = {
    __typename?: 'PaginatedUserCollections';
    data?: Maybe<Array<Maybe<UserCollection>>>;
    meta?: Maybe<MetaPage>;
    next?: Maybe<NextPage>;
};

export type PlanFrequencyIdentifier = {
    __typename?: 'PlanFrequencyIdentifier';
    code: Scalars['String'];
    frequency?: Maybe<Interval>;
};

export type PluginPackages = {
    __typename?: 'PluginPackages';
    macOS?: Maybe<PackagesMeta>;
    windows?: Maybe<PackagesMeta>;
};

export enum PremiumCodes {
    LandrStudio = 'landr_studio',
    SamplesPro = 'samples_pro',
    SamplesTier1 = 'samples_tier1',
    SamplesTier2 = 'samples_tier2',
    SamplesTier3 = 'samples_tier3',
}

export type PrismicBackgroundImage = {
    __typename?: 'PrismicBackgroundImage';
    alt?: Maybe<Scalars['String']>;
    copyright?: Maybe<Scalars['String']>;
    dimensions: PrismicImageDimensions;
    regular: PrismicImage;
    url: Scalars['String'];
};

export type PrismicBanner = {
    __typename?: 'PrismicBanner';
    first_publication_date: Scalars['Date'];
    id: Scalars['ID'];
    isBroken?: Maybe<Scalars['Boolean']>;
    lang: Scalars['String'];
    last_publication_date: Scalars['Date'];
    link_type: Scalars['String'];
    slug: Scalars['String'];
    tags: Array<Maybe<Scalars['String']>>;
    type: Scalars['String'];
};

export type PrismicBannerData =
    | PrismicSampleAuthBannerData
    | PrismicSampleBannerData
    | PrismicSampleBannerGroupData;

export type PrismicDocument = {
    __typename?: 'PrismicDocument';
    alternate_languages: Array<Maybe<AlternateLanguage>>;
    data: PrismicBannerData;
    first_publication_date: Scalars['String'];
    href: Scalars['String'];
    id: Scalars['ID'];
    lang: Scalars['String'];
    last_publication_date: Scalars['String'];
    slugs: Array<Maybe<Scalars['String']>>;
    tags: Array<Maybe<Scalars['String']>>;
    type: Scalars['String'];
    uid?: Maybe<Scalars['String']>;
};

export type PrismicImage = {
    __typename?: 'PrismicImage';
    alt?: Maybe<Scalars['String']>;
    copyright?: Maybe<Scalars['String']>;
    dimensions: PrismicImageDimensions;
    url: Scalars['String'];
};

export type PrismicImageDimensions = {
    __typename?: 'PrismicImageDimensions';
    height: Scalars['Int'];
    width: Scalars['Int'];
};

export type PrismicSampleAuthBannerCtaLink = {
    __typename?: 'PrismicSampleAuthBannerCtaLink';
    authenticated_cta_link: Array<PrismicTextField>;
    guest_cta_link: Array<PrismicTextField>;
};

export type PrismicSampleAuthBannerCtaText = {
    __typename?: 'PrismicSampleAuthBannerCtaText';
    authenticated_cta_text: Array<PrismicTextField>;
    guest_cta_text: Array<PrismicTextField>;
};

export type PrismicSampleAuthBannerData = {
    __typename?: 'PrismicSampleAuthBannerData';
    background_image: PrismicBackgroundImage;
    banner_title: Array<PrismicTextField>;
    cta_link: Array<PrismicSampleAuthBannerCtaLink>;
    cta_text: Array<PrismicSampleAuthBannerCtaText>;
    description: Array<PrismicSampleAuthBannerDescription>;
    title: Array<PrismicSampleAuthBannerTitle>;
};

export type PrismicSampleAuthBannerDescription = {
    __typename?: 'PrismicSampleAuthBannerDescription';
    authenticated_description: Array<PrismicTextField>;
    guest_description: Array<PrismicTextField>;
};

export type PrismicSampleAuthBannerTitle = {
    __typename?: 'PrismicSampleAuthBannerTitle';
    authenticated_title: Array<PrismicTextField>;
    guest_title: Array<PrismicTextField>;
};

export type PrismicSampleBannerData = {
    __typename?: 'PrismicSampleBannerData';
    background_color?: Maybe<Scalars['String']>;
    background_image: PrismicBackgroundImage;
    banner_title: Array<PrismicTextField>;
    cta_path: Array<PrismicTextField>;
    cta_text: Array<PrismicTextField>;
    description: Array<PrismicTextField>;
    title: Array<PrismicTextField>;
};

export type PrismicSampleBannerGroupData = {
    __typename?: 'PrismicSampleBannerGroupData';
    banner_a: PrismicBanner;
    banner_b: PrismicBanner;
    banner_c: PrismicBanner;
    banner_d: PrismicBanner;
    banner_e: PrismicBanner;
    title: Array<PrismicTextField>;
};

export type PrismicTextField = {
    __typename?: 'PrismicTextField';
    spans: Array<Maybe<Scalars['String']>>;
    text: Scalars['String'];
    type: Scalars['String'];
};

export type PromotionalCard = {
    __typename?: 'PromotionalCard';
    backgroundColor: Scalars['String'];
    ctaText: Scalars['String'];
    ctaUrl: Scalars['String'];
    description: Scalars['String'];
    id: Scalars['ID'];
    imageHoverUrl?: Maybe<Scalars['String']>;
    imageUrl: Scalars['String'];
    isPrioritized: Scalars['Boolean'];
    title: Scalars['String'];
    type: PromotionalCardType;
};

export enum PromotionalCardType {
    Creator = 'creator',
    None = 'none',
    Plugin = 'plugin',
    QuickPicks = 'quick_picks',
}

export enum ProviderType {
    Aggregator = 'AGGREGATOR',
    Artist = 'ARTIST',
    Label = 'LABEL',
}

export type PurchasableCreatorSample = {
    gainDb?: InputMaybe<Scalars['Float']>;
    id?: InputMaybe<Scalars['String']>;
    semitoneShift?: InputMaybe<Scalars['Float']>;
    timeFactor?: InputMaybe<Scalars['Float']>;
};

export type PurchasableCreatorSession = {
    async?: InputMaybe<Scalars['Boolean']>;
    samples?: InputMaybe<Array<InputMaybe<PurchasableCreatorSample>>>;
    targetBpm?: InputMaybe<Scalars['Int']>;
    targetKey?: InputMaybe<Scalars['String']>;
    targetKeyQuality?: InputMaybe<Scalars['String']>;
    targetKeyRoot?: InputMaybe<Scalars['String']>;
};

export type PurchaseResponse = {
    __typename?: 'PurchaseResponse';
    url?: Maybe<Scalars['String']>;
};

export type Query = {
    __typename?: 'Query';
    accountBalanceSummary?: Maybe<AccountBalanceSummary>;
    artistBySlug?: Maybe<Artist>;
    bannerGroup: Array<PrismicDocument>;
    brandtypeBySlug?: Maybe<Brandtype>;
    chartsTopLabels?: Maybe<LabelsChart>;
    chartsTopLandrPacks?: Maybe<RepacksChart>;
    chartsTopPacks?: Maybe<PacksChart>;
    collectionCostPreview?: Maybe<CostPreviewResponse>;
    collectionPurchase?: Maybe<PurchaseResponse>;
    contentGroup?: Maybe<ContentGroup>;
    creatorMixdownPurchase?: Maybe<PurchaseResponse>;
    creatorSamplesCostPreview?: Maybe<CostPreviewResponse>;
    creatorSamplesPurchase?: Maybe<PurchaseResponse>;
    creatorSessionById?: Maybe<CreatorSession>;
    filters?: Maybe<FilterCategories>;
    /** @deprecated Use filters */
    genres?: Maybe<Array<Maybe<Genre>>>;
    getUserRecentSearch: UserRecentSearches;
    heroBanners?: Maybe<Array<Maybe<HeroBanner>>>;
    /** @deprecated Use filters */
    instruments?: Maybe<Array<Maybe<Instrument>>>;
    labelBySlug?: Maybe<Label>;
    /** @deprecated authenticated user should not load last session from the cloud */
    lastCreatorSession?: Maybe<CreatorSession>;
    merchZone?: Maybe<MerchZone>;
    milestones: Array<Scalars['String']>;
    pack?: Maybe<Pack>;
    packCostPreview?: Maybe<CostPreviewResponse>;
    packPurchase?: Maybe<PurchaseResponse>;
    packStream?: Maybe<StreamUrl>;
    paginatedArtistPacks?: Maybe<PaginatedPacks>;
    paginatedArtistSamples?: Maybe<PaginatedSamples>;
    paginatedArtists?: Maybe<PaginatedArtists>;
    /** @deprecated Use paginatedBrandtypeRepacks */
    paginatedBrandtypePacks?: Maybe<PaginatedPacks>;
    paginatedBrandtypeRepacks?: Maybe<PaginatedRepacks>;
    paginatedBrandtypeSamples?: Maybe<PaginatedSamples>;
    paginatedDownloadedPacksAndRepacksV2?: Maybe<PaginatedPacksOrRepacks>;
    paginatedDownloadedSamples?: Maybe<PaginatedSamples>;
    paginatedFavoritedPacksAndRepacksV2?: Maybe<PaginatedPacksOrRepacks>;
    paginatedFavoritedSamples?: Maybe<PaginatedSamples>;
    paginatedLabelPacks?: Maybe<PaginatedPacks>;
    paginatedLabelSamples?: Maybe<PaginatedSamples>;
    paginatedLabels?: Maybe<PaginatedLabels>;
    paginatedPackSamples?: Maybe<PaginatedSamples>;
    paginatedPacks?: Maybe<PaginatedPacks>;
    paginatedPacksAndRepacks?: Maybe<PaginatedPacksOrRepacks>;
    paginatedRecommendedSamples?: Maybe<PaginatedSamples>;
    paginatedRepackSamples?: Maybe<PaginatedSamples>;
    paginatedRepacks?: Maybe<PaginatedRepacks>;
    paginatedSamples?: Maybe<PaginatedSamples>;
    paginatedUserCollectionSamples?: Maybe<PaginatedSamples>;
    paginatedUserCollections?: Maybe<PaginatedUserCollections>;
    pluginPackages?: Maybe<PluginPackages>;
    promotionalCardsRepeater: Array<PromotionalCard>;
    repack?: Maybe<Repack>;
    repackCostPreview?: Maybe<CostPreviewResponse>;
    repackPurchase?: Maybe<PurchaseResponse>;
    repackStream?: Maybe<StreamUrl>;
    sample?: Maybe<Sample>;
    sampleStream?: Maybe<StreamUrl>;
    sampleWaveform?: Maybe<Waveform>;
    samplesCostPreview?: Maybe<CostPreviewResponse>;
    samplesPurchase?: Maybe<PurchaseResponse>;
    seoByPath?: Maybe<Seo>;
    /** @deprecated use seoByPath */
    seoByUrl?: Maybe<Seo>;
    /** @deprecated Use filters */
    sfxs?: Maybe<Array<Maybe<Sfx>>>;
    similarPacks?: Maybe<PaginatedPacks>;
    subscriptionsAndAllowedFeatures?: Maybe<SubscriptionsAndAllowedFeatures>;
    /** @deprecated Use filters */
    tags?: Maybe<Array<Maybe<Tag>>>;
    userAgreements?: Maybe<Agreements>;
    /** @deprecated Use samplesSubscriptionsAndAllowedFeatures */
    userAllAccess: Scalars['Boolean'];
    /** @deprecated Use samplesSubscriptionsAndAllowedFeatures */
    userAllSubscriptions?: Maybe<AllSubscriptions>;
    userCollection?: Maybe<UserCollection>;
    userCollections?: Maybe<Array<Maybe<UserCollection>>>;
    userCollectionsFeed?: Maybe<UserCollectionsFeed>;
    userCredits?: Maybe<UserCredits>;
    /** @deprecated Use samplesSubscriptionsAndAllowedFeatures */
    userHasSubscription: Scalars['Boolean'];
    userProfile?: Maybe<User>;
    /** @deprecated Use samplesSubscriptionsAndAllowedFeatures */
    userSamplesSubscription?: Maybe<CurrentSubscription>;
};

export type QueryArtistBySlugArgs = {
    slug?: InputMaybe<Scalars['String']>;
};

export type QueryBannerGroupArgs = {
    locale?: InputMaybe<Locale>;
};

export type QueryBrandtypeBySlugArgs = {
    slug?: InputMaybe<Scalars['String']>;
};

export type QueryCollectionCostPreviewArgs = {
    guid?: InputMaybe<Scalars['String']>;
};

export type QueryCollectionPurchaseArgs = {
    guid?: InputMaybe<Scalars['String']>;
    interactionSource?: InputMaybe<Scalars['String']>;
};

export type QueryContentGroupArgs = {
    guid: Scalars['ID'];
};

export type QueryCreatorMixdownPurchaseArgs = {
    interactionSource?: InputMaybe<Scalars['String']>;
    purchasableItem: PurchasableCreatorSession;
};

export type QueryCreatorSamplesCostPreviewArgs = {
    guid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryCreatorSamplesPurchaseArgs = {
    interactionSource?: InputMaybe<Scalars['String']>;
    purchasableItem: PurchasableCreatorSession;
};

export type QueryCreatorSessionByIdArgs = {
    sessionId?: InputMaybe<Scalars['ID']>;
};

export type QueryLabelBySlugArgs = {
    slug?: InputMaybe<Scalars['String']>;
};

export type QueryMerchZoneArgs = {
    slug: Scalars['ID'];
};

export type QueryPackArgs = {
    packSlugOrGuid: Scalars['ID'];
};

export type QueryPackCostPreviewArgs = {
    guid?: InputMaybe<Scalars['String']>;
};

export type QueryPackPurchaseArgs = {
    guid?: InputMaybe<Scalars['String']>;
    interactionSource?: InputMaybe<Scalars['String']>;
};

export type QueryPackStreamArgs = {
    packGuid: Scalars['ID'];
};

export type QueryPaginatedArtistPacksArgs = {
    artistGuid?: InputMaybe<Scalars['ID']>;
    offset?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    query?: InputMaybe<Scalars['String']>;
    take?: InputMaybe<Scalars['Int']>;
};

export type QueryPaginatedArtistSamplesArgs = {
    artistGuid?: InputMaybe<Scalars['ID']>;
    offset?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    query?: InputMaybe<Scalars['String']>;
    take?: InputMaybe<Scalars['Int']>;
};

export type QueryPaginatedArtistsArgs = {
    offset?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    query?: InputMaybe<Scalars['String']>;
    take?: InputMaybe<Scalars['Int']>;
};

export type QueryPaginatedBrandtypePacksArgs = {
    brandtypeGuid?: InputMaybe<Scalars['ID']>;
    offset?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    query?: InputMaybe<Scalars['String']>;
    take?: InputMaybe<Scalars['Int']>;
};

export type QueryPaginatedBrandtypeRepacksArgs = {
    brandtypeGuid?: InputMaybe<Scalars['ID']>;
    offset?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    query?: InputMaybe<Scalars['String']>;
    take?: InputMaybe<Scalars['Int']>;
};

export type QueryPaginatedBrandtypeSamplesArgs = {
    brandtypeGuid?: InputMaybe<Scalars['ID']>;
    offset?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    query?: InputMaybe<Scalars['String']>;
    take?: InputMaybe<Scalars['Int']>;
};

export type QueryPaginatedDownloadedPacksAndRepacksV2Args = {
    offset?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    query?: InputMaybe<Scalars['String']>;
    take?: InputMaybe<Scalars['Int']>;
};

export type QueryPaginatedDownloadedSamplesArgs = {
    offset?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    query?: InputMaybe<Scalars['String']>;
    take?: InputMaybe<Scalars['Int']>;
};

export type QueryPaginatedFavoritedPacksAndRepacksV2Args = {
    offset?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    query?: InputMaybe<Scalars['String']>;
    take?: InputMaybe<Scalars['Int']>;
};

export type QueryPaginatedFavoritedSamplesArgs = {
    offset?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    query?: InputMaybe<Scalars['String']>;
    take?: InputMaybe<Scalars['Int']>;
};

export type QueryPaginatedLabelPacksArgs = {
    labelGuid?: InputMaybe<Scalars['ID']>;
    offset?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    query?: InputMaybe<Scalars['String']>;
    take?: InputMaybe<Scalars['Int']>;
};

export type QueryPaginatedLabelSamplesArgs = {
    labelGuid?: InputMaybe<Scalars['ID']>;
    offset?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    query?: InputMaybe<Scalars['String']>;
    take?: InputMaybe<Scalars['Int']>;
};

export type QueryPaginatedLabelsArgs = {
    offset?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    query?: InputMaybe<Scalars['String']>;
    take?: InputMaybe<Scalars['Int']>;
};

export type QueryPaginatedPackSamplesArgs = {
    offset?: InputMaybe<Scalars['Int']>;
    packGuid?: InputMaybe<Scalars['ID']>;
    page?: InputMaybe<Scalars['Int']>;
    query?: InputMaybe<Scalars['String']>;
    take?: InputMaybe<Scalars['Int']>;
};

export type QueryPaginatedPacksArgs = {
    offset?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    query?: InputMaybe<Scalars['String']>;
    take?: InputMaybe<Scalars['Int']>;
};

export type QueryPaginatedPacksAndRepacksArgs = {
    offset?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    query?: InputMaybe<Scalars['String']>;
    take?: InputMaybe<Scalars['Int']>;
};

export type QueryPaginatedRecommendedSamplesArgs = {
    offset?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    query?: InputMaybe<Scalars['String']>;
    sampleGuid: Scalars['ID'];
    take?: InputMaybe<Scalars['Int']>;
};

export type QueryPaginatedRepackSamplesArgs = {
    offset?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    query?: InputMaybe<Scalars['String']>;
    repackGuid?: InputMaybe<Scalars['ID']>;
    take?: InputMaybe<Scalars['Int']>;
};

export type QueryPaginatedRepacksArgs = {
    offset?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    query?: InputMaybe<Scalars['String']>;
    subType?: InputMaybe<RepackSubType>;
    take?: InputMaybe<Scalars['Int']>;
};

export type QueryPaginatedSamplesArgs = {
    offset?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    query?: InputMaybe<Scalars['String']>;
    take?: InputMaybe<Scalars['Int']>;
};

export type QueryPaginatedUserCollectionSamplesArgs = {
    collectionGuid?: InputMaybe<Scalars['ID']>;
    offset?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    query?: InputMaybe<Scalars['String']>;
    take?: InputMaybe<Scalars['Int']>;
};

export type QueryPaginatedUserCollectionsArgs = {
    offset?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    query?: InputMaybe<Scalars['String']>;
    take?: InputMaybe<Scalars['Int']>;
};

export type QueryPromotionalCardsRepeaterArgs = {
    locale?: InputMaybe<Locale>;
};

export type QueryRepackArgs = {
    repackSlugOrGuid: Scalars['ID'];
};

export type QueryRepackCostPreviewArgs = {
    guid?: InputMaybe<Scalars['String']>;
};

export type QueryRepackPurchaseArgs = {
    guid?: InputMaybe<Scalars['String']>;
    interactionSource?: InputMaybe<Scalars['String']>;
};

export type QueryRepackStreamArgs = {
    repackGuid: Scalars['ID'];
};

export type QuerySampleArgs = {
    sampleGuid: Scalars['ID'];
};

export type QuerySampleStreamArgs = {
    sampleGuid: Scalars['ID'];
};

export type QuerySampleWaveformArgs = {
    sampleGuid: Scalars['ID'];
    waveformUrl: Scalars['String'];
};

export type QuerySamplesCostPreviewArgs = {
    guid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QuerySamplesPurchaseArgs = {
    guid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    interactionSource?: InputMaybe<Scalars['String']>;
};

export type QuerySeoByPathArgs = {
    pathname?: InputMaybe<Scalars['ID']>;
};

export type QuerySeoByUrlArgs = {
    url?: InputMaybe<Scalars['ID']>;
};

export type QuerySimilarPacksArgs = {
    packSlugOrGuid: Scalars['ID'];
};

export type QueryUserCollectionArgs = {
    collectionGuid?: InputMaybe<Scalars['ID']>;
};

export type QueryUserCollectionsArgs = {
    offset?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    query?: InputMaybe<Scalars['String']>;
    take?: InputMaybe<Scalars['Int']>;
};

export type QueryUserCollectionsFeedArgs = {
    offset?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    query?: InputMaybe<Scalars['String']>;
    take?: InputMaybe<Scalars['Int']>;
};

export type Repack = {
    __typename?: 'Repack';
    artist?: Maybe<Artist>;
    artworkUrl?: Maybe<Scalars['String']>;
    backgroundColor?: Maybe<Scalars['String']>;
    brandtype?: Maybe<Brandtype>;
    creatorSession?: Maybe<Scalars['ID']>;
    /** @deprecated Will be remove */
    dateCreated?: Maybe<Scalars['String']>;
    /** @deprecated Will be remove */
    datePublished?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    foregroundColor?: Maybe<Scalars['String']>;
    genres?: Maybe<Array<Maybe<Genre>>>;
    guid: Scalars['ID'];
    /** @deprecated Use guid */
    id?: Maybe<Scalars['Int']>;
    instrument?: Maybe<Instrument>;
    isDownloaded?: Maybe<Scalars['Boolean']>;
    isExclusive: Scalars['Boolean'];
    isFavorited?: Maybe<Scalars['Boolean']>;
    isFree: Scalars['Boolean'];
    label?: Maybe<Label>;
    mainGenre?: Maybe<Genre>;
    name?: Maybe<Scalars['String']>;
    packState: PackState;
    premiumCodes?: Maybe<Array<Maybe<PremiumCodes>>>;
    sampleCount?: Maybe<Scalars['Int']>;
    /** @deprecated use seoByPath query */
    seoMetadata?: Maybe<SeoMetadata>;
    sfxs?: Maybe<Array<Maybe<Sfx>>>;
    slug?: Maybe<Scalars['String']>;
    subType?: Maybe<RepackSubType>;
    type: PackType;
};

export type RepackFavoritedInput = {
    guid: Scalars['ID'];
    isFavorited: Scalars['Boolean'];
};

export enum RepackSubType {
    Chart = 'Chart',
    Collection = 'Collection',
    Creator = 'Creator',
}

export type RepacksChart = {
    __typename?: 'RepacksChart';
    items: Array<Maybe<PacksChartItem>>;
};

export type RepacksChartItem = {
    __typename?: 'RepacksChartItem';
    artistName?: Maybe<Scalars['String']>;
    artworkUrl: Scalars['String'];
    currentRank: Scalars['Int'];
    guid: Scalars['ID'];
    isNew: Scalars['Boolean'];
    lastRank?: Maybe<Scalars['Int']>;
    name: Scalars['String'];
    slug: Scalars['String'];
    subItemsCount: Scalars['Int'];
};

export type Sample = {
    __typename?: 'Sample';
    bpm?: Maybe<Scalars['Int']>;
    credits?: Maybe<Scalars['Int']>;
    /** @deprecated Use tags */
    descriptors?: Maybe<Array<Maybe<Descriptor>>>;
    duration?: Maybe<Scalars['Float']>;
    guid: Scalars['ID'];
    /** @deprecated Use guid */
    id?: Maybe<Scalars['ID']>;
    instruments?: Maybe<Array<Maybe<Instrument>>>;
    isDownloaded: Scalars['Boolean'];
    isFavorited: Scalars['Boolean'];
    isRecommendationFiller?: Maybe<Scalars['Boolean']>;
    key?: Maybe<SampleKey>;
    name?: Maybe<Scalars['String']>;
    pack?: Maybe<Pack>;
    sampleState: SampleState;
    sampleType?: Maybe<SampleType>;
    sfxs?: Maybe<Array<Maybe<Sfx>>>;
    tags?: Maybe<Array<Maybe<Tag>>>;
    waveformUrl?: Maybe<Scalars['String']>;
};

export type SampleFavoritedInput = {
    guid: Scalars['ID'];
    isFavorited?: InputMaybe<Scalars['Boolean']>;
};

export type SampleKey = {
    __typename?: 'SampleKey';
    code?: Maybe<Scalars['String']>;
    quality?: Maybe<KeyQuality>;
    root?: Maybe<KeyRootApi>;
    sanitizedCode?: Maybe<Scalars['String']>;
};

export type SampleKeyInput = {
    code?: InputMaybe<Scalars['String']>;
    quality?: InputMaybe<KeyQuality>;
    root?: InputMaybe<KeyRootApi>;
    sanitizedCode?: InputMaybe<Scalars['String']>;
};

export enum SampleState {
    Available = 'Available',
    TakenDown = 'TakenDown',
}

export enum SampleType {
    Loop = 'loop',
    OneShot = 'one_shot',
}

export type SampleTypeFilter = {
    __typename?: 'SampleTypeFilter';
    code?: Maybe<Scalars['String']>;
};

export type Seo = {
    __typename?: 'Seo';
    byline?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    header?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
    url?: Maybe<Scalars['String']>;
};

export type SeoMetadata = {
    __typename?: 'SeoMetadata';
    description?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
};

export type SeoMetadataInput = {
    description?: InputMaybe<Scalars['String']>;
    title?: InputMaybe<Scalars['String']>;
};

export enum ServiceOfferKind {
    Bundles = 'Bundles',
    Chromatic = 'Chromatic',
    Distribution = 'Distribution',
    Mastering = 'Mastering',
    MasteringAndDistribution = 'MasteringAndDistribution',
    Samples = 'Samples',
    Sessions = 'Sessions',
    Video = 'Video',
}

export enum ServiceStatusEnum {
    Active = 'Active',
    Cancelled = 'Cancelled',
    Draft = 'Draft',
    Hold = 'Hold',
    Suspended = 'Suspended',
}

export type Sfx = {
    __typename?: 'Sfx';
    code?: Maybe<Scalars['String']>;
    subSfxs?: Maybe<Array<Maybe<SubItems>>>;
};

export type StreamUrl = {
    __typename?: 'StreamUrl';
    url?: Maybe<Scalars['String']>;
};

export type SubItems = {
    __typename?: 'SubItems';
    code?: Maybe<Scalars['String']>;
};

export type SubscriptionPlanProductDetails = {
    __typename?: 'SubscriptionPlanProductDetails';
    code: Scalars['String'];
    currentQuantity: Scalars['Float'];
    endDate?: Maybe<Scalars['String']>;
    isUnlimited: Scalars['Boolean'];
    maxQuantity?: Maybe<Scalars['Float']>;
    numberOfCredits?: Maybe<Scalars['Int']>;
    status: SubscriptionProductStatus;
};

export enum SubscriptionProductStatus {
    Active = 'Active',
    Expired = 'Expired',
}

export enum SubscriptionStatusEnum {
    Active = 'Active',
    Cancelled = 'Cancelled',
    Draft = 'Draft',
    PendingPlanChange = 'PendingPlanChange',
    Suspended = 'Suspended',
}

export type SubscriptionsAndAllowedFeatures = {
    __typename?: 'SubscriptionsAndAllowedFeatures';
    hasSamplesPlan: Scalars['Boolean'];
    hasStudioPlan: Scalars['Boolean'];
    hasSubscription: Scalars['Boolean'];
    samplePaidContentAccess: AllowedFeature;
    upsellingAllowed: Scalars['Boolean'];
};

export type Tag = {
    __typename?: 'Tag';
    code?: Maybe<Scalars['String']>;
    displayName?: Maybe<Scalars['String']>;
};

export type Track = {
    __typename?: 'Track';
    bpm?: Maybe<Scalars['Int']>;
    bpmMultiplier: BpmMultiplier;
    gain: Scalars['Int'];
    guid: Scalars['ID'];
    pitchTranspose: Scalars['Int'];
};

export type TrackInput = {
    bpm?: InputMaybe<Scalars['Int']>;
    bpmMultiplier?: InputMaybe<BpmMultiplier>;
    gain?: InputMaybe<Scalars['Int']>;
    guid: Scalars['ID'];
    pitchTranspose?: InputMaybe<Scalars['Int']>;
};

export type UnallowedReason = {
    __typename?: 'UnallowedReason';
    reason: Scalars['String'];
    unallowedByPlan?: Maybe<Scalars['String']>;
};

export type User = {
    __typename?: 'User';
    country?: Maybe<Scalars['String']>;
    creationTime: Scalars['String'];
    displayName?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    experience?: Maybe<Scalars['String']>;
    firstname?: Maybe<Scalars['String']>;
    hasSeenArchiveNotification: Scalars['Boolean'];
    homePage: HomePageEnum;
    isArchived: Scalars['Boolean'];
    lastname?: Maybe<Scalars['String']>;
    musicGenres?: Maybe<Array<Maybe<MusicGenre>>>;
    permalink?: Maybe<Scalars['String']>;
    preferredCulture?: Maybe<Scalars['String']>;
    primaryOccupation?: Maybe<OccupationEnum>;
    profilePicture72Url?: Maybe<Scalars['String']>;
    profilePicture360Url?: Maybe<Scalars['String']>;
    secondaryOccupations?: Maybe<Array<Maybe<OccupationEnum>>>;
    signupCountry?: Maybe<Scalars['String']>;
    signupCountryCode?: Maybe<Scalars['String']>;
    signupState?: Maybe<Scalars['String']>;
    userId: Scalars['String'];
};

export type UserCollection = {
    __typename?: 'UserCollection';
    artworkUrl?: Maybe<Scalars['String']>;
    /** @deprecated Will be removed */
    dateCreated?: Maybe<Scalars['String']>;
    guid?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
    sampleCount?: Maybe<Scalars['Int']>;
    /** @deprecated user collection do not have seoMetadata */
    seoMetadata?: Maybe<SeoMetadata>;
    slug?: Maybe<Scalars['String']>;
    userId?: Maybe<Scalars['ID']>;
};

export type UserCollectionsFeed = {
    __typename?: 'UserCollectionsFeed';
    feed?: Maybe<Array<Maybe<UserCollection>>>;
    meta?: Maybe<MetaPage>;
};

export type UserCollectionsFeedFeedArgs = {
    offset?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    query?: InputMaybe<Scalars['String']>;
    take?: InputMaybe<Scalars['Int']>;
};

export type UserCredits = {
    __typename?: 'UserCredits';
    remaining?: Maybe<Scalars['Int']>;
};

export type UserRecentSearch = {
    __typename?: 'UserRecentSearch';
    filterSlug: Scalars['String'];
    searchFilter: UserSearchFilter;
};

export type UserRecentSearches = {
    __typename?: 'UserRecentSearches';
    recentSearch: Array<UserRecentSearch>;
};

export enum UserSearchFilter {
    Descriptors = 'Descriptors',
    Genres = 'Genres',
    Instruments = 'Instruments',
    Sfxs = 'Sfxs',
    SuggestedLabel = 'SuggestedLabel',
    SuggestedPack = 'SuggestedPack',
    Tags = 'Tags',
    Textsearch = 'Textsearch',
}

export type Waveform = {
    __typename?: 'Waveform';
    guid: Scalars['ID'];
    waveform: Array<Maybe<Scalars['Float']>>;
};

export type ArtistBaseFragmentFragment = {
    __typename?: 'Artist';
    guid: string;
    slug?: string | undefined;
    name?: string | undefined;
    isExclusive: boolean;
};

export type ArtistExcerptFragmentFragment = {
    __typename?: 'Artist';
    artworkUrl?: string | undefined;
    guid: string;
    slug?: string | undefined;
    name?: string | undefined;
    isExclusive: boolean;
};

export type ArtistDetailFragmentFragment = {
    __typename?: 'Artist';
    description?: string | undefined;
    artworkUrl?: string | undefined;
    portraitUrl?: string | undefined;
    coverUrl?: string | undefined;
    isExclusive: boolean;
    sampleCount?: number | undefined;
    packCount?: number | undefined;
    guid: string;
    slug?: string | undefined;
    name?: string | undefined;
};

export type ArtistBySlugQueryVariables = Exact<{
    slug?: InputMaybe<Scalars['String']>;
}>;

export type ArtistBySlugQuery = {
    __typename?: 'Query';
    artistBySlug?:
        | {
              __typename?: 'Artist';
              description?: string | undefined;
              artworkUrl?: string | undefined;
              portraitUrl?: string | undefined;
              coverUrl?: string | undefined;
              isExclusive: boolean;
              sampleCount?: number | undefined;
              packCount?: number | undefined;
              guid: string;
              slug?: string | undefined;
              name?: string | undefined;
          }
        | undefined;
};

export type PaginatedArtistsQueryVariables = Exact<{
    take?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    query?: InputMaybe<Scalars['String']>;
    offset?: InputMaybe<Scalars['Int']>;
}>;

export type PaginatedArtistsQuery = {
    __typename?: 'Query';
    paginatedArtists?:
        | {
              __typename?: 'PaginatedArtists';
              data?:
                  | Array<
                        | {
                              __typename?: 'Artist';
                              artworkUrl?: string | undefined;
                              guid: string;
                              slug?: string | undefined;
                              name?: string | undefined;
                              isExclusive: boolean;
                          }
                        | undefined
                    >
                  | undefined;
              meta?:
                  | {
                        __typename?: 'MetaPage';
                        totalRecords?: number | undefined;
                        totalPackRecords?: number | undefined;
                    }
                  | undefined;
              next?:
                  | { __typename?: 'NextPage'; skip?: number | undefined }
                  | undefined;
          }
        | undefined;
};

export type PaginatedArtistSamplesQueryVariables = Exact<{
    artistGuid?: InputMaybe<Scalars['ID']>;
    take?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    query?: InputMaybe<Scalars['String']>;
    offset?: InputMaybe<Scalars['Int']>;
}>;

export type PaginatedArtistSamplesQuery = {
    __typename?: 'Query';
    paginatedArtistSamples?:
        | {
              __typename?: 'PaginatedSamples';
              data?:
                  | Array<
                        | {
                              __typename?: 'Sample';
                              guid: string;
                              name?: string | undefined;
                              bpm?: number | undefined;
                              credits?: number | undefined;
                              duration?: number | undefined;
                              isDownloaded: boolean;
                              isFavorited: boolean;
                              isRecommendationFiller?: boolean | undefined;
                              sampleState: SampleState;
                              sampleType?: SampleType | undefined;
                              waveformUrl?: string | undefined;
                              instruments?:
                                  | Array<
                                        | {
                                              __typename?: 'Instrument';
                                              code?: string | undefined;
                                              isFamily?: boolean | undefined;
                                          }
                                        | undefined
                                    >
                                  | undefined;
                              key?:
                                  | {
                                        __typename?: 'SampleKey';
                                        code?: string | undefined;
                                        root?: KeyRootApi | undefined;
                                        quality?: KeyQuality | undefined;
                                    }
                                  | undefined;
                              tags?:
                                  | Array<
                                        | {
                                              __typename?: 'Tag';
                                              code?: string | undefined;
                                              displayName?: string | undefined;
                                          }
                                        | undefined
                                    >
                                  | undefined;
                              sfxs?:
                                  | Array<
                                        | {
                                              __typename?: 'Sfx';
                                              code?: string | undefined;
                                          }
                                        | undefined
                                    >
                                  | undefined;
                              pack?:
                                  | {
                                        __typename?: 'Pack';
                                        artworkUrl?: string | undefined;
                                        guid: string;
                                        isExclusive: boolean;
                                        isFree: boolean;
                                        name?: string | undefined;
                                        packState: PackState;
                                        slug?: string | undefined;
                                        type: PackType;
                                        artist?:
                                            | {
                                                  __typename?: 'Artist';
                                                  guid: string;
                                                  slug?: string | undefined;
                                                  name?: string | undefined;
                                                  isExclusive: boolean;
                                              }
                                            | undefined;
                                        label?:
                                            | {
                                                  __typename?: 'Label';
                                                  guid: string;
                                                  name?: string | undefined;
                                                  slug?: string | undefined;
                                                  isExclusive: boolean;
                                              }
                                            | undefined;
                                    }
                                  | undefined;
                          }
                        | undefined
                    >
                  | undefined;
              meta?:
                  | {
                        __typename?: 'MetaPage';
                        totalRecords?: number | undefined;
                        totalSampleRecords?: number | undefined;
                        totalPackRecords?: number | undefined;
                        filters?:
                            | {
                                  __typename?: 'Filters';
                                  sampleTypeFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  instrumentFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  genreFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  sfxFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  tagFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                              }
                            | undefined;
                    }
                  | undefined;
              next?:
                  | { __typename?: 'NextPage'; skip?: number | undefined }
                  | undefined;
          }
        | undefined;
};

export type PaginatedArtistPacksQueryVariables = Exact<{
    artistGuid?: InputMaybe<Scalars['ID']>;
    take?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    query?: InputMaybe<Scalars['String']>;
    offset?: InputMaybe<Scalars['Int']>;
}>;

export type PaginatedArtistPacksQuery = {
    __typename?: 'Query';
    paginatedArtistPacks?:
        | {
              __typename?: 'PaginatedPacks';
              data?:
                  | Array<
                        | {
                              __typename?: 'Pack';
                              isDownloaded?: boolean | undefined;
                              isFavorited?: boolean | undefined;
                              artworkUrl?: string | undefined;
                              guid: string;
                              isExclusive: boolean;
                              isFree: boolean;
                              name?: string | undefined;
                              packState: PackState;
                              slug?: string | undefined;
                              type: PackType;
                              artist?:
                                  | {
                                        __typename?: 'Artist';
                                        guid: string;
                                        slug?: string | undefined;
                                        name?: string | undefined;
                                        isExclusive: boolean;
                                    }
                                  | undefined;
                              label?:
                                  | {
                                        __typename?: 'Label';
                                        guid: string;
                                        name?: string | undefined;
                                        slug?: string | undefined;
                                        isExclusive: boolean;
                                    }
                                  | undefined;
                          }
                        | undefined
                    >
                  | undefined;
              meta?:
                  | {
                        __typename?: 'MetaPage';
                        totalRecords?: number | undefined;
                        totalSampleRecords?: number | undefined;
                        totalPackRecords?: number | undefined;
                        filters?:
                            | {
                                  __typename?: 'Filters';
                                  sampleTypeFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  instrumentFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  genreFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  sfxFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  tagFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                              }
                            | undefined;
                    }
                  | undefined;
              next?:
                  | { __typename?: 'NextPage'; skip?: number | undefined }
                  | undefined;
          }
        | undefined;
};

export type BrandtypeExcerptFragmentFragment = {
    __typename?: 'Brandtype';
    guid: string;
    name?: string | undefined;
    slug?: string | undefined;
};

export type BrandtypeDetailFragmentFragment = {
    __typename?: 'Brandtype';
    description?: string | undefined;
    sampleCount?: number | undefined;
    packCount?: number | undefined;
    guid: string;
    name?: string | undefined;
    slug?: string | undefined;
};

export type PaginatedBrandtypeSamplesQueryVariables = Exact<{
    brandtypeGuid?: InputMaybe<Scalars['ID']>;
    take?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    query?: InputMaybe<Scalars['String']>;
    offset?: InputMaybe<Scalars['Int']>;
}>;

export type PaginatedBrandtypeSamplesQuery = {
    __typename?: 'Query';
    paginatedBrandtypeSamples?:
        | {
              __typename?: 'PaginatedSamples';
              data?:
                  | Array<
                        | {
                              __typename?: 'Sample';
                              guid: string;
                              name?: string | undefined;
                              bpm?: number | undefined;
                              credits?: number | undefined;
                              duration?: number | undefined;
                              isDownloaded: boolean;
                              isFavorited: boolean;
                              isRecommendationFiller?: boolean | undefined;
                              sampleState: SampleState;
                              sampleType?: SampleType | undefined;
                              waveformUrl?: string | undefined;
                              instruments?:
                                  | Array<
                                        | {
                                              __typename?: 'Instrument';
                                              code?: string | undefined;
                                              isFamily?: boolean | undefined;
                                          }
                                        | undefined
                                    >
                                  | undefined;
                              key?:
                                  | {
                                        __typename?: 'SampleKey';
                                        code?: string | undefined;
                                        root?: KeyRootApi | undefined;
                                        quality?: KeyQuality | undefined;
                                    }
                                  | undefined;
                              tags?:
                                  | Array<
                                        | {
                                              __typename?: 'Tag';
                                              code?: string | undefined;
                                              displayName?: string | undefined;
                                          }
                                        | undefined
                                    >
                                  | undefined;
                              sfxs?:
                                  | Array<
                                        | {
                                              __typename?: 'Sfx';
                                              code?: string | undefined;
                                          }
                                        | undefined
                                    >
                                  | undefined;
                              pack?:
                                  | {
                                        __typename?: 'Pack';
                                        artworkUrl?: string | undefined;
                                        guid: string;
                                        isExclusive: boolean;
                                        isFree: boolean;
                                        name?: string | undefined;
                                        packState: PackState;
                                        slug?: string | undefined;
                                        type: PackType;
                                        artist?:
                                            | {
                                                  __typename?: 'Artist';
                                                  guid: string;
                                                  slug?: string | undefined;
                                                  name?: string | undefined;
                                                  isExclusive: boolean;
                                              }
                                            | undefined;
                                        label?:
                                            | {
                                                  __typename?: 'Label';
                                                  guid: string;
                                                  name?: string | undefined;
                                                  slug?: string | undefined;
                                                  isExclusive: boolean;
                                              }
                                            | undefined;
                                    }
                                  | undefined;
                          }
                        | undefined
                    >
                  | undefined;
              meta?:
                  | {
                        __typename?: 'MetaPage';
                        totalRecords?: number | undefined;
                        totalSampleRecords?: number | undefined;
                        totalPackRecords?: number | undefined;
                        filters?:
                            | {
                                  __typename?: 'Filters';
                                  sampleTypeFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  instrumentFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  genreFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  sfxFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  tagFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                              }
                            | undefined;
                    }
                  | undefined;
              next?:
                  | { __typename?: 'NextPage'; skip?: number | undefined }
                  | undefined;
          }
        | undefined;
};

export type PaginatedBrandtypeRepacksQueryVariables = Exact<{
    brandtypeGuid?: InputMaybe<Scalars['ID']>;
    take?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    query?: InputMaybe<Scalars['String']>;
    offset?: InputMaybe<Scalars['Int']>;
}>;

export type PaginatedBrandtypeRepacksQuery = {
    __typename?: 'Query';
    paginatedBrandtypeRepacks?:
        | {
              __typename?: 'PaginatedRepacks';
              data?:
                  | Array<
                        | {
                              __typename?: 'Repack';
                              artworkUrl?: string | undefined;
                              creatorSession?: string | undefined;
                              guid: string;
                              isDownloaded?: boolean | undefined;
                              isExclusive: boolean;
                              isFavorited?: boolean | undefined;
                              isFree: boolean;
                              name?: string | undefined;
                              packState: PackState;
                              premiumCodes?:
                                  | Array<PremiumCodes | undefined>
                                  | undefined;
                              slug?: string | undefined;
                              subType?: RepackSubType | undefined;
                              type: PackType;
                          }
                        | undefined
                    >
                  | undefined;
              meta?:
                  | {
                        __typename?: 'MetaPage';
                        totalRecords?: number | undefined;
                        totalSampleRecords?: number | undefined;
                        totalPackRecords?: number | undefined;
                        filters?:
                            | {
                                  __typename?: 'Filters';
                                  sampleTypeFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  instrumentFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  genreFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  sfxFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  tagFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                              }
                            | undefined;
                    }
                  | undefined;
              next?:
                  | { __typename?: 'NextPage'; skip?: number | undefined }
                  | undefined;
          }
        | undefined;
};

export type BrandtypeBySlugQueryVariables = Exact<{
    slug?: InputMaybe<Scalars['String']>;
}>;

export type BrandtypeBySlugQuery = {
    __typename?: 'Query';
    brandtypeBySlug?:
        | {
              __typename?: 'Brandtype';
              description?: string | undefined;
              sampleCount?: number | undefined;
              packCount?: number | undefined;
              guid: string;
              name?: string | undefined;
              slug?: string | undefined;
          }
        | undefined;
};

export type ChartsTopLabelsQueryVariables = Exact<{ [key: string]: never }>;

export type ChartsTopLabelsQuery = {
    __typename?: 'Query';
    chartsTopLabels?:
        | {
              __typename?: 'LabelsChart';
              items: Array<
                  | {
                        __typename?: 'LabelsChartItem';
                        guid: string;
                        slug: string;
                        name: string;
                        artworkUrl: string;
                        lastRank?: number | undefined;
                        currentRank: number;
                        isNew: boolean;
                        subItemsCount?: number | undefined;
                        artistName?: string | undefined;
                    }
                  | undefined
              >;
          }
        | undefined;
};

export type ChartsTopPacksQueryVariables = Exact<{ [key: string]: never }>;

export type ChartsTopPacksQuery = {
    __typename?: 'Query';
    chartsTopPacks?:
        | {
              __typename?: 'PacksChart';
              items: Array<
                  | {
                        __typename?: 'PacksChartItem';
                        guid: string;
                        slug: string;
                        name: string;
                        artworkUrl: string;
                        lastRank?: number | undefined;
                        currentRank: number;
                        isNew: boolean;
                        subItemsCount?: number | undefined;
                        artistName?: string | undefined;
                    }
                  | undefined
              >;
          }
        | undefined;
};

export type ChartsTopLandrPacksQueryVariables = Exact<{ [key: string]: never }>;

export type ChartsTopLandrPacksQuery = {
    __typename?: 'Query';
    chartsTopLandrPacks?:
        | {
              __typename?: 'RepacksChart';
              items: Array<
                  | {
                        __typename?: 'PacksChartItem';
                        guid: string;
                        slug: string;
                        name: string;
                        artworkUrl: string;
                        lastRank?: number | undefined;
                        currentRank: number;
                        isNew: boolean;
                        subItemsCount?: number | undefined;
                        artistName?: string | undefined;
                    }
                  | undefined
              >;
          }
        | undefined;
};

export type ContentGroupQueryVariables = Exact<{
    guid: Scalars['ID'];
}>;

export type ContentGroupQuery = {
    __typename?: 'Query';
    contentGroup?:
        | {
              __typename?: 'ContentGroup';
              guid: string;
              title: string;
              byline: string;
              target: ContentTarget;
              to?: string | undefined;
              contentLists: Array<
                  | {
                        __typename?: 'ContentList';
                        tabName: string;
                        items: Array<
                            | {
                                  __typename?: 'Pack';
                                  isDownloaded?: boolean | undefined;
                                  isFavorited?: boolean | undefined;
                                  artworkUrl?: string | undefined;
                                  guid: string;
                                  isExclusive: boolean;
                                  isFree: boolean;
                                  name?: string | undefined;
                                  packState: PackState;
                                  slug?: string | undefined;
                                  type: PackType;
                                  artist?:
                                      | {
                                            __typename?: 'Artist';
                                            guid: string;
                                            slug?: string | undefined;
                                            name?: string | undefined;
                                            isExclusive: boolean;
                                        }
                                      | undefined;
                                  label?:
                                      | {
                                            __typename?: 'Label';
                                            guid: string;
                                            name?: string | undefined;
                                            slug?: string | undefined;
                                            isExclusive: boolean;
                                        }
                                      | undefined;
                              }
                            | {
                                  __typename?: 'Repack';
                                  artworkUrl?: string | undefined;
                                  creatorSession?: string | undefined;
                                  guid: string;
                                  isDownloaded?: boolean | undefined;
                                  isExclusive: boolean;
                                  isFavorited?: boolean | undefined;
                                  isFree: boolean;
                                  name?: string | undefined;
                                  packState: PackState;
                                  premiumCodes?:
                                      | Array<PremiumCodes | undefined>
                                      | undefined;
                                  slug?: string | undefined;
                                  subType?: RepackSubType | undefined;
                                  type: PackType;
                              }
                            | undefined
                        >;
                    }
                  | undefined
              >;
          }
        | undefined;
};

export type CostFramentFragment = {
    __typename?: 'CostPreviewResponse';
    credits?: number | undefined;
    remainingCredits?: number | undefined;
    unavailabilityReason?: string | undefined;
};

export type SamplesCostPreviewQueryVariables = Exact<{
    guid?: InputMaybe<
        Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
    >;
}>;

export type SamplesCostPreviewQuery = {
    __typename?: 'Query';
    samplesCostPreview?:
        | {
              __typename?: 'CostPreviewResponse';
              credits?: number | undefined;
              remainingCredits?: number | undefined;
              unavailabilityReason?: string | undefined;
          }
        | undefined;
};

export type CreatorSamplesCostPreviewQueryVariables = Exact<{
    guid?: InputMaybe<
        Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
    >;
}>;

export type CreatorSamplesCostPreviewQuery = {
    __typename?: 'Query';
    creatorSamplesCostPreview?:
        | {
              __typename?: 'CostPreviewResponse';
              credits?: number | undefined;
              remainingCredits?: number | undefined;
              unavailabilityReason?: string | undefined;
          }
        | undefined;
};

export type PackCostPreviewQueryVariables = Exact<{
    guid?: InputMaybe<Scalars['String']>;
}>;

export type PackCostPreviewQuery = {
    __typename?: 'Query';
    packCostPreview?:
        | {
              __typename?: 'CostPreviewResponse';
              credits?: number | undefined;
              remainingCredits?: number | undefined;
              unavailabilityReason?: string | undefined;
          }
        | undefined;
};

export type RepackCostPreviewQueryVariables = Exact<{
    guid?: InputMaybe<Scalars['String']>;
}>;

export type RepackCostPreviewQuery = {
    __typename?: 'Query';
    repackCostPreview?:
        | {
              __typename?: 'CostPreviewResponse';
              credits?: number | undefined;
              remainingCredits?: number | undefined;
              unavailabilityReason?: string | undefined;
          }
        | undefined;
};

export type CollectionCostPreviewQueryVariables = Exact<{
    guid?: InputMaybe<Scalars['String']>;
}>;

export type CollectionCostPreviewQuery = {
    __typename?: 'Query';
    collectionCostPreview?:
        | {
              __typename?: 'CostPreviewResponse';
              credits?: number | undefined;
              remainingCredits?: number | undefined;
              unavailabilityReason?: string | undefined;
          }
        | undefined;
};

export type CreatorKeyFragmentFragment = {
    __typename?: 'Key';
    root: KeyRootApi;
    quality: KeyQuality;
    sign: KeySign;
};

export type TrackFragmentFragment = {
    __typename?: 'Track';
    guid: string;
    bpmMultiplier: BpmMultiplier;
    bpm?: number | undefined;
    gain: number;
    pitchTranspose: number;
};

export type CreatorSessionByIdQueryVariables = Exact<{
    sessionId: Scalars['ID'];
}>;

export type CreatorSessionByIdQuery = {
    __typename?: 'Query';
    creatorSessionById?:
        | {
              __typename?: 'CreatorSession';
              guid: string;
              bpm: number;
              key: {
                  __typename?: 'Key';
                  root: KeyRootApi;
                  quality: KeyQuality;
                  sign: KeySign;
              };
              samples: Array<{
                  __typename?: 'Track';
                  guid: string;
                  bpmMultiplier: BpmMultiplier;
                  bpm?: number | undefined;
                  gain: number;
                  pitchTranspose: number;
              }>;
          }
        | undefined;
};

export type StoreCreatorSessionMutationVariables = Exact<{
    session?: InputMaybe<CreatorSessionInput>;
}>;

export type StoreCreatorSessionMutation = {
    __typename?: 'Mutation';
    storeCreatorSession?:
        | { __typename?: 'CreatorSession'; guid: string }
        | undefined;
};

export type GenreFragmentFragment = {
    __typename?: 'Genre';
    code?: string | undefined;
};

export type InstrumentFragmentFragment = {
    __typename?: 'Instrument';
    code?: string | undefined;
    isFamily?: boolean | undefined;
};

export type SfxFragmentFragment = {
    __typename?: 'Sfx';
    code?: string | undefined;
};

export type TagFragmentFragment = {
    __typename?: 'Tag';
    code?: string | undefined;
    displayName?: string | undefined;
};

export type FilterFamilyFragmentFragment = {
    __typename?: 'FilterFamily';
    code: string;
    subFilters?: Array<{ __typename?: 'Filter'; code: string }> | undefined;
};

export type FilterWithLabelFragmentFragment = {
    __typename?: 'FilterWithLabel';
    code: string;
    label: string;
};

export type FiltersQueryVariables = Exact<{ [key: string]: never }>;

export type FiltersQuery = {
    __typename?: 'Query';
    filters?:
        | {
              __typename?: 'FilterCategories';
              instruments?:
                  | Array<{
                        __typename?: 'FilterFamily';
                        code: string;
                        subFilters?:
                            | Array<{ __typename?: 'Filter'; code: string }>
                            | undefined;
                    }>
                  | undefined;
              genres?:
                  | Array<{
                        __typename?: 'FilterFamily';
                        code: string;
                        subFilters?:
                            | Array<{ __typename?: 'Filter'; code: string }>
                            | undefined;
                    }>
                  | undefined;
              sfxs?:
                  | Array<{
                        __typename?: 'FilterFamily';
                        code: string;
                        subFilters?:
                            | Array<{ __typename?: 'Filter'; code: string }>
                            | undefined;
                    }>
                  | undefined;
              tags?:
                  | Array<{
                        __typename?: 'FilterWithLabel';
                        code: string;
                        label: string;
                    }>
                  | undefined;
              sampleTypes?:
                  | Array<{ __typename?: 'Filter'; code: string }>
                  | undefined;
          }
        | undefined;
};

export type HeroBannersQueryVariables = Exact<{ [key: string]: never }>;

export type HeroBannersQuery = {
    __typename?: 'Query';
    heroBanners?:
        | Array<
              | {
                    __typename?: 'HeroBanner';
                    guid: string;
                    contentType: HeroBannerContentType;
                    title?: string | undefined;
                    byline?: string | undefined;
                    description?: string | undefined;
                    customArtworkUrl?: string | undefined;
                    customDemoUrl?: string | undefined;
                    customTags?: Array<string | undefined> | undefined;
                    genres?:
                        | Array<
                              | {
                                    __typename?: 'Genre';
                                    code?: string | undefined;
                                }
                              | undefined
                          >
                        | undefined;
                    instruments?:
                        | Array<
                              | {
                                    __typename?: 'Instrument';
                                    code?: string | undefined;
                                }
                              | undefined
                          >
                        | undefined;
                    entity?:
                        | {
                              __typename?: 'Label';
                              guid: string;
                              name?: string | undefined;
                              slug?: string | undefined;
                              isExclusive: boolean;
                          }
                        | {
                              __typename?: 'Pack';
                              isDownloaded?: boolean | undefined;
                              isFavorited?: boolean | undefined;
                              artworkUrl?: string | undefined;
                              guid: string;
                              isExclusive: boolean;
                              isFree: boolean;
                              name?: string | undefined;
                              packState: PackState;
                              slug?: string | undefined;
                              type: PackType;
                              artist?:
                                  | {
                                        __typename?: 'Artist';
                                        guid: string;
                                        slug?: string | undefined;
                                        name?: string | undefined;
                                        isExclusive: boolean;
                                    }
                                  | undefined;
                              label?:
                                  | {
                                        __typename?: 'Label';
                                        guid: string;
                                        name?: string | undefined;
                                        slug?: string | undefined;
                                        isExclusive: boolean;
                                    }
                                  | undefined;
                          }
                        | {
                              __typename?: 'Repack';
                              artworkUrl?: string | undefined;
                              creatorSession?: string | undefined;
                              guid: string;
                              isDownloaded?: boolean | undefined;
                              isExclusive: boolean;
                              isFavorited?: boolean | undefined;
                              isFree: boolean;
                              name?: string | undefined;
                              packState: PackState;
                              premiumCodes?:
                                  | Array<PremiumCodes | undefined>
                                  | undefined;
                              slug?: string | undefined;
                              subType?: RepackSubType | undefined;
                              type: PackType;
                          }
                        | undefined;
                    labelPacks?:
                        | Array<
                              | {
                                    __typename?: 'Pack';
                                    isDownloaded?: boolean | undefined;
                                    isFavorited?: boolean | undefined;
                                    artworkUrl?: string | undefined;
                                    guid: string;
                                    isExclusive: boolean;
                                    isFree: boolean;
                                    name?: string | undefined;
                                    packState: PackState;
                                    slug?: string | undefined;
                                    type: PackType;
                                    artist?:
                                        | {
                                              __typename?: 'Artist';
                                              guid: string;
                                              slug?: string | undefined;
                                              name?: string | undefined;
                                              isExclusive: boolean;
                                          }
                                        | undefined;
                                    label?:
                                        | {
                                              __typename?: 'Label';
                                              guid: string;
                                              name?: string | undefined;
                                              slug?: string | undefined;
                                              isExclusive: boolean;
                                          }
                                        | undefined;
                                }
                              | undefined
                          >
                        | undefined;
                }
              | undefined
          >
        | undefined;
};

export type LabelBaseFragmentFragment = {
    __typename?: 'Label';
    guid: string;
    name?: string | undefined;
    slug?: string | undefined;
    isExclusive: boolean;
};

export type LabelExcerptFragmentFragment = {
    __typename?: 'Label';
    artworkUrl?: string | undefined;
    guid: string;
    name?: string | undefined;
    slug?: string | undefined;
    isExclusive: boolean;
};

export type LabelDetailFragmentFragment = {
    __typename?: 'Label';
    name?: string | undefined;
    description?: string | undefined;
    artworkUrl?: string | undefined;
    coverUrl?: string | undefined;
    isExclusive: boolean;
    sampleCount?: number | undefined;
    packCount?: number | undefined;
    guid: string;
    slug?: string | undefined;
};

export type PaginatedLabelsQueryVariables = Exact<{
    take?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    query?: InputMaybe<Scalars['String']>;
    offset?: InputMaybe<Scalars['Int']>;
}>;

export type PaginatedLabelsQuery = {
    __typename?: 'Query';
    paginatedLabels?:
        | {
              __typename?: 'PaginatedLabels';
              data?:
                  | Array<
                        | {
                              __typename?: 'Label';
                              artworkUrl?: string | undefined;
                              guid: string;
                              name?: string | undefined;
                              slug?: string | undefined;
                              isExclusive: boolean;
                          }
                        | undefined
                    >
                  | undefined;
              meta?:
                  | {
                        __typename?: 'MetaPage';
                        totalRecords?: number | undefined;
                        totalPackRecords?: number | undefined;
                    }
                  | undefined;
              next?:
                  | { __typename?: 'NextPage'; skip?: number | undefined }
                  | undefined;
          }
        | undefined;
};

export type PaginatedLabelSamplesQueryVariables = Exact<{
    labelGuid?: InputMaybe<Scalars['ID']>;
    take?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    query?: InputMaybe<Scalars['String']>;
    offset?: InputMaybe<Scalars['Int']>;
}>;

export type PaginatedLabelSamplesQuery = {
    __typename?: 'Query';
    paginatedLabelSamples?:
        | {
              __typename?: 'PaginatedSamples';
              data?:
                  | Array<
                        | {
                              __typename?: 'Sample';
                              guid: string;
                              name?: string | undefined;
                              bpm?: number | undefined;
                              credits?: number | undefined;
                              duration?: number | undefined;
                              isDownloaded: boolean;
                              isFavorited: boolean;
                              isRecommendationFiller?: boolean | undefined;
                              sampleState: SampleState;
                              sampleType?: SampleType | undefined;
                              waveformUrl?: string | undefined;
                              instruments?:
                                  | Array<
                                        | {
                                              __typename?: 'Instrument';
                                              code?: string | undefined;
                                              isFamily?: boolean | undefined;
                                          }
                                        | undefined
                                    >
                                  | undefined;
                              key?:
                                  | {
                                        __typename?: 'SampleKey';
                                        code?: string | undefined;
                                        root?: KeyRootApi | undefined;
                                        quality?: KeyQuality | undefined;
                                    }
                                  | undefined;
                              tags?:
                                  | Array<
                                        | {
                                              __typename?: 'Tag';
                                              code?: string | undefined;
                                              displayName?: string | undefined;
                                          }
                                        | undefined
                                    >
                                  | undefined;
                              sfxs?:
                                  | Array<
                                        | {
                                              __typename?: 'Sfx';
                                              code?: string | undefined;
                                          }
                                        | undefined
                                    >
                                  | undefined;
                              pack?:
                                  | {
                                        __typename?: 'Pack';
                                        artworkUrl?: string | undefined;
                                        guid: string;
                                        isExclusive: boolean;
                                        isFree: boolean;
                                        name?: string | undefined;
                                        packState: PackState;
                                        slug?: string | undefined;
                                        type: PackType;
                                        artist?:
                                            | {
                                                  __typename?: 'Artist';
                                                  guid: string;
                                                  slug?: string | undefined;
                                                  name?: string | undefined;
                                                  isExclusive: boolean;
                                              }
                                            | undefined;
                                        label?:
                                            | {
                                                  __typename?: 'Label';
                                                  guid: string;
                                                  name?: string | undefined;
                                                  slug?: string | undefined;
                                                  isExclusive: boolean;
                                              }
                                            | undefined;
                                    }
                                  | undefined;
                          }
                        | undefined
                    >
                  | undefined;
              meta?:
                  | {
                        __typename?: 'MetaPage';
                        totalRecords?: number | undefined;
                        totalSampleRecords?: number | undefined;
                        totalPackRecords?: number | undefined;
                        filters?:
                            | {
                                  __typename?: 'Filters';
                                  sampleTypeFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  instrumentFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  genreFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  sfxFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  tagFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                              }
                            | undefined;
                    }
                  | undefined;
              next?:
                  | { __typename?: 'NextPage'; skip?: number | undefined }
                  | undefined;
          }
        | undefined;
};

export type PaginatedLabelPacksQueryVariables = Exact<{
    labelGuid?: InputMaybe<Scalars['ID']>;
    take?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    query?: InputMaybe<Scalars['String']>;
    offset?: InputMaybe<Scalars['Int']>;
}>;

export type PaginatedLabelPacksQuery = {
    __typename?: 'Query';
    paginatedLabelPacks?:
        | {
              __typename?: 'PaginatedPacks';
              data?:
                  | Array<
                        | {
                              __typename?: 'Pack';
                              isDownloaded?: boolean | undefined;
                              isFavorited?: boolean | undefined;
                              artworkUrl?: string | undefined;
                              guid: string;
                              isExclusive: boolean;
                              isFree: boolean;
                              name?: string | undefined;
                              packState: PackState;
                              slug?: string | undefined;
                              type: PackType;
                              artist?:
                                  | {
                                        __typename?: 'Artist';
                                        guid: string;
                                        slug?: string | undefined;
                                        name?: string | undefined;
                                        isExclusive: boolean;
                                    }
                                  | undefined;
                              label?:
                                  | {
                                        __typename?: 'Label';
                                        guid: string;
                                        name?: string | undefined;
                                        slug?: string | undefined;
                                        isExclusive: boolean;
                                    }
                                  | undefined;
                          }
                        | undefined
                    >
                  | undefined;
              meta?:
                  | {
                        __typename?: 'MetaPage';
                        totalRecords?: number | undefined;
                        totalSampleRecords?: number | undefined;
                        totalPackRecords?: number | undefined;
                        filters?:
                            | {
                                  __typename?: 'Filters';
                                  sampleTypeFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  instrumentFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  genreFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  sfxFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  tagFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                              }
                            | undefined;
                    }
                  | undefined;
              next?:
                  | { __typename?: 'NextPage'; skip?: number | undefined }
                  | undefined;
          }
        | undefined;
};

export type LabelBySlugQueryVariables = Exact<{
    slug?: InputMaybe<Scalars['String']>;
}>;

export type LabelBySlugQuery = {
    __typename?: 'Query';
    labelBySlug?:
        | {
              __typename?: 'Label';
              name?: string | undefined;
              description?: string | undefined;
              artworkUrl?: string | undefined;
              coverUrl?: string | undefined;
              isExclusive: boolean;
              sampleCount?: number | undefined;
              packCount?: number | undefined;
              guid: string;
              slug?: string | undefined;
          }
        | undefined;
};

export type MerchZoneQueryVariables = Exact<{
    slug: Scalars['ID'];
}>;

export type MerchZoneQuery = {
    __typename?: 'Query';
    merchZone?:
        | {
              __typename?: 'MerchZone';
              contentGroups: Array<{
                  __typename?: 'ContentGroup';
                  guid: string;
                  title: string;
                  byline: string;
                  target: ContentTarget;
                  to?: string | undefined;
              }>;
          }
        | undefined;
};

export type MetaPageFilterFragmentFragment = {
    __typename?: 'MetaPage';
    filters?:
        | {
              __typename?: 'Filters';
              sampleTypeFilters?:
                  | Array<
                        | {
                              __typename?: 'FilterMeta';
                              name?: string | undefined;
                              value?: number | undefined;
                          }
                        | undefined
                    >
                  | undefined;
              instrumentFilters?:
                  | Array<
                        | {
                              __typename?: 'FilterMeta';
                              name?: string | undefined;
                              value?: number | undefined;
                          }
                        | undefined
                    >
                  | undefined;
              genreFilters?:
                  | Array<
                        | {
                              __typename?: 'FilterMeta';
                              name?: string | undefined;
                              value?: number | undefined;
                          }
                        | undefined
                    >
                  | undefined;
              sfxFilters?:
                  | Array<
                        | {
                              __typename?: 'FilterMeta';
                              name?: string | undefined;
                              value?: number | undefined;
                          }
                        | undefined
                    >
                  | undefined;
              tagFilters?:
                  | Array<
                        | {
                              __typename?: 'FilterMeta';
                              name?: string | undefined;
                              value?: number | undefined;
                          }
                        | undefined
                    >
                  | undefined;
          }
        | undefined;
};

export type MetaPageTotalFragmentFragment = {
    __typename?: 'MetaPage';
    totalRecords?: number | undefined;
    totalSampleRecords?: number | undefined;
    totalPackRecords?: number | undefined;
};

export type MilestonesQueryVariables = Exact<{ [key: string]: never }>;

export type MilestonesQuery = {
    __typename?: 'Query';
    milestones: Array<string>;
};

export type PutMilestonesMutationVariables = Exact<{
    milestones: Array<Scalars['String']> | Scalars['String'];
}>;

export type PutMilestonesMutation = {
    __typename?: 'Mutation';
    putMilestones: Array<string>;
};

export type PackBaseFragmentFragment = {
    __typename?: 'Pack';
    artworkUrl?: string | undefined;
    guid: string;
    isExclusive: boolean;
    isFree: boolean;
    name?: string | undefined;
    packState: PackState;
    slug?: string | undefined;
    type: PackType;
    artist?:
        | {
              __typename?: 'Artist';
              guid: string;
              slug?: string | undefined;
              name?: string | undefined;
              isExclusive: boolean;
          }
        | undefined;
    label?:
        | {
              __typename?: 'Label';
              guid: string;
              name?: string | undefined;
              slug?: string | undefined;
              isExclusive: boolean;
          }
        | undefined;
};

export type PackExcerptFragmentFragment = {
    __typename?: 'Pack';
    isDownloaded?: boolean | undefined;
    isFavorited?: boolean | undefined;
    artworkUrl?: string | undefined;
    guid: string;
    isExclusive: boolean;
    isFree: boolean;
    name?: string | undefined;
    packState: PackState;
    slug?: string | undefined;
    type: PackType;
    artist?:
        | {
              __typename?: 'Artist';
              guid: string;
              slug?: string | undefined;
              name?: string | undefined;
              isExclusive: boolean;
          }
        | undefined;
    label?:
        | {
              __typename?: 'Label';
              guid: string;
              name?: string | undefined;
              slug?: string | undefined;
              isExclusive: boolean;
          }
        | undefined;
};

export type PackDetailFragmentFragment = {
    __typename?: 'Pack';
    backgroundColor?: string | undefined;
    creatorSession?: string | undefined;
    description?: string | undefined;
    foregroundColor?: string | undefined;
    premiumCodes?: Array<PremiumCodes | undefined> | undefined;
    sampleCount?: number | undefined;
    isDownloaded?: boolean | undefined;
    isFavorited?: boolean | undefined;
    artworkUrl?: string | undefined;
    guid: string;
    isExclusive: boolean;
    isFree: boolean;
    name?: string | undefined;
    packState: PackState;
    slug?: string | undefined;
    type: PackType;
    genres?:
        | Array<{ __typename?: 'Genre'; code?: string | undefined } | undefined>
        | undefined;
    mainGenre?: { __typename?: 'Genre'; code?: string | undefined } | undefined;
    sfxs?:
        | Array<{ __typename?: 'Sfx'; code?: string | undefined } | undefined>
        | undefined;
    artist?:
        | {
              __typename?: 'Artist';
              guid: string;
              slug?: string | undefined;
              name?: string | undefined;
              isExclusive: boolean;
          }
        | undefined;
    label?:
        | {
              __typename?: 'Label';
              guid: string;
              name?: string | undefined;
              slug?: string | undefined;
              isExclusive: boolean;
          }
        | undefined;
};

export type PaginatedPackSamplesQueryVariables = Exact<{
    packGuid?: InputMaybe<Scalars['ID']>;
    take?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    query?: InputMaybe<Scalars['String']>;
    offset?: InputMaybe<Scalars['Int']>;
}>;

export type PaginatedPackSamplesQuery = {
    __typename?: 'Query';
    paginatedPackSamples?:
        | {
              __typename?: 'PaginatedSamples';
              data?:
                  | Array<
                        | {
                              __typename?: 'Sample';
                              guid: string;
                              name?: string | undefined;
                              bpm?: number | undefined;
                              credits?: number | undefined;
                              duration?: number | undefined;
                              isDownloaded: boolean;
                              isFavorited: boolean;
                              isRecommendationFiller?: boolean | undefined;
                              sampleState: SampleState;
                              sampleType?: SampleType | undefined;
                              waveformUrl?: string | undefined;
                              instruments?:
                                  | Array<
                                        | {
                                              __typename?: 'Instrument';
                                              code?: string | undefined;
                                              isFamily?: boolean | undefined;
                                          }
                                        | undefined
                                    >
                                  | undefined;
                              key?:
                                  | {
                                        __typename?: 'SampleKey';
                                        code?: string | undefined;
                                        root?: KeyRootApi | undefined;
                                        quality?: KeyQuality | undefined;
                                    }
                                  | undefined;
                              tags?:
                                  | Array<
                                        | {
                                              __typename?: 'Tag';
                                              code?: string | undefined;
                                              displayName?: string | undefined;
                                          }
                                        | undefined
                                    >
                                  | undefined;
                              sfxs?:
                                  | Array<
                                        | {
                                              __typename?: 'Sfx';
                                              code?: string | undefined;
                                          }
                                        | undefined
                                    >
                                  | undefined;
                              pack?:
                                  | {
                                        __typename?: 'Pack';
                                        artworkUrl?: string | undefined;
                                        guid: string;
                                        isExclusive: boolean;
                                        isFree: boolean;
                                        name?: string | undefined;
                                        packState: PackState;
                                        slug?: string | undefined;
                                        type: PackType;
                                        artist?:
                                            | {
                                                  __typename?: 'Artist';
                                                  guid: string;
                                                  slug?: string | undefined;
                                                  name?: string | undefined;
                                                  isExclusive: boolean;
                                              }
                                            | undefined;
                                        label?:
                                            | {
                                                  __typename?: 'Label';
                                                  guid: string;
                                                  name?: string | undefined;
                                                  slug?: string | undefined;
                                                  isExclusive: boolean;
                                              }
                                            | undefined;
                                    }
                                  | undefined;
                          }
                        | undefined
                    >
                  | undefined;
              meta?:
                  | {
                        __typename?: 'MetaPage';
                        totalRecords?: number | undefined;
                        totalSampleRecords?: number | undefined;
                        totalPackRecords?: number | undefined;
                        filters?:
                            | {
                                  __typename?: 'Filters';
                                  sampleTypeFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  instrumentFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  genreFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  sfxFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  tagFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                              }
                            | undefined;
                    }
                  | undefined;
              next?:
                  | { __typename?: 'NextPage'; skip?: number | undefined }
                  | undefined;
          }
        | undefined;
};

export type PackBySlugOrGuidQueryVariables = Exact<{
    packSlugOrGuid: Scalars['ID'];
}>;

export type PackBySlugOrGuidQuery = {
    __typename?: 'Query';
    pack?:
        | {
              __typename?: 'Pack';
              backgroundColor?: string | undefined;
              creatorSession?: string | undefined;
              description?: string | undefined;
              foregroundColor?: string | undefined;
              premiumCodes?: Array<PremiumCodes | undefined> | undefined;
              sampleCount?: number | undefined;
              isDownloaded?: boolean | undefined;
              isFavorited?: boolean | undefined;
              artworkUrl?: string | undefined;
              guid: string;
              isExclusive: boolean;
              isFree: boolean;
              name?: string | undefined;
              packState: PackState;
              slug?: string | undefined;
              type: PackType;
              genres?:
                  | Array<
                        | { __typename?: 'Genre'; code?: string | undefined }
                        | undefined
                    >
                  | undefined;
              mainGenre?:
                  | { __typename?: 'Genre'; code?: string | undefined }
                  | undefined;
              sfxs?:
                  | Array<
                        | { __typename?: 'Sfx'; code?: string | undefined }
                        | undefined
                    >
                  | undefined;
              artist?:
                  | {
                        __typename?: 'Artist';
                        guid: string;
                        slug?: string | undefined;
                        name?: string | undefined;
                        isExclusive: boolean;
                    }
                  | undefined;
              label?:
                  | {
                        __typename?: 'Label';
                        guid: string;
                        name?: string | undefined;
                        slug?: string | undefined;
                        isExclusive: boolean;
                    }
                  | undefined;
          }
        | undefined;
};

export type SimilarPacksQueryVariables = Exact<{
    packSlugOrGuid: Scalars['ID'];
}>;

export type SimilarPacksQuery = {
    __typename?: 'Query';
    similarPacks?:
        | {
              __typename?: 'PaginatedPacks';
              data?:
                  | Array<
                        | {
                              __typename?: 'Pack';
                              isDownloaded?: boolean | undefined;
                              isFavorited?: boolean | undefined;
                              artworkUrl?: string | undefined;
                              guid: string;
                              isExclusive: boolean;
                              isFree: boolean;
                              name?: string | undefined;
                              packState: PackState;
                              slug?: string | undefined;
                              type: PackType;
                              artist?:
                                  | {
                                        __typename?: 'Artist';
                                        guid: string;
                                        slug?: string | undefined;
                                        name?: string | undefined;
                                        isExclusive: boolean;
                                    }
                                  | undefined;
                              label?:
                                  | {
                                        __typename?: 'Label';
                                        guid: string;
                                        name?: string | undefined;
                                        slug?: string | undefined;
                                        isExclusive: boolean;
                                    }
                                  | undefined;
                          }
                        | undefined
                    >
                  | undefined;
          }
        | undefined;
};

export type PackIsDownloadedQueryVariables = Exact<{
    guid: Scalars['ID'];
}>;

export type PackIsDownloadedQuery = {
    __typename?: 'Query';
    pack?:
        | {
              __typename?: 'Pack';
              guid: string;
              isDownloaded?: boolean | undefined;
          }
        | undefined;
};

export type UpdatePackFavoritedStateMutationVariables = Exact<{
    pack: PackFavoritedInput;
}>;

export type UpdatePackFavoritedStateMutation = {
    __typename?: 'Mutation';
    updatePackFavoritedState?:
        | {
              __typename?: 'Pack';
              guid: string;
              isFavorited?: boolean | undefined;
          }
        | undefined;
};

export type PacksStreamQueryVariables = Exact<{
    packGuid: Scalars['ID'];
}>;

export type PacksStreamQuery = {
    __typename?: 'Query';
    packStream?:
        | { __typename?: 'StreamUrl'; url?: string | undefined }
        | undefined;
};

export type PaginatedPacksAndRepacksQueryVariables = Exact<{
    take?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    query?: InputMaybe<Scalars['String']>;
    offset?: InputMaybe<Scalars['Int']>;
}>;

export type PaginatedPacksAndRepacksQuery = {
    __typename?: 'Query';
    paginatedPacksAndRepacks?:
        | {
              __typename?: 'PaginatedPacksOrRepacks';
              data?:
                  | Array<
                        | {
                              __typename?: 'Pack';
                              isDownloaded?: boolean | undefined;
                              isFavorited?: boolean | undefined;
                              artworkUrl?: string | undefined;
                              guid: string;
                              isExclusive: boolean;
                              isFree: boolean;
                              name?: string | undefined;
                              packState: PackState;
                              slug?: string | undefined;
                              type: PackType;
                              artist?:
                                  | {
                                        __typename?: 'Artist';
                                        guid: string;
                                        slug?: string | undefined;
                                        name?: string | undefined;
                                        isExclusive: boolean;
                                    }
                                  | undefined;
                              label?:
                                  | {
                                        __typename?: 'Label';
                                        guid: string;
                                        name?: string | undefined;
                                        slug?: string | undefined;
                                        isExclusive: boolean;
                                    }
                                  | undefined;
                          }
                        | {
                              __typename?: 'Repack';
                              artworkUrl?: string | undefined;
                              creatorSession?: string | undefined;
                              guid: string;
                              isDownloaded?: boolean | undefined;
                              isExclusive: boolean;
                              isFavorited?: boolean | undefined;
                              isFree: boolean;
                              name?: string | undefined;
                              packState: PackState;
                              premiumCodes?:
                                  | Array<PremiumCodes | undefined>
                                  | undefined;
                              slug?: string | undefined;
                              subType?: RepackSubType | undefined;
                              type: PackType;
                          }
                        | undefined
                    >
                  | undefined;
              meta?:
                  | {
                        __typename?: 'MetaPage';
                        totalRecords?: number | undefined;
                        totalSampleRecords?: number | undefined;
                        totalPackRecords?: number | undefined;
                        filters?:
                            | {
                                  __typename?: 'Filters';
                                  sampleTypeFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  instrumentFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  genreFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  sfxFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  tagFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                              }
                            | undefined;
                    }
                  | undefined;
              next?:
                  | { __typename?: 'NextPage'; skip?: number | undefined }
                  | undefined;
          }
        | undefined;
};

export type PaginatedDownloadedPacksAndRepacksQueryVariables = Exact<{
    take?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    query?: InputMaybe<Scalars['String']>;
    offset?: InputMaybe<Scalars['Int']>;
}>;

export type PaginatedDownloadedPacksAndRepacksQuery = {
    __typename?: 'Query';
    paginatedDownloadedPacksAndRepacksV2?:
        | {
              __typename?: 'PaginatedPacksOrRepacks';
              data?:
                  | Array<
                        | {
                              __typename?: 'Pack';
                              isDownloaded?: boolean | undefined;
                              isFavorited?: boolean | undefined;
                              artworkUrl?: string | undefined;
                              guid: string;
                              isExclusive: boolean;
                              isFree: boolean;
                              name?: string | undefined;
                              packState: PackState;
                              slug?: string | undefined;
                              type: PackType;
                              artist?:
                                  | {
                                        __typename?: 'Artist';
                                        guid: string;
                                        slug?: string | undefined;
                                        name?: string | undefined;
                                        isExclusive: boolean;
                                    }
                                  | undefined;
                              label?:
                                  | {
                                        __typename?: 'Label';
                                        guid: string;
                                        name?: string | undefined;
                                        slug?: string | undefined;
                                        isExclusive: boolean;
                                    }
                                  | undefined;
                          }
                        | {
                              __typename?: 'Repack';
                              artworkUrl?: string | undefined;
                              creatorSession?: string | undefined;
                              guid: string;
                              isDownloaded?: boolean | undefined;
                              isExclusive: boolean;
                              isFavorited?: boolean | undefined;
                              isFree: boolean;
                              name?: string | undefined;
                              packState: PackState;
                              premiumCodes?:
                                  | Array<PremiumCodes | undefined>
                                  | undefined;
                              slug?: string | undefined;
                              subType?: RepackSubType | undefined;
                              type: PackType;
                          }
                        | undefined
                    >
                  | undefined;
              meta?:
                  | {
                        __typename?: 'MetaPage';
                        totalRecords?: number | undefined;
                        totalSampleRecords?: number | undefined;
                        totalPackRecords?: number | undefined;
                        filters?:
                            | {
                                  __typename?: 'Filters';
                                  sampleTypeFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  instrumentFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  genreFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  sfxFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  tagFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                              }
                            | undefined;
                    }
                  | undefined;
              next?:
                  | { __typename?: 'NextPage'; skip?: number | undefined }
                  | undefined;
          }
        | undefined;
};

export type PaginatedFavoritedPacksAndRepacksQueryVariables = Exact<{
    take?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    query?: InputMaybe<Scalars['String']>;
    offset?: InputMaybe<Scalars['Int']>;
}>;

export type PaginatedFavoritedPacksAndRepacksQuery = {
    __typename?: 'Query';
    paginatedFavoritedPacksAndRepacksV2?:
        | {
              __typename?: 'PaginatedPacksOrRepacks';
              data?:
                  | Array<
                        | {
                              __typename?: 'Pack';
                              isDownloaded?: boolean | undefined;
                              isFavorited?: boolean | undefined;
                              artworkUrl?: string | undefined;
                              guid: string;
                              isExclusive: boolean;
                              isFree: boolean;
                              name?: string | undefined;
                              packState: PackState;
                              slug?: string | undefined;
                              type: PackType;
                              artist?:
                                  | {
                                        __typename?: 'Artist';
                                        guid: string;
                                        slug?: string | undefined;
                                        name?: string | undefined;
                                        isExclusive: boolean;
                                    }
                                  | undefined;
                              label?:
                                  | {
                                        __typename?: 'Label';
                                        guid: string;
                                        name?: string | undefined;
                                        slug?: string | undefined;
                                        isExclusive: boolean;
                                    }
                                  | undefined;
                          }
                        | {
                              __typename?: 'Repack';
                              artworkUrl?: string | undefined;
                              creatorSession?: string | undefined;
                              guid: string;
                              isDownloaded?: boolean | undefined;
                              isExclusive: boolean;
                              isFavorited?: boolean | undefined;
                              isFree: boolean;
                              name?: string | undefined;
                              packState: PackState;
                              premiumCodes?:
                                  | Array<PremiumCodes | undefined>
                                  | undefined;
                              slug?: string | undefined;
                              subType?: RepackSubType | undefined;
                              type: PackType;
                          }
                        | undefined
                    >
                  | undefined;
              meta?:
                  | {
                        __typename?: 'MetaPage';
                        totalRecords?: number | undefined;
                        totalSampleRecords?: number | undefined;
                        totalPackRecords?: number | undefined;
                        filters?:
                            | {
                                  __typename?: 'Filters';
                                  sampleTypeFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  instrumentFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  genreFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  sfxFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  tagFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                              }
                            | undefined;
                    }
                  | undefined;
              next?:
                  | { __typename?: 'NextPage'; skip?: number | undefined }
                  | undefined;
          }
        | undefined;
};

export type PromotionalCardsRepeaterQueryVariables = Exact<{
    locale?: InputMaybe<Locale>;
}>;

export type PromotionalCardsRepeaterQuery = {
    __typename?: 'Query';
    promotionalCardsRepeater: Array<{
        __typename?: 'PromotionalCard';
        id: string;
        title: string;
        description: string;
        ctaText: string;
        ctaUrl: string;
        backgroundColor: string;
        imageUrl: string;
        imageHoverUrl?: string | undefined;
        isPrioritized: boolean;
        type: PromotionalCardType;
    }>;
};

export type SamplesPurchaseQueryVariables = Exact<{
    guid?: InputMaybe<
        Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
    >;
    interactionSource?: InputMaybe<Scalars['String']>;
}>;

export type SamplesPurchaseQuery = {
    __typename?: 'Query';
    samplesPurchase?:
        | { __typename?: 'PurchaseResponse'; url?: string | undefined }
        | undefined;
};

export type CreatorSamplesPurchaseQueryVariables = Exact<{
    purchasableItem: PurchasableCreatorSession;
    interactionSource?: InputMaybe<Scalars['String']>;
}>;

export type CreatorSamplesPurchaseQuery = {
    __typename?: 'Query';
    creatorSamplesPurchase?:
        | { __typename?: 'PurchaseResponse'; url?: string | undefined }
        | undefined;
};

export type CreatorMixdownPurchaseQueryVariables = Exact<{
    purchasableItem: PurchasableCreatorSession;
    interactionSource?: InputMaybe<Scalars['String']>;
}>;

export type CreatorMixdownPurchaseQuery = {
    __typename?: 'Query';
    creatorMixdownPurchase?:
        | { __typename?: 'PurchaseResponse'; url?: string | undefined }
        | undefined;
};

export type PackPurchaseQueryVariables = Exact<{
    guid?: InputMaybe<Scalars['String']>;
    interactionSource?: InputMaybe<Scalars['String']>;
}>;

export type PackPurchaseQuery = {
    __typename?: 'Query';
    packPurchase?:
        | { __typename?: 'PurchaseResponse'; url?: string | undefined }
        | undefined;
};

export type RepackPurchaseQueryVariables = Exact<{
    guid?: InputMaybe<Scalars['String']>;
    interactionSource?: InputMaybe<Scalars['String']>;
}>;

export type RepackPurchaseQuery = {
    __typename?: 'Query';
    repackPurchase?:
        | { __typename?: 'PurchaseResponse'; url?: string | undefined }
        | undefined;
};

export type CollectionPurchaseQueryVariables = Exact<{
    guid?: InputMaybe<Scalars['String']>;
    interactionSource?: InputMaybe<Scalars['String']>;
}>;

export type CollectionPurchaseQuery = {
    __typename?: 'Query';
    collectionPurchase?:
        | { __typename?: 'PurchaseResponse'; url?: string | undefined }
        | undefined;
};

export type RepackExcerptFragmentFragment = {
    __typename?: 'Repack';
    artworkUrl?: string | undefined;
    creatorSession?: string | undefined;
    guid: string;
    isDownloaded?: boolean | undefined;
    isExclusive: boolean;
    isFavorited?: boolean | undefined;
    isFree: boolean;
    name?: string | undefined;
    packState: PackState;
    premiumCodes?: Array<PremiumCodes | undefined> | undefined;
    slug?: string | undefined;
    subType?: RepackSubType | undefined;
    type: PackType;
};

export type RepackDetailFragmentFragment = {
    __typename?: 'Repack';
    backgroundColor?: string | undefined;
    creatorSession?: string | undefined;
    description?: string | undefined;
    foregroundColor?: string | undefined;
    sampleCount?: number | undefined;
    artworkUrl?: string | undefined;
    guid: string;
    isDownloaded?: boolean | undefined;
    isExclusive: boolean;
    isFavorited?: boolean | undefined;
    isFree: boolean;
    name?: string | undefined;
    packState: PackState;
    premiumCodes?: Array<PremiumCodes | undefined> | undefined;
    slug?: string | undefined;
    subType?: RepackSubType | undefined;
    type: PackType;
    instrument?:
        | { __typename?: 'Instrument'; code?: string | undefined }
        | undefined;
    mainGenre?: { __typename?: 'Genre'; code?: string | undefined } | undefined;
    genres?:
        | Array<{ __typename?: 'Genre'; code?: string | undefined } | undefined>
        | undefined;
    sfxs?:
        | Array<{ __typename?: 'Sfx'; code?: string | undefined } | undefined>
        | undefined;
    artist?:
        | {
              __typename?: 'Artist';
              guid: string;
              slug?: string | undefined;
              name?: string | undefined;
              isExclusive: boolean;
          }
        | undefined;
    label?:
        | {
              __typename?: 'Label';
              guid: string;
              name?: string | undefined;
              slug?: string | undefined;
              isExclusive: boolean;
          }
        | undefined;
    brandtype?:
        | {
              __typename?: 'Brandtype';
              guid: string;
              name?: string | undefined;
              slug?: string | undefined;
          }
        | undefined;
};

export type PaginatedRepacksQueryVariables = Exact<{
    take?: InputMaybe<Scalars['Int']>;
    subType?: InputMaybe<RepackSubType>;
    page?: InputMaybe<Scalars['Int']>;
    query?: InputMaybe<Scalars['String']>;
}>;

export type PaginatedRepacksQuery = {
    __typename?: 'Query';
    paginatedRepacks?:
        | {
              __typename?: 'PaginatedRepacks';
              data?:
                  | Array<
                        | {
                              __typename?: 'Repack';
                              artworkUrl?: string | undefined;
                              creatorSession?: string | undefined;
                              guid: string;
                              isDownloaded?: boolean | undefined;
                              isExclusive: boolean;
                              isFavorited?: boolean | undefined;
                              isFree: boolean;
                              name?: string | undefined;
                              packState: PackState;
                              premiumCodes?:
                                  | Array<PremiumCodes | undefined>
                                  | undefined;
                              slug?: string | undefined;
                              subType?: RepackSubType | undefined;
                              type: PackType;
                          }
                        | undefined
                    >
                  | undefined;
              meta?:
                  | {
                        __typename?: 'MetaPage';
                        totalRecords?: number | undefined;
                        totalSampleRecords?: number | undefined;
                        totalPackRecords?: number | undefined;
                        filters?:
                            | {
                                  __typename?: 'Filters';
                                  sampleTypeFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  instrumentFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  genreFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  sfxFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  tagFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                              }
                            | undefined;
                    }
                  | undefined;
          }
        | undefined;
};

export type RepackBySlugOrGuidQueryVariables = Exact<{
    repackSlugOrGuid: Scalars['ID'];
}>;

export type RepackBySlugOrGuidQuery = {
    __typename?: 'Query';
    repack?:
        | {
              __typename?: 'Repack';
              backgroundColor?: string | undefined;
              creatorSession?: string | undefined;
              description?: string | undefined;
              foregroundColor?: string | undefined;
              sampleCount?: number | undefined;
              artworkUrl?: string | undefined;
              guid: string;
              isDownloaded?: boolean | undefined;
              isExclusive: boolean;
              isFavorited?: boolean | undefined;
              isFree: boolean;
              name?: string | undefined;
              packState: PackState;
              premiumCodes?: Array<PremiumCodes | undefined> | undefined;
              slug?: string | undefined;
              subType?: RepackSubType | undefined;
              type: PackType;
              instrument?:
                  | { __typename?: 'Instrument'; code?: string | undefined }
                  | undefined;
              mainGenre?:
                  | { __typename?: 'Genre'; code?: string | undefined }
                  | undefined;
              genres?:
                  | Array<
                        | { __typename?: 'Genre'; code?: string | undefined }
                        | undefined
                    >
                  | undefined;
              sfxs?:
                  | Array<
                        | { __typename?: 'Sfx'; code?: string | undefined }
                        | undefined
                    >
                  | undefined;
              artist?:
                  | {
                        __typename?: 'Artist';
                        guid: string;
                        slug?: string | undefined;
                        name?: string | undefined;
                        isExclusive: boolean;
                    }
                  | undefined;
              label?:
                  | {
                        __typename?: 'Label';
                        guid: string;
                        name?: string | undefined;
                        slug?: string | undefined;
                        isExclusive: boolean;
                    }
                  | undefined;
              brandtype?:
                  | {
                        __typename?: 'Brandtype';
                        guid: string;
                        name?: string | undefined;
                        slug?: string | undefined;
                    }
                  | undefined;
          }
        | undefined;
};

export type RepackIsDownloadedQueryVariables = Exact<{
    guid: Scalars['ID'];
}>;

export type RepackIsDownloadedQuery = {
    __typename?: 'Query';
    repack?:
        | {
              __typename?: 'Repack';
              guid: string;
              isDownloaded?: boolean | undefined;
          }
        | undefined;
};

export type PaginatedRepackSamplesQueryVariables = Exact<{
    repackGuid?: InputMaybe<Scalars['ID']>;
    take?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    query?: InputMaybe<Scalars['String']>;
    offset?: InputMaybe<Scalars['Int']>;
}>;

export type PaginatedRepackSamplesQuery = {
    __typename?: 'Query';
    paginatedRepackSamples?:
        | {
              __typename?: 'PaginatedSamples';
              data?:
                  | Array<
                        | {
                              __typename?: 'Sample';
                              guid: string;
                              name?: string | undefined;
                              bpm?: number | undefined;
                              credits?: number | undefined;
                              duration?: number | undefined;
                              isDownloaded: boolean;
                              isFavorited: boolean;
                              isRecommendationFiller?: boolean | undefined;
                              sampleState: SampleState;
                              sampleType?: SampleType | undefined;
                              waveformUrl?: string | undefined;
                              instruments?:
                                  | Array<
                                        | {
                                              __typename?: 'Instrument';
                                              code?: string | undefined;
                                              isFamily?: boolean | undefined;
                                          }
                                        | undefined
                                    >
                                  | undefined;
                              key?:
                                  | {
                                        __typename?: 'SampleKey';
                                        code?: string | undefined;
                                        root?: KeyRootApi | undefined;
                                        quality?: KeyQuality | undefined;
                                    }
                                  | undefined;
                              tags?:
                                  | Array<
                                        | {
                                              __typename?: 'Tag';
                                              code?: string | undefined;
                                              displayName?: string | undefined;
                                          }
                                        | undefined
                                    >
                                  | undefined;
                              sfxs?:
                                  | Array<
                                        | {
                                              __typename?: 'Sfx';
                                              code?: string | undefined;
                                          }
                                        | undefined
                                    >
                                  | undefined;
                              pack?:
                                  | {
                                        __typename?: 'Pack';
                                        artworkUrl?: string | undefined;
                                        guid: string;
                                        isExclusive: boolean;
                                        isFree: boolean;
                                        name?: string | undefined;
                                        packState: PackState;
                                        slug?: string | undefined;
                                        type: PackType;
                                        artist?:
                                            | {
                                                  __typename?: 'Artist';
                                                  guid: string;
                                                  slug?: string | undefined;
                                                  name?: string | undefined;
                                                  isExclusive: boolean;
                                              }
                                            | undefined;
                                        label?:
                                            | {
                                                  __typename?: 'Label';
                                                  guid: string;
                                                  name?: string | undefined;
                                                  slug?: string | undefined;
                                                  isExclusive: boolean;
                                              }
                                            | undefined;
                                    }
                                  | undefined;
                          }
                        | undefined
                    >
                  | undefined;
              meta?:
                  | {
                        __typename?: 'MetaPage';
                        totalRecords?: number | undefined;
                        totalSampleRecords?: number | undefined;
                        totalPackRecords?: number | undefined;
                        filters?:
                            | {
                                  __typename?: 'Filters';
                                  sampleTypeFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  instrumentFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  genreFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  sfxFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  tagFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                              }
                            | undefined;
                    }
                  | undefined;
              next?:
                  | { __typename?: 'NextPage'; skip?: number | undefined }
                  | undefined;
          }
        | undefined;
};

export type UpdateRepackFavoritedStateMutationVariables = Exact<{
    repack: RepackFavoritedInput;
}>;

export type UpdateRepackFavoritedStateMutation = {
    __typename?: 'Mutation';
    updateRepackFavoritedState?:
        | {
              __typename?: 'Repack';
              guid: string;
              isFavorited?: boolean | undefined;
          }
        | undefined;
};

export type RepacksStreamQueryVariables = Exact<{
    repackGuid: Scalars['ID'];
}>;

export type RepacksStreamQuery = {
    __typename?: 'Query';
    repackStream?:
        | { __typename?: 'StreamUrl'; url?: string | undefined }
        | undefined;
};

export type SampleKeyFragmentFragment = {
    __typename?: 'SampleKey';
    code?: string | undefined;
    root?: KeyRootApi | undefined;
    quality?: KeyQuality | undefined;
};

export type SampleFragmentFragment = {
    __typename?: 'Sample';
    guid: string;
    name?: string | undefined;
    bpm?: number | undefined;
    credits?: number | undefined;
    duration?: number | undefined;
    isDownloaded: boolean;
    isFavorited: boolean;
    isRecommendationFiller?: boolean | undefined;
    sampleState: SampleState;
    sampleType?: SampleType | undefined;
    waveformUrl?: string | undefined;
    instruments?:
        | Array<
              | {
                    __typename?: 'Instrument';
                    code?: string | undefined;
                    isFamily?: boolean | undefined;
                }
              | undefined
          >
        | undefined;
    key?:
        | {
              __typename?: 'SampleKey';
              code?: string | undefined;
              root?: KeyRootApi | undefined;
              quality?: KeyQuality | undefined;
          }
        | undefined;
    tags?:
        | Array<
              | {
                    __typename?: 'Tag';
                    code?: string | undefined;
                    displayName?: string | undefined;
                }
              | undefined
          >
        | undefined;
    sfxs?:
        | Array<{ __typename?: 'Sfx'; code?: string | undefined } | undefined>
        | undefined;
    pack?:
        | {
              __typename?: 'Pack';
              artworkUrl?: string | undefined;
              guid: string;
              isExclusive: boolean;
              isFree: boolean;
              name?: string | undefined;
              packState: PackState;
              slug?: string | undefined;
              type: PackType;
              artist?:
                  | {
                        __typename?: 'Artist';
                        guid: string;
                        slug?: string | undefined;
                        name?: string | undefined;
                        isExclusive: boolean;
                    }
                  | undefined;
              label?:
                  | {
                        __typename?: 'Label';
                        guid: string;
                        name?: string | undefined;
                        slug?: string | undefined;
                        isExclusive: boolean;
                    }
                  | undefined;
          }
        | undefined;
};

export type SampleQueryVariables = Exact<{
    sampleGuid: Scalars['ID'];
}>;

export type SampleQuery = {
    __typename?: 'Query';
    sample?:
        | {
              __typename?: 'Sample';
              guid: string;
              name?: string | undefined;
              bpm?: number | undefined;
              credits?: number | undefined;
              duration?: number | undefined;
              isDownloaded: boolean;
              isFavorited: boolean;
              isRecommendationFiller?: boolean | undefined;
              sampleState: SampleState;
              sampleType?: SampleType | undefined;
              waveformUrl?: string | undefined;
              instruments?:
                  | Array<
                        | {
                              __typename?: 'Instrument';
                              code?: string | undefined;
                              isFamily?: boolean | undefined;
                          }
                        | undefined
                    >
                  | undefined;
              key?:
                  | {
                        __typename?: 'SampleKey';
                        code?: string | undefined;
                        root?: KeyRootApi | undefined;
                        quality?: KeyQuality | undefined;
                    }
                  | undefined;
              tags?:
                  | Array<
                        | {
                              __typename?: 'Tag';
                              code?: string | undefined;
                              displayName?: string | undefined;
                          }
                        | undefined
                    >
                  | undefined;
              sfxs?:
                  | Array<
                        | { __typename?: 'Sfx'; code?: string | undefined }
                        | undefined
                    >
                  | undefined;
              pack?:
                  | {
                        __typename?: 'Pack';
                        artworkUrl?: string | undefined;
                        guid: string;
                        isExclusive: boolean;
                        isFree: boolean;
                        name?: string | undefined;
                        packState: PackState;
                        slug?: string | undefined;
                        type: PackType;
                        artist?:
                            | {
                                  __typename?: 'Artist';
                                  guid: string;
                                  slug?: string | undefined;
                                  name?: string | undefined;
                                  isExclusive: boolean;
                              }
                            | undefined;
                        label?:
                            | {
                                  __typename?: 'Label';
                                  guid: string;
                                  name?: string | undefined;
                                  slug?: string | undefined;
                                  isExclusive: boolean;
                              }
                            | undefined;
                    }
                  | undefined;
          }
        | undefined;
};

export type SampleIsDownloadedQueryVariables = Exact<{
    guid: Scalars['ID'];
}>;

export type SampleIsDownloadedQuery = {
    __typename?: 'Query';
    sample?:
        | { __typename?: 'Sample'; guid: string; isDownloaded: boolean }
        | undefined;
};

export type PaginatedSamplesQueryVariables = Exact<{
    take?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    query?: InputMaybe<Scalars['String']>;
    offset?: InputMaybe<Scalars['Int']>;
}>;

export type PaginatedSamplesQuery = {
    __typename?: 'Query';
    paginatedSamples?:
        | {
              __typename?: 'PaginatedSamples';
              data?:
                  | Array<
                        | {
                              __typename?: 'Sample';
                              guid: string;
                              name?: string | undefined;
                              bpm?: number | undefined;
                              credits?: number | undefined;
                              duration?: number | undefined;
                              isDownloaded: boolean;
                              isFavorited: boolean;
                              isRecommendationFiller?: boolean | undefined;
                              sampleState: SampleState;
                              sampleType?: SampleType | undefined;
                              waveformUrl?: string | undefined;
                              instruments?:
                                  | Array<
                                        | {
                                              __typename?: 'Instrument';
                                              code?: string | undefined;
                                              isFamily?: boolean | undefined;
                                          }
                                        | undefined
                                    >
                                  | undefined;
                              key?:
                                  | {
                                        __typename?: 'SampleKey';
                                        code?: string | undefined;
                                        root?: KeyRootApi | undefined;
                                        quality?: KeyQuality | undefined;
                                    }
                                  | undefined;
                              tags?:
                                  | Array<
                                        | {
                                              __typename?: 'Tag';
                                              code?: string | undefined;
                                              displayName?: string | undefined;
                                          }
                                        | undefined
                                    >
                                  | undefined;
                              sfxs?:
                                  | Array<
                                        | {
                                              __typename?: 'Sfx';
                                              code?: string | undefined;
                                          }
                                        | undefined
                                    >
                                  | undefined;
                              pack?:
                                  | {
                                        __typename?: 'Pack';
                                        artworkUrl?: string | undefined;
                                        guid: string;
                                        isExclusive: boolean;
                                        isFree: boolean;
                                        name?: string | undefined;
                                        packState: PackState;
                                        slug?: string | undefined;
                                        type: PackType;
                                        artist?:
                                            | {
                                                  __typename?: 'Artist';
                                                  guid: string;
                                                  slug?: string | undefined;
                                                  name?: string | undefined;
                                                  isExclusive: boolean;
                                              }
                                            | undefined;
                                        label?:
                                            | {
                                                  __typename?: 'Label';
                                                  guid: string;
                                                  name?: string | undefined;
                                                  slug?: string | undefined;
                                                  isExclusive: boolean;
                                              }
                                            | undefined;
                                    }
                                  | undefined;
                          }
                        | undefined
                    >
                  | undefined;
              meta?:
                  | {
                        __typename?: 'MetaPage';
                        totalRecords?: number | undefined;
                        totalSampleRecords?: number | undefined;
                        totalPackRecords?: number | undefined;
                        filters?:
                            | {
                                  __typename?: 'Filters';
                                  sampleTypeFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  instrumentFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  genreFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  sfxFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  tagFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                              }
                            | undefined;
                    }
                  | undefined;
              next?:
                  | { __typename?: 'NextPage'; skip?: number | undefined }
                  | undefined;
          }
        | undefined;
};

export type PaginatedDownloadedSamplesQueryVariables = Exact<{
    take?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    query?: InputMaybe<Scalars['String']>;
    offset?: InputMaybe<Scalars['Int']>;
}>;

export type PaginatedDownloadedSamplesQuery = {
    __typename?: 'Query';
    paginatedDownloadedSamples?:
        | {
              __typename?: 'PaginatedSamples';
              data?:
                  | Array<
                        | {
                              __typename?: 'Sample';
                              guid: string;
                              name?: string | undefined;
                              bpm?: number | undefined;
                              credits?: number | undefined;
                              duration?: number | undefined;
                              isDownloaded: boolean;
                              isFavorited: boolean;
                              isRecommendationFiller?: boolean | undefined;
                              sampleState: SampleState;
                              sampleType?: SampleType | undefined;
                              waveformUrl?: string | undefined;
                              instruments?:
                                  | Array<
                                        | {
                                              __typename?: 'Instrument';
                                              code?: string | undefined;
                                              isFamily?: boolean | undefined;
                                          }
                                        | undefined
                                    >
                                  | undefined;
                              key?:
                                  | {
                                        __typename?: 'SampleKey';
                                        code?: string | undefined;
                                        root?: KeyRootApi | undefined;
                                        quality?: KeyQuality | undefined;
                                    }
                                  | undefined;
                              tags?:
                                  | Array<
                                        | {
                                              __typename?: 'Tag';
                                              code?: string | undefined;
                                              displayName?: string | undefined;
                                          }
                                        | undefined
                                    >
                                  | undefined;
                              sfxs?:
                                  | Array<
                                        | {
                                              __typename?: 'Sfx';
                                              code?: string | undefined;
                                          }
                                        | undefined
                                    >
                                  | undefined;
                              pack?:
                                  | {
                                        __typename?: 'Pack';
                                        artworkUrl?: string | undefined;
                                        guid: string;
                                        isExclusive: boolean;
                                        isFree: boolean;
                                        name?: string | undefined;
                                        packState: PackState;
                                        slug?: string | undefined;
                                        type: PackType;
                                        artist?:
                                            | {
                                                  __typename?: 'Artist';
                                                  guid: string;
                                                  slug?: string | undefined;
                                                  name?: string | undefined;
                                                  isExclusive: boolean;
                                              }
                                            | undefined;
                                        label?:
                                            | {
                                                  __typename?: 'Label';
                                                  guid: string;
                                                  name?: string | undefined;
                                                  slug?: string | undefined;
                                                  isExclusive: boolean;
                                              }
                                            | undefined;
                                    }
                                  | undefined;
                          }
                        | undefined
                    >
                  | undefined;
              meta?:
                  | {
                        __typename?: 'MetaPage';
                        totalRecords?: number | undefined;
                        totalSampleRecords?: number | undefined;
                        totalPackRecords?: number | undefined;
                        filters?:
                            | {
                                  __typename?: 'Filters';
                                  sampleTypeFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  instrumentFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  genreFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  sfxFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  tagFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                              }
                            | undefined;
                    }
                  | undefined;
              next?:
                  | { __typename?: 'NextPage'; skip?: number | undefined }
                  | undefined;
          }
        | undefined;
};

export type PaginatedFavoritedSamplesQueryVariables = Exact<{
    take?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    query?: InputMaybe<Scalars['String']>;
    offset?: InputMaybe<Scalars['Int']>;
}>;

export type PaginatedFavoritedSamplesQuery = {
    __typename?: 'Query';
    paginatedFavoritedSamples?:
        | {
              __typename?: 'PaginatedSamples';
              data?:
                  | Array<
                        | {
                              __typename?: 'Sample';
                              guid: string;
                              name?: string | undefined;
                              bpm?: number | undefined;
                              credits?: number | undefined;
                              duration?: number | undefined;
                              isDownloaded: boolean;
                              isFavorited: boolean;
                              isRecommendationFiller?: boolean | undefined;
                              sampleState: SampleState;
                              sampleType?: SampleType | undefined;
                              waveformUrl?: string | undefined;
                              instruments?:
                                  | Array<
                                        | {
                                              __typename?: 'Instrument';
                                              code?: string | undefined;
                                              isFamily?: boolean | undefined;
                                          }
                                        | undefined
                                    >
                                  | undefined;
                              key?:
                                  | {
                                        __typename?: 'SampleKey';
                                        code?: string | undefined;
                                        root?: KeyRootApi | undefined;
                                        quality?: KeyQuality | undefined;
                                    }
                                  | undefined;
                              tags?:
                                  | Array<
                                        | {
                                              __typename?: 'Tag';
                                              code?: string | undefined;
                                              displayName?: string | undefined;
                                          }
                                        | undefined
                                    >
                                  | undefined;
                              sfxs?:
                                  | Array<
                                        | {
                                              __typename?: 'Sfx';
                                              code?: string | undefined;
                                          }
                                        | undefined
                                    >
                                  | undefined;
                              pack?:
                                  | {
                                        __typename?: 'Pack';
                                        artworkUrl?: string | undefined;
                                        guid: string;
                                        isExclusive: boolean;
                                        isFree: boolean;
                                        name?: string | undefined;
                                        packState: PackState;
                                        slug?: string | undefined;
                                        type: PackType;
                                        artist?:
                                            | {
                                                  __typename?: 'Artist';
                                                  guid: string;
                                                  slug?: string | undefined;
                                                  name?: string | undefined;
                                                  isExclusive: boolean;
                                              }
                                            | undefined;
                                        label?:
                                            | {
                                                  __typename?: 'Label';
                                                  guid: string;
                                                  name?: string | undefined;
                                                  slug?: string | undefined;
                                                  isExclusive: boolean;
                                              }
                                            | undefined;
                                    }
                                  | undefined;
                          }
                        | undefined
                    >
                  | undefined;
              meta?:
                  | {
                        __typename?: 'MetaPage';
                        totalRecords?: number | undefined;
                        totalSampleRecords?: number | undefined;
                        totalPackRecords?: number | undefined;
                        filters?:
                            | {
                                  __typename?: 'Filters';
                                  sampleTypeFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  instrumentFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  genreFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  sfxFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  tagFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                              }
                            | undefined;
                    }
                  | undefined;
              next?:
                  | { __typename?: 'NextPage'; skip?: number | undefined }
                  | undefined;
          }
        | undefined;
};

export type PaginatedRecommendedSamplesQueryVariables = Exact<{
    sampleGuid: Scalars['ID'];
    take?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    query?: InputMaybe<Scalars['String']>;
    offset?: InputMaybe<Scalars['Int']>;
}>;

export type PaginatedRecommendedSamplesQuery = {
    __typename?: 'Query';
    paginatedRecommendedSamples?:
        | {
              __typename?: 'PaginatedSamples';
              data?:
                  | Array<
                        | {
                              __typename?: 'Sample';
                              guid: string;
                              name?: string | undefined;
                              bpm?: number | undefined;
                              credits?: number | undefined;
                              duration?: number | undefined;
                              isDownloaded: boolean;
                              isFavorited: boolean;
                              isRecommendationFiller?: boolean | undefined;
                              sampleState: SampleState;
                              sampleType?: SampleType | undefined;
                              waveformUrl?: string | undefined;
                              instruments?:
                                  | Array<
                                        | {
                                              __typename?: 'Instrument';
                                              code?: string | undefined;
                                              isFamily?: boolean | undefined;
                                          }
                                        | undefined
                                    >
                                  | undefined;
                              key?:
                                  | {
                                        __typename?: 'SampleKey';
                                        code?: string | undefined;
                                        root?: KeyRootApi | undefined;
                                        quality?: KeyQuality | undefined;
                                    }
                                  | undefined;
                              tags?:
                                  | Array<
                                        | {
                                              __typename?: 'Tag';
                                              code?: string | undefined;
                                              displayName?: string | undefined;
                                          }
                                        | undefined
                                    >
                                  | undefined;
                              sfxs?:
                                  | Array<
                                        | {
                                              __typename?: 'Sfx';
                                              code?: string | undefined;
                                          }
                                        | undefined
                                    >
                                  | undefined;
                              pack?:
                                  | {
                                        __typename?: 'Pack';
                                        artworkUrl?: string | undefined;
                                        guid: string;
                                        isExclusive: boolean;
                                        isFree: boolean;
                                        name?: string | undefined;
                                        packState: PackState;
                                        slug?: string | undefined;
                                        type: PackType;
                                        artist?:
                                            | {
                                                  __typename?: 'Artist';
                                                  guid: string;
                                                  slug?: string | undefined;
                                                  name?: string | undefined;
                                                  isExclusive: boolean;
                                              }
                                            | undefined;
                                        label?:
                                            | {
                                                  __typename?: 'Label';
                                                  guid: string;
                                                  name?: string | undefined;
                                                  slug?: string | undefined;
                                                  isExclusive: boolean;
                                              }
                                            | undefined;
                                    }
                                  | undefined;
                          }
                        | undefined
                    >
                  | undefined;
              meta?:
                  | {
                        __typename?: 'MetaPage';
                        totalRecords?: number | undefined;
                        totalSampleRecords?: number | undefined;
                        totalPackRecords?: number | undefined;
                        filters?:
                            | {
                                  __typename?: 'Filters';
                                  sampleTypeFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  instrumentFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  genreFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  sfxFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  tagFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                              }
                            | undefined;
                    }
                  | undefined;
              next?:
                  | { __typename?: 'NextPage'; skip?: number | undefined }
                  | undefined;
          }
        | undefined;
};

export type UpdateSampleFavoritedStateMutationVariables = Exact<{
    sample: SampleFavoritedInput;
}>;

export type UpdateSampleFavoritedStateMutation = {
    __typename?: 'Mutation';
    updateSampleFavoritedState?:
        | { __typename?: 'Sample'; guid: string; isFavorited: boolean }
        | undefined;
};

export type SampleStreamQueryVariables = Exact<{
    sampleGuid: Scalars['ID'];
}>;

export type SampleStreamQuery = {
    __typename?: 'Query';
    sampleStream?:
        | { __typename?: 'StreamUrl'; url?: string | undefined }
        | undefined;
};

export type GetSeoByPathQueryVariables = Exact<{
    pathname?: InputMaybe<Scalars['ID']>;
}>;

export type GetSeoByPathQuery = {
    __typename?: 'Query';
    seoByPath?:
        | {
              __typename?: 'Seo';
              title?: string | undefined;
              description?: string | undefined;
              header?: string | undefined;
              byline?: string | undefined;
          }
        | undefined;
};

export type UserProfileQueryVariables = Exact<{ [key: string]: never }>;

export type UserProfileQuery = {
    __typename?: 'Query';
    userProfile?:
        | {
              __typename?: 'User';
              userId: string;
              email?: string | undefined;
              firstname?: string | undefined;
              lastname?: string | undefined;
              profilePicture72Url?: string | undefined;
              country?: string | undefined;
          }
        | undefined;
};

export type UserCreditsQueryVariables = Exact<{ [key: string]: never }>;

export type UserCreditsQuery = {
    __typename?: 'Query';
    userCredits?:
        | { __typename?: 'UserCredits'; remaining?: number | undefined }
        | undefined;
};

export type UserAgreementsQueryVariables = Exact<{ [key: string]: never }>;

export type UserAgreementsQuery = {
    __typename?: 'Query';
    userAgreements?:
        | {
              __typename?: 'Agreements';
              agreements?:
                  | Array<
                        | {
                              __typename?: 'Agreement';
                              requireUpdate?: boolean | undefined;
                              kind: LegalAgreementKindEnum;
                              version?: string | undefined;
                          }
                        | undefined
                    >
                  | undefined;
          }
        | undefined;
};

export type AccountBalanceSummaryQueryVariables = Exact<{
    [key: string]: never;
}>;

export type AccountBalanceSummaryQuery = {
    __typename?: 'Query';
    accountBalanceSummary?:
        | {
              __typename?: 'AccountBalanceSummary';
              accountingStatus: AccountStatusEnum;
          }
        | undefined;
};

export type SubscriptionsAndAllowedFeaturesQueryVariables = Exact<{
    [key: string]: never;
}>;

export type SubscriptionsAndAllowedFeaturesQuery = {
    __typename?: 'Query';
    subscriptionsAndAllowedFeatures?:
        | {
              __typename?: 'SubscriptionsAndAllowedFeatures';
              hasStudioPlan: boolean;
              hasSamplesPlan: boolean;
              hasSubscription: boolean;
              upsellingAllowed: boolean;
              samplePaidContentAccess: {
                  __typename?: 'AllowedFeature';
                  isAllowed: boolean;
                  unallowedReason?:
                      | {
                            __typename?: 'UnallowedReason';
                            reason: string;
                            unallowedByPlan?: string | undefined;
                        }
                      | undefined;
              };
          }
        | undefined;
};

export type AcceptUserAgreementsMutationVariables = Exact<{
    agreements: AcceptUserAgreementsInput;
}>;

export type AcceptUserAgreementsMutation = {
    __typename?: 'Mutation';
    acceptUserAgreements?: boolean | undefined;
};

export type ActivateTrialSubscriptionMutationVariables = Exact<{
    [key: string]: never;
}>;

export type ActivateTrialSubscriptionMutation = {
    __typename?: 'Mutation';
    activateTrialSubscription: {
        __typename?: 'SubscriptionsAndAllowedFeatures';
        samplePaidContentAccess: {
            __typename?: 'AllowedFeature';
            isAllowed: boolean;
            unallowedReason?:
                | {
                      __typename?: 'UnallowedReason';
                      reason: string;
                      unallowedByPlan?: string | undefined;
                  }
                | undefined;
        };
    };
};

export type UserCollectionFragmentFragment = {
    __typename?: 'UserCollection';
    artworkUrl?: string | undefined;
    guid?: string | undefined;
    name?: string | undefined;
    sampleCount?: number | undefined;
    userId?: string | undefined;
};

export type UserCollectionQueryVariables = Exact<{
    collectionGuid?: InputMaybe<Scalars['ID']>;
}>;

export type UserCollectionQuery = {
    __typename?: 'Query';
    userCollection?:
        | {
              __typename?: 'UserCollection';
              artworkUrl?: string | undefined;
              guid?: string | undefined;
              name?: string | undefined;
              sampleCount?: number | undefined;
              userId?: string | undefined;
          }
        | undefined;
};

export type DeleteUserCollectionMutationVariables = Exact<{
    collectionGuid?: InputMaybe<Scalars['ID']>;
}>;

export type DeleteUserCollectionMutation = {
    __typename?: 'Mutation';
    deleteUserCollection?: string | undefined;
};

export type UserCollectionsFeedQueryVariables = Exact<{
    take?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    query?: InputMaybe<Scalars['String']>;
    offset?: InputMaybe<Scalars['Int']>;
}>;

export type UserCollectionsFeedQuery = {
    __typename?: 'Query';
    userCollectionsFeed?:
        | {
              __typename?: 'UserCollectionsFeed';
              feed?:
                  | Array<
                        | {
                              __typename?: 'UserCollection';
                              artworkUrl?: string | undefined;
                              guid?: string | undefined;
                              name?: string | undefined;
                              sampleCount?: number | undefined;
                              userId?: string | undefined;
                          }
                        | undefined
                    >
                  | undefined;
              meta?:
                  | {
                        __typename?: 'MetaPage';
                        totalRecords?: number | undefined;
                        totalSampleRecords?: number | undefined;
                        totalPackRecords?: number | undefined;
                        filters?:
                            | {
                                  __typename?: 'Filters';
                                  sampleTypeFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  instrumentFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  genreFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  sfxFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  tagFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                              }
                            | undefined;
                    }
                  | undefined;
          }
        | undefined;
};

export type PaginatedUserCollectionsQueryVariables = Exact<{
    take?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    query?: InputMaybe<Scalars['String']>;
    offset?: InputMaybe<Scalars['Int']>;
}>;

export type PaginatedUserCollectionsQuery = {
    __typename?: 'Query';
    paginatedUserCollections?:
        | {
              __typename?: 'PaginatedUserCollections';
              data?:
                  | Array<
                        | {
                              __typename?: 'UserCollection';
                              artworkUrl?: string | undefined;
                              guid?: string | undefined;
                              name?: string | undefined;
                              sampleCount?: number | undefined;
                              userId?: string | undefined;
                          }
                        | undefined
                    >
                  | undefined;
              meta?:
                  | {
                        __typename?: 'MetaPage';
                        totalRecords?: number | undefined;
                        totalSampleRecords?: number | undefined;
                        totalPackRecords?: number | undefined;
                        filters?:
                            | {
                                  __typename?: 'Filters';
                                  sampleTypeFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  instrumentFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  genreFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  sfxFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  tagFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                              }
                            | undefined;
                    }
                  | undefined;
          }
        | undefined;
};

export type PaginatedUserCollectionSamplesQueryVariables = Exact<{
    collectionGuid?: InputMaybe<Scalars['ID']>;
    take?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
    query?: InputMaybe<Scalars['String']>;
    offset?: InputMaybe<Scalars['Int']>;
}>;

export type PaginatedUserCollectionSamplesQuery = {
    __typename?: 'Query';
    paginatedUserCollectionSamples?:
        | {
              __typename?: 'PaginatedSamples';
              data?:
                  | Array<
                        | {
                              __typename?: 'Sample';
                              guid: string;
                              name?: string | undefined;
                              bpm?: number | undefined;
                              credits?: number | undefined;
                              duration?: number | undefined;
                              isDownloaded: boolean;
                              isFavorited: boolean;
                              isRecommendationFiller?: boolean | undefined;
                              sampleState: SampleState;
                              sampleType?: SampleType | undefined;
                              waveformUrl?: string | undefined;
                              instruments?:
                                  | Array<
                                        | {
                                              __typename?: 'Instrument';
                                              code?: string | undefined;
                                              isFamily?: boolean | undefined;
                                          }
                                        | undefined
                                    >
                                  | undefined;
                              key?:
                                  | {
                                        __typename?: 'SampleKey';
                                        code?: string | undefined;
                                        root?: KeyRootApi | undefined;
                                        quality?: KeyQuality | undefined;
                                    }
                                  | undefined;
                              tags?:
                                  | Array<
                                        | {
                                              __typename?: 'Tag';
                                              code?: string | undefined;
                                              displayName?: string | undefined;
                                          }
                                        | undefined
                                    >
                                  | undefined;
                              sfxs?:
                                  | Array<
                                        | {
                                              __typename?: 'Sfx';
                                              code?: string | undefined;
                                          }
                                        | undefined
                                    >
                                  | undefined;
                              pack?:
                                  | {
                                        __typename?: 'Pack';
                                        artworkUrl?: string | undefined;
                                        guid: string;
                                        isExclusive: boolean;
                                        isFree: boolean;
                                        name?: string | undefined;
                                        packState: PackState;
                                        slug?: string | undefined;
                                        type: PackType;
                                        artist?:
                                            | {
                                                  __typename?: 'Artist';
                                                  guid: string;
                                                  slug?: string | undefined;
                                                  name?: string | undefined;
                                                  isExclusive: boolean;
                                              }
                                            | undefined;
                                        label?:
                                            | {
                                                  __typename?: 'Label';
                                                  guid: string;
                                                  name?: string | undefined;
                                                  slug?: string | undefined;
                                                  isExclusive: boolean;
                                              }
                                            | undefined;
                                    }
                                  | undefined;
                          }
                        | undefined
                    >
                  | undefined;
              meta?:
                  | {
                        __typename?: 'MetaPage';
                        totalRecords?: number | undefined;
                        totalSampleRecords?: number | undefined;
                        totalPackRecords?: number | undefined;
                        filters?:
                            | {
                                  __typename?: 'Filters';
                                  sampleTypeFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  instrumentFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  genreFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  sfxFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                                  tagFilters?:
                                      | Array<
                                            | {
                                                  __typename?: 'FilterMeta';
                                                  name?: string | undefined;
                                                  value?: number | undefined;
                                              }
                                            | undefined
                                        >
                                      | undefined;
                              }
                            | undefined;
                    }
                  | undefined;
              next?:
                  | { __typename?: 'NextPage'; skip?: number | undefined }
                  | undefined;
          }
        | undefined;
};

export type EditUserCollectionMutationVariables = Exact<{
    collectionGuid?: InputMaybe<Scalars['ID']>;
    name?: InputMaybe<Scalars['String']>;
}>;

export type EditUserCollectionMutation = {
    __typename?: 'Mutation';
    editUserCollection?: string | undefined;
};

export type CreateUserCollectionMutationVariables = Exact<{
    name?: InputMaybe<Scalars['String']>;
    sampleCount?: InputMaybe<Scalars['Int']>;
}>;

export type CreateUserCollectionMutation = {
    __typename?: 'Mutation';
    createUserCollection?:
        | {
              __typename?: 'UserCollection';
              guid?: string | undefined;
              name?: string | undefined;
          }
        | undefined;
};

export type AddSamplesToUserCollectionMutationVariables = Exact<{
    sampleIds?: InputMaybe<
        Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>
    >;
    collectionGuid?: InputMaybe<Scalars['ID']>;
}>;

export type AddSamplesToUserCollectionMutation = {
    __typename?: 'Mutation';
    addSamplesToUserCollection?:
        | { __typename?: 'UserCollection'; guid?: string | undefined }
        | undefined;
};

export type RemoveSamplesFromUserCollectionMutationVariables = Exact<{
    sampleIds?: InputMaybe<
        Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>
    >;
    collectionGuid?: InputMaybe<Scalars['ID']>;
}>;

export type RemoveSamplesFromUserCollectionMutation = {
    __typename?: 'Mutation';
    removeSamplesFromUserCollection?:
        | { __typename?: 'UserCollection'; guid?: string | undefined }
        | undefined;
};

export type CreateUserRecentSearchMutationVariables = Exact<{
    filterType: UserSearchFilter;
    filterSlug: Scalars['String'];
}>;

export type CreateUserRecentSearchMutation = {
    __typename?: 'Mutation';
    createUserRecentSearch?: string | undefined;
};

export type GetUserRecentSearchQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserRecentSearchQuery = {
    __typename?: 'Query';
    getUserRecentSearch: {
        __typename?: 'UserRecentSearches';
        recentSearch: Array<{
            __typename?: 'UserRecentSearch';
            searchFilter: UserSearchFilter;
            filterSlug: string;
        }>;
    };
};

export type DeleteUserRecentSearchMutationVariables = Exact<{
    filterType: UserSearchFilter;
    filterSlug: Scalars['String'];
}>;

export type DeleteUserRecentSearchMutation = {
    __typename?: 'Mutation';
    deleteUserRecentSearch?: string | undefined;
};

export type SampleFromGuidFragment = {
    __typename?: 'Sample';
    guid: string;
    name?: string | undefined;
};
