import { DocumentNode } from '@apollo/client';
import { DownloadType } from 'types/enums';
import {
    CollectionCostPreview,
    CreatorSamplesCostPreview,
    PackCostPreview,
    SamplesCostPreview,
    RepackCostPreview,
} from '../cost';

export const RequestCostPreviewQueries: {
    [key in DownloadType]: DocumentNode;
} = {
    [DownloadType.Creator]: CreatorSamplesCostPreview,
    [DownloadType.CreatorMulti]: CreatorSamplesCostPreview,
    [DownloadType.CreatorMixdown]: CreatorSamplesCostPreview,
    [DownloadType.Pack]: PackCostPreview,
    [DownloadType.Repack]: RepackCostPreview,
    [DownloadType.Collection]: CollectionCostPreview,
    [DownloadType.CollectionMulti]: CollectionCostPreview,
    [DownloadType.Sample]: SamplesCostPreview,
    [DownloadType.Multi]: SamplesCostPreview,
    [DownloadType.Unknown]: SamplesCostPreview,
};

export const RequestCostPreviewQueryItemName: {
    [key in DownloadType]: string;
} = {
    [DownloadType.Creator]: 'creatorSamplesCostPreview',
    [DownloadType.CreatorMulti]: 'creatorSamplesCostPreview',
    [DownloadType.CreatorMixdown]: 'creatorSamplesCostPreview',
    [DownloadType.Pack]: 'packCostPreview',
    [DownloadType.Repack]: 'repackCostPreview',
    [DownloadType.Collection]: 'collectionCostPreview',
    [DownloadType.CollectionMulti]: 'collectionCostPreview',
    [DownloadType.Sample]: 'samplesCostPreview',
    [DownloadType.Multi]: 'samplesCostPreview',
    [DownloadType.Unknown]: 'samplesCostPreview',
};
