import React, {
    useContext,
    useCallback,
    useState,
    useMemo,
    useRef,
} from 'react';
import { useDisclosure } from '@landr/maestro';
import { neverop } from 'utils/noop';

type SidebarLayoutType = {
    toggleSidebar: () => void;
    openSidebar: () => void;
    isSidebarOpen: boolean;
    isSidebarDisabled: boolean;
    enableSidebar: () => void;
    disableSidebar: () => void;
    sidebarRef: React.Ref<HTMLDivElement | undefined> | null;
    scrollToTop: () => void;
    defaultSelectedTab: FiltersTabs | undefined;
    setDefaultSelectedTab: React.Dispatch<
        React.SetStateAction<FiltersTabs | undefined>
    >;
};

export const DefaultSidebarLayoutContext: SidebarLayoutType = {
    toggleSidebar: neverop('SidebarLayoutContext', 'toggleSidebar'),
    openSidebar: neverop('SidebarLayoutContext', 'openSidebar'),
    isSidebarOpen: false,
    isSidebarDisabled: false,
    enableSidebar: neverop('SidebarLayoutContext', 'enableSidebar'),
    disableSidebar: neverop('SidebarLayoutContext', 'disableSidebar'),
    sidebarRef: null,
    scrollToTop: neverop('SidebarLayoutContext', 'scrollToTop'),
    defaultSelectedTab: undefined,
    setDefaultSelectedTab: neverop(
        'SidebarLayoutContext',
        'setDefaultSelectedTab',
    ),
};

export const SidebarLayoutContext = React.createContext<SidebarLayoutType>(
    DefaultSidebarLayoutContext,
);

export const useSidebarLayoutContext = (): SidebarLayoutType =>
    useContext(SidebarLayoutContext);

export const enum FiltersTabs {
    'Instruments' = 'tab-instruments',
    'Genres' = 'tab-genres',
    'Sfxs' = 'tab-sfsx',
    'BpmAndKeys' = 'tab-bpm-and-keys',
}

export const useSidebarLayoutProvider = () => {
    const { isOpen, onToggle, onOpen } = useDisclosure();
    const [isSidebarDisabled, setIsSidebarDisabled] = useState(false);
    const [defaultSelectedTab, setDefaultSelectedTab] = useState<
        FiltersTabs | undefined
    >(undefined);

    const sidebarRef = useRef<HTMLDivElement | undefined>(undefined);
    const isSidebarOpen = useMemo(
        () => isOpen && !isSidebarDisabled,
        [isOpen, isSidebarDisabled],
    );

    const handleSidebarOpen = useCallback(() => {
        if (!isSidebarDisabled) {
            onOpen();
        }
    }, [onOpen, isSidebarDisabled]);

    const handleSidebarToggle = useCallback(() => {
        if (!isSidebarDisabled) {
            onToggle();
        }
    }, [onToggle, isSidebarDisabled]);

    const enableSidebar = useCallback(
        () => setIsSidebarDisabled(false),
        [setIsSidebarDisabled],
    );
    const disableSidebar = useCallback(
        () => setIsSidebarDisabled(true),
        [setIsSidebarDisabled],
    );
    const scrollToTop = useCallback(() => {
        if (sidebarRef.current) {
            sidebarRef.current.scrollTo(0, 0);
        }
    }, [sidebarRef]);

    return {
        toggleSidebar: handleSidebarToggle,
        openSidebar: handleSidebarOpen,
        isSidebarOpen,
        isSidebarDisabled,
        enableSidebar,
        disableSidebar,
        sidebarRef,
        scrollToTop,
        defaultSelectedTab,
        setDefaultSelectedTab,
    };
};

export const SidebarLayoutProvider = ({
    children,
}: {
    children: React.ReactNode;
}): React.ReactElement => {
    const context = useSidebarLayoutProvider();

    return (
        <SidebarLayoutContext.Provider value={context}>
            {children}
        </SidebarLayoutContext.Provider>
    );
};

export default SidebarLayoutProvider;
