import { gql } from '@apollo/client';

export const CREATOR_KEY_FRAGMENT = gql`
    fragment CreatorKeyFragment on Key {
        root
        quality
        sign
    }
`;

export const TRACK_FRAGMENT = gql`
    fragment TrackFragment on Track {
        guid
        bpmMultiplier
        bpm
        gain
        pitchTranspose
    }
`;
