import {
    DownloadableItem,
    CreatorDownloadableItem,
    PurchaseVariables,
} from 'types';
import { DownloadType } from 'types/enums';

export const getPurchaseVariables = (
    item: DownloadableItem,
): PurchaseVariables => {
    let variables;

    switch (item.type) {
        case DownloadType.Creator:
        case DownloadType.CreatorMulti:
        case DownloadType.CreatorMixdown:
            variables = {
                purchasableItem: {
                    targetBpm: (item as CreatorDownloadableItem).targetMeta
                        .targetBpm,
                    targetKeyRoot: (item as CreatorDownloadableItem).targetMeta
                        .targetKeyRoot,
                    targetKeyQuality: (item as CreatorDownloadableItem)
                        .targetMeta.targetKeyQuality,
                    targetKey: `${
                        (item as CreatorDownloadableItem).targetMeta
                            .targetKeyRoot
                    }${
                        (item as CreatorDownloadableItem).targetMeta
                            .targetKeyQuality
                    }`,
                    samples: (item as CreatorDownloadableItem).samples,
                },
            };
            break;
        case DownloadType.CollectionMulti:
        case DownloadType.Collection:
            variables = {
                guid: item.id,
            };
            break;
        case DownloadType.Pack:
        case DownloadType.Repack:
            variables = {
                guid: item.id,
            };
            break;
        default:
            variables = {
                guid: item.itemIds,
            };
    }

    return variables;
};
