import { useCallback, useState } from 'react';

const useSessionMilestones = () => {
    const [sessionMilestones, setSessionMilestones] = useState<string[]>([]);

    const updateSingleSessionMilestone = useCallback(
        (milestone: string) => {
            setSessionMilestones([...sessionMilestones, milestone]);
        },
        [sessionMilestones, setSessionMilestones],
    );

    const updateAllSessionMilestones = useCallback(
        (milestones: string[]) => {
            setSessionMilestones(milestones);
        },
        [setSessionMilestones],
    );

    const getSessionMilestone = useCallback(
        (flag: string) => sessionMilestones.includes(flag),
        [sessionMilestones],
    );

    return {
        getSessionMilestone,
        updateSingleSessionMilestone,
        updateAllSessionMilestones,
    };
};

export default useSessionMilestones;
